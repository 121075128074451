import { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import DoneIcon from '@mui/icons-material/Done';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Comments from 'components/UI/Comments/Comments';
import CommentInput from 'components/UI/CommentInput/CommentInput';
import DialogNoUser from 'components/UI/DialogNoUser/DialogNoUser';

import { isLogged } from 'store/user-actions';
import { deleteComment, likeAction } from 'store/comment-actions';
import { downloadFileHandler } from 'store/memory-actions';

import classes from './MemoryActions.module.css';

const MemoryActions = props => {
  const loged = isLogged();
  const [showMessageDownload, setShowMessageDownload] =
    useState(false);
  const [showMessageCopy, setShowMessageCopy] = useState(false);
  const [modalNotUser, setModalNotUser] = useState(false);
  const [memoryLikes, setMemoryLikes] = useState(
    props.dataMemory.countLikes
  );
  const [memoryLikeOwner, setMemoryLikeOwner] = useState(
    props.dataMemory.isOwnerLike
  );
  const [showResponses, setShowResponses] = useState(false);
  const [comments, setComments] = useState(
    props.dataMemory.comments
  );

  const showResponsesHandler = () => {
    setShowResponses(!showResponses);
  };

  const modalNotUserHandler = () => {
    setModalNotUser(true);
  };

  const closeDialogHander = () => {
    setModalNotUser(false);
  };

  const loadNewCommentHandler = (
    avatar,
    userName,
    userLastName,
    comment,
    condolenceCommentId
  ) => {
    const start = Date.now();
    const newComment = {
      avatar: avatar ? avatar : null,
      comment: comment,
      firstName: userName,
      lastName: userLastName,
      timePublished: start / 1000,
      condolenceCommentId,
      isOwnerComment: true
    };
    setComments([...comments, newComment]);
  };

  const deleteCommentHandler = id => {
    (async () => {
      let deleteCommentData = await deleteComment(id, 'MEMORY');
      if (deleteCommentData) {
        setComments(comments => {
          return comments.filter(
            comment => comment.memoryCommentId !== id
          );
        });
        props.loadData();
      }
    })();
  };

  const likeHandler = () => {
    if (memoryLikeOwner) {
      setMemoryLikes(memoryLikes - 1);
      setMemoryLikeOwner(false);
    } else {
      setMemoryLikes(memoryLikes + 1);
      setMemoryLikeOwner(true);
    }
    setModalNotUser(false);
    (async () => {
      let likeData = await likeAction(
        props.dataMemory.memoryId,
        'MEMORY'
      );
      if (likeData) {
        props.loadData();
      }
    })();
  };

  const downloadFile = link => () => {
    setShowMessageDownload(true);
    downloadFileHandler(link);
    setTimeout(() => {
      setShowMessageDownload(false);
    }, 3000);
  };

  const copyMemoryHandler = () => {
    setShowMessageCopy(true);
    setTimeout(() => {
      setShowMessageCopy(false);
    }, 3000);
  };

  return (
    <>
      <div
        className={
          props.hideMemoryId
            ? classes.mamoryActionsNoShow
            : classes.mamoryActionsContainer
        }
      >
        <div className={classes.actionsContainer}>
          <Button
            className={classes.actionBtn}
            onClick={loged ? likeHandler : modalNotUserHandler}
          >
            <ThumbUpOutlinedIcon className={classes.actionIcon} />{' '}
            <span className={classes.actionText}>
              {memoryLikes > 0 && memoryLikes} Me Gusta
            </span>
          </Button>
          <Button
            className={classes.actionBtn}
            onClick={showResponsesHandler}
          >
            <ForumOutlinedIcon className={classes.actionIcon} />{' '}
            <span className={classes.actionText}>Comentar</span>
          </Button>
          <CopyToClipboard
            text={`tribut.org/tribut/${props.tributId}/memory/${props.dataMemory.memoryId}`}
            onCopy={copyMemoryHandler}
          >
            <Button className={classes.actionBtn}>
              <ShareOutlinedIcon className={classes.actionIcon} />{' '}
              <span className={classes.actionText}>Compartir</span>
            </Button>
          </CopyToClipboard>
          {showMessageCopy && (
            <p className={classes.messageContainer}>
              <DoneIcon className={classes.messageIcon} /> El enlace
              de la memoria fue copiado para compartirlo.
            </p>
          )}
          <Button
            className={classes.actionBtn}
            onClick={downloadFile(
              props.dataMemory.urlOrigin
                ? props.dataMemory.urlOrigin
                : props.dataMemory.url
            )}
          >
            <DownloadIcon className={classes.actionIcon} />{' '}
            <span className={classes.actionText}>Descargar</span>
          </Button>
          {showMessageDownload && (
            <p
              className={classes.messageContainer}
              style={{ right: 10 }}
            >
              <DoneIcon className={classes.messageIcon} /> La memoria
              se descargó en este dispositivo.
            </p>
          )}
          <input
            className={classes.inputsContainer}
            type="text"
            value={`tribut.org/tribut/${props.tributId}/memory/${props.dataMemory.memoryId}`}
            id={`memoryCopy-${props.dataMemory.memoryId}`}
          />
        </div>
        {showResponses && (
          <CommentInput
            tributId={props.tributId}
            object={props.dataMemory}
            loadNewComment={loadNewCommentHandler}
          />
        )}
        {showResponses &&
          comments &&
          comments.map(comment => (
            <Comments
              tributId={props.tributId}
              comment={comment}
              key={
                props.memory
                  ? comment.memoryCommentId
                  : comment.condolenceCommentId
              }
              deleteComment={deleteCommentHandler}
              tributOwner={comment.isOwnerTribut}
              commentOwner={comment.isOwnerComment}
              memory
            />
          ))}
      </div>
      {!loged && (
        <DialogNoUser
          open={modalNotUser}
          closeDialog={closeDialogHander}
          action={likeHandler}
        />
      )}
    </>
  );
};

MemoryActions.propTypes = {
  // eslint-disable-next-line react/require-default-props
  memory: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  tributId: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  dataMemory: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  loadData: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  hideMemoryId: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  memoryView: PropTypes.number
};

export default MemoryActions;

import { useState } from 'react';
import PropTypes from 'prop-types';

import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';

import ComplementaryInfo from './ComplementaryInfo/ComplementaryInfo';
import EditInfo from './EditInfo/EditInfo';
import InactiveTribut from 'Pages/Tribut/InactiveTribut/InactiveTribut';
import classes from './TributSelected.module.css';

const TributSelected = props => {
  const [sectionTribut, setSectionTribut] = useState('generalInfo');

  const changeSectionHandler = section => () => {
    setSectionTribut(section);
  };

  return (
    <>
      {props.tribut.daysRemaning < 0 ? (
        <InactiveTribut
          tributName={props.tribut.firstName}
          tributImage={props.tribut.urlAvatar}
          admin
        />
      ) : (
        <aside className={classes.tributSelectedContainer}>
          <div className={classes.userInfo}>
            {props.tribut.urlAvatar ? (
              <figure className={classes.avatar}>
                <img
                  alt={props.tribut.firstName}
                  className={classes.avatar_image}
                  src={
                    props.tribut.urlAvatar
                      ? props.tribut.urlAvatar
                      : ''
                  }
                />
              </figure>
            ) : (
              <div className={classes.noUserContainer}>
                <PersonOutlinedIcon className={classes.noUserIcon} />
              </div>
            )}
            <p className={classes.name}>
              {props.tribut.firstName} {props.tribut.lastName}
            </p>
            {sectionTribut === 'tributPlan' && (
              <ComplementaryInfo data={props.tribut} />
            )}
          </div>
          <EditInfo
            changeSection={changeSectionHandler}
            editCancel={props.adminPage}
            tributInfo={props.tribut}
          />
        </aside>
      )}
    </>
  );
};

TributSelected.propTypes = {
  // eslint-disable-next-line react/require-default-props
  tribut: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  adminPage: PropTypes.func
};

export default TributSelected;

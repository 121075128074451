import { Fragment } from 'react';
import PropTypes from 'prop-types';

import Loading from 'components/UI/Loading/Loading';

import classes from './Bio.module.css';

const Bio = props => {
  const month = monthNumber => {
    const monthText = [
      'Ene',
      'Feb',
      'Mar',
      'Abr',
      'May',
      'Jun',
      'Jul',
      'Ago',
      'Sep',
      'Oct',
      'Nov',
      'Dic'
    ];
    return monthText[monthNumber];
  };

  return (
    <div className={classes.bioContainer}>
      {props.tributDetails != null ? (
        <Fragment>
          <>
            <div className={classes.ocurrenceContainer}>
              <div className={classes.ocurrenceYearsContainer}>
                <p className={classes.yearText}>
                  {new Date(
                    props.tributDetails.birthday
                  ).getFullYear()}
                </p>
              </div>
              <div
                className={classes.ocurrenceSpecificDataContainer}
              >
                <p className={classes.ocurrenceTitle}>Nacimiento</p>
                <p className={classes.ocurrenceText}>
                  {new Date(props.tributDetails.birthday).getDate()}/
                  {month(
                    new Date(props.tributDetails.birthday).getMonth()
                  )}
                  /
                  {new Date(
                    props.tributDetails.birthday
                  ).getFullYear()}{' '}
                  {props.tributDetails.birthplace}
                </p>
              </div>
            </div>
            {props.tributDetails.lstOcurrence.length > 0 &&
              props.tributDetails.lstOcurrence.map(ocurrence => (
                <div
                  className={classes.ocurrenceContainer}
                  key={ocurrence.ocurrenceId}
                >
                  <div className={classes.ocurrenceYearsContainer}>
                    <p className={classes.yearText}>
                      {new Date(ocurrence.date).getFullYear()}
                    </p>
                    <p className={classes.ageText}>
                      {Math.floor(
                        (new Date(ocurrence.date).getTime() -
                          new Date(
                            props.tributDetails.birthday
                          ).getTime()) /
                          (1000 * 60 * 60 * 24) /
                          365
                      )}
                    </p>
                  </div>
                  <div
                    className={
                      classes.ocurrenceSpecificDataContainer
                    }
                  >
                    <p className={classes.ocurrenceTitle}>
                      <span className={classes.infoText}>
                        {ocurrence.type === 'MARRIAGE'
                          ? 'Matrimonio con '
                          : ocurrence.type === 'BIRTH_SON'
                          ? 'Nacimiento de '
                          : ''}
                        {ocurrence.name}
                      </span>
                    </p>
                    <p className={classes.ocurrenceText}>
                      {new Date(ocurrence.date).getDate()}/
                      {month(new Date(ocurrence.date).getMonth())}/
                      {new Date(ocurrence.date).getFullYear()}{' '}
                      {ocurrence.place}
                    </p>
                    <p className={classes.ocurrenceText}>
                      {ocurrence.description}
                    </p>
                  </div>
                </div>
              ))}
            <div className={classes.ocurrenceContainer}>
              <div className={classes.ocurrenceYearsContainer}>
                <p className={classes.yearText}>
                  {new Date(
                    props.tributDetails.dateDeath
                  ).getFullYear()}
                </p>
                {/* <p className={classes.ageText}>
                  {props.tributDetails.age}
                </p> */}
              </div>
              <div
                className={classes.ocurrenceSpecificDataContainer}
              >
                <p className={classes.ocurrenceTitle}>
                  <span className={classes.infoText}>Defunción</span>
                </p>
                <p className={classes.ocurrenceText}>
                  {new Date(props.tributDetails.dateDeath).getDate()}
                  /
                  {month(
                    new Date(
                      props.tributDetails.dateDeath
                    ).getMonth()
                  )}
                  /
                  {new Date(
                    props.tributDetails.dateDeath
                  ).getFullYear()}{' '}
                  {props.tributDetails.placeDeath}
                </p>
              </div>
            </div>
            <div className={classes.bioGeneralDataContainer}>
              {props.tributDetails.phrase && (
                <p className={classes.bioGeneralData__phrase}>
                  <strong>"{props.tributDetails.phrase}"</strong>
                </p>
              )}
            </div>
          </>
          {/* <div className={classes.bioSpecificDataContainer}>
            <p>
              <span className={classes.ageContainer}>1997</span>
              <span>Nacimiento</span>
            </p>
            <p className={classes.bioSpecificData__text}>
              {new Date(props.tributDetails.birthday).getDate()}/
              {month(
                new Date(props.tributDetails.birthday).getMonth()
              )}
              /{new Date(props.tributDetails.birthday).getFullYear()}{' '}
              {props.tributDetails.birthplace}
            </p>
            {props.tributDetails.lstOcurrence.length > 0 &&
              props.tributDetails.lstOcurrence.map(ocurrence => (
                <Fragment key={ocurrence.ocurrenceId}>
                  <p className={classes.bioSpecificData__title}>
                    <span className={classes.infoText}>
                      {ocurrence.type === 'MARRIAGE'
                        ? 'Matrimonio con '
                        : ocurrence.type === 'BIRTH_SON'
                        ? 'Nacimiento de '
                        : ''}
                      {ocurrence.name}
                    </span>
                  </p>
                  <p className={classes.bioSpecificData__text}>
                    {new Date(ocurrence.date).getDate()}/
                    {month(new Date(ocurrence.date).getMonth())}/
                    {new Date(ocurrence.date).getFullYear()}{' '}
                    {ocurrence.place}
                  </p>
                  <p className={classes.bioSpecificData__text}>
                    {ocurrence.description}
                  </p>
                </Fragment>
              ))}
            <p className={classes.bioSpecificData__title}>
              <span className={classes.infoText}>Defunción</span>
            </p>
            <p className={classes.bioSpecificData__text}>
              {new Date(props.tributDetails.dateDeath).getDate()}/
              {month(
                new Date(props.tributDetails.dateDeath).getMonth()
              )}
              /
              {new Date(props.tributDetails.dateDeath).getFullYear()}{' '}
              {props.tributDetails.placeDeath}
            </p>
          </div>
           */}
        </Fragment>
      ) : (
        <Loading />
      )}
    </div>
  );
};

Bio.propTypes = {
  // eslint-disable-next-line react/require-default-props
  tributId: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  tributDetails: PropTypes.string
};

export default Bio;

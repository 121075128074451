import { Fragment, useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import ReactGA from 'react-ga4';

import Button from '@mui/material/Button';

import { getTributs } from '../../store/tribut-actions';
import { isLogged } from '../../store/user-actions';

import MyTribute from 'components/admin/MyTribute/MyTribute';
import TributSelected from 'components/admin/TributSelected/TributSelected';
import Loading from 'components/UI/Loading/Loading';

import classes from './Admin.module.css';

const Admin = () => {
  const [lstTribut, setLstTribut] = useState(null);
  // const [charge, setCharge] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [tributSelected, setTributSelected] = useState(null);

  useEffect(() => {
    ReactGA.initialize('G-N3MP6T91TP');
    ReactGA.pageview('/admin');
  }, []);

  const handleChange = tributData => () => {
    setTributSelected(tributData);
  };

  const adminPageHandler = () => {
    setTributSelected(null);
  };

  useEffect(() => {
    (async () => {
      if (isLogged()) {
        if (lstTribut == null) {
          let tributs = await getTributs();
          setLstTribut(tributs);
        }
      }
    })();
    if (lstTribut !== null) {
      setIsLoading(false);
    }
  }, [lstTribut]);

  const recallListTributHandler = () => {
    (async () => {
      let tributs = await getTributs();
      setLstTribut(tributs);
    })();
  };

  return (
    <section className={classes.admin}>
      {!tributSelected ? (
        <h3 className={classes.title}>Mis Tributos</h3>
      ) : (
        <h3 className={classes.title}>Editar Tributo</h3>
      )}
      {!tributSelected ? (
        <Fragment>
          {!isLoading ? (
            <Fragment>
              <div className={classes.btnContainer}>
                <Button
                  component={Link}
                  to="/tribut/create"
                  className={classes.createTributBtn}
                >
                  Crear nuevo Tributo
                </Button>
              </div>
              {isLogged() ? (
                <div>
                  {lstTribut != null ? (
                    <>
                      {lstTribut.active.length > 0 && (
                        <h3 className={classes.subtitle}>
                          Tributos a mi cargo
                        </h3>
                      )}
                      {lstTribut.active.length > 0 &&
                        lstTribut.active.map(tribut => (
                          <MyTribute
                            key={tribut.idTribut}
                            tributData={tribut}
                            handleChange={handleChange}
                            recallListTribut={
                              recallListTributHandler
                            }
                          />
                        ))}
                      {lstTribut.archived.length > 0 && (
                        <h3 className={classes.subtitle}>
                          Mis Tributos eliminados
                        </h3>
                      )}
                      {lstTribut.archived.length > 0 &&
                        lstTribut.archived.map(tribut => (
                          <MyTribute
                            key={tribut.idTribut}
                            tributData={tribut}
                            handleChange={handleChange}
                            archived
                            recallListTribut={
                              recallListTributHandler
                            }
                          />
                        ))}
                    </>
                  ) : (
                    <p className={classes.complementaryText}>
                      No has credo ningún Tributo
                    </p>
                  )}
                </div>
              ) : (
                <Navigate to="/" />
              )}
            </Fragment>
          ) : (
            <Loading />
          )}

          <aside className={classes.btnHomeContainer}>
            <Button
              className={classes.btnHome}
              component={Link}
              to="/"
            >
              Ir a página Principal
            </Button>
          </aside>
        </Fragment>
      ) : (
        <TributSelected
          adminPage={adminPageHandler}
          tribut={tributSelected}
        />
      )}
    </section>
  );
};

export default Admin;

import axios from 'axios';
import Cookies from 'js-cookie';
import config from './config';
// import { userActions } from './user-slice';

export const getAuthHeader = () => {
  const jwtInMemoriam = Cookies.get('jwtInMemoriam');
  // return { Authorization: ' Bearer ' + jwtInMemoriam };
  return {
    Authorization: `Bearer ${jwtInMemoriam ? jwtInMemoriam : '*'}`
  };
};

export const setCookie = jwtCookie => {
  if (jwtCookie) {
    // Cookies.set('jwtInMemoriam', jwtCookie, {
    //   path: '/',
    //   domain: '.tribut.org',
    //   expires: 3650
    // });
    Cookies.set('jwtInMemoriam', jwtCookie, {
      expires: 3650
    });
  }
};

export const isLogged = () => {
  const jwtInMemoriam = Cookies.get('jwtInMemoriam');
  if (jwtInMemoriam) {
    // Add Validations
    return true;
  }
  return false;
};

export const removeCookie = () => {
  Cookies.remove('jwtInMemoriam');
};

export const getUserData = async () => {
  let userData = await axios.get(`${config.API_USER}`, {
    headers: getAuthHeader()
  });
  if (userData.data.httpStatus === 200) {
    return userData.data;
  }
  return false;
};

export const getLoggerData = async () => {
  await axios.get(`${config.API_USER}/logger`, {
    headers: getAuthHeader()
  });
  return false;
};

export const getUserCredentialsUploadImageS3 = async (
  userId,
  contentType,
  fileName,
  mediaType
) => {
  let response = await axios.get(
    config.API_USER +
      '/key?userId=' +
      userId +
      '&contentType=' +
      contentType +
      '&fileName=' +
      fileName +
      '&mediaType=' +
      mediaType,
    { headers: getAuthHeader() }
  );
  if (
    response &&
    response.data &&
    response.data.httpStatus === 200
  ) {
    return response.data.credentials;
  }
  return null;
};

export const editUserData = async (
  firstName,
  lastName,
  email,
  password,
  newPassword,
  lada,
  cellphone,
  fileName,
  mediaType
) => {
  const data = {
    firstName,
    lastName,
    email,
    password,
    newPassword,
    lada,
    cellphone,
    fileName,
    mediaType
  };

  let response = await axios.post(config.API_USER, data, {
    headers: getAuthHeader()
  });
  if (response.data.updated) {
    return true;
  }
  return false;
};

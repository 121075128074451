import axios from 'axios';
import config from './config';

import { getAuthHeader } from './user-actions';
import { MemoriesDataActions } from './memory-slice';

export const getMemoryList = tributId => {
  return async dispatch => {
    let response = await axios.get(`${config.API_MEMORY}`, {
      params: {
        tributId
      },
      headers: getAuthHeader()
    });
    if (
      response &&
      response.data &&
      response.data.httpStatus === 200
    ) {
      dispatch(
        MemoriesDataActions.info({
          items:
            response.data.memorys.length > 0
              ? response.data.memorys
              : 'error'
        })
      );
    }
    return null;
  };
};

// export const getMemoryList = async tributId => {
//   let response = await axios.get(`${config.API_MEMORY}`, {
//     params: {
//       tributId
//     },
//     headers: getAuthHeader()
//   });
//   if (
//     response &&
//     response.data &&
//     response.data.httpStatus === 200
//   ) {
//     return response.data.memorys;
//   }
//   return null;
// };

export const downloadFileHandler = link => {
  const typeFile = link.split('.');
  axios({
    url: link, //your url
    method: 'GET',
    responseType: 'blob' // important
  }).then(response => {
    // create file link in browser's memory
    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `memory.${typeFile[4]}`); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
};

export const getMemoryDetails = async memoryId => {
  let response = await axios.get(`${config.API_MEMORY}/details`, {
    params: {
      memoryId
    }
  });
  if (
    response &&
    response.data &&
    response.data.httpStatus === 200
  ) {
    return response.data.memory;
  }
  return null;
};

export const getMemoryComments = async memoryId => {
  let response = await axios.get(`${config.API_MEMORY}/comments`, {
    params: {
      memoryId
    }
  });
  if (
    response &&
    response.data &&
    response.data.httpStatus === 200
  ) {
    return response.data.comments;
  }
  return null;
};

export const createMemory = async (tributId, message, file) => {
  const data = {
    tributId: tributId,
    message: message,
    mediaType: file.type,
    extension: file.fileExtension,
    fileName: file.fileName
  };

  let memory = await axios.put(config.API_MEMORY, data, {
    headers: getAuthHeader()
  });
  if (memory && memory.data && memory.data.httpStatus === 200) {
    return memory.data.memoryId;
  }
  return null;
};

export const getMemoryCredentialsUploadImageS3 = async (
  tributId,
  memoryId,
  contentType,
  fileName,
  mediaType
) => {
  let response = await axios.get(
    config.API_MEMORY +
      '/key?tributId=' +
      tributId +
      '&memoryId=' +
      memoryId +
      '&contentType=' +
      contentType +
      '&fileName=' +
      fileName +
      '&mediaType=' +
      mediaType,
    { headers: getAuthHeader() }
  );
  if (
    response &&
    response.data &&
    response.data.httpStatus === 200
  ) {
    return response.data.credentials;
  }
  return null;
};

export const deleteMemory = async memoryId => {
  let deleteMemoryData = await axios.post(
    `${config.API_MEMORY}/delete`,
    {
      memoryId
    },
    {
      headers: getAuthHeader()
    }
  );
  if (deleteMemoryData.data.memory === true) {
    return true;
  }
  return false;
};

import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';

import BannerSection from '../../components/UI/BannerSection/BannerSection';
import classes from './Home.module.css';

const Home = () => {
  const originalURL = window.location.host;
  useEffect(() => {
    ReactGA.initialize('G-N3MP6T91TP');
    ReactGA.pageview('/');
  }, []);

  return (
    <section className={classes.homeContainer}>
      <BannerSection />
      <div className={classes.infoContainer}>
        <h3 className={classes.infoContainer__title}>
          ¿Qué es Tribut?
        </h3>
        <p className={classes.infoContainer__text}>
          <strong>Tribut</strong> es la plataforma para crear y
          compartir homenajes a la memoria de aquellos seres queridos
          que se nos adelantaron en el camino.
        </p>
        <p className={classes.infoContainer__text}>
          En tus páginas de homenaje, llamadas Tributos, podrás
          recabar memorias, anécdotas, condolencias, mensajes, fotos
          y videos dedicados a esa persona especial. Podrás compartir
          la página de Tributo con todos los que conocieron en vida a
          la persona homenajeada, y ellos también podrán realizar sus
          propias aportaciones de anécdotas, memorias y buenos
          recuerdos para celebrar contigo esa vida tan especial.
        </p>
        <div className={classes.btnsContainer}>
          {originalURL === 'https://tribut.org' ||
          originalURL === 'localhost:3000' ? (
            <>
              <Link
                className={classes.seeExample__btn}
                to="/tribut/1/memorias"
              >
                Visita un ejemplo de Tributo
              </Link>
              <Link
                className={classes.inTribut__btn}
                to="/tribut/create"
              >
                Crea un Tributo
              </Link>
            </>
          ) : (
            <>
              <a
                className={classes.seeExample__btn}
                href="https://tribut.org/tribut/1/memorias"
              >
                Visita un ejemplo de Tributo
              </a>
              <a
                className={classes.inTribut__btn}
                href="https://tribut.org/tribut/create"
              >
                Crea un Tributo
              </a>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default Home;

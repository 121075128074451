import { useState } from 'react';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import PhoneInputForm from '../PhoneInputForm/PhoneInputForm';

import classes from './UserInput.module.css';

const UserInput = props => {
  const [showPassword, setShowPassword] = useState(false);
  const [setValueInput] = useState(
    props.prevalue ? props.prevalue : ''
  );

  const handleChange = event => {
    setValueInput(event.target.value);
  };

  const handleClickShowPassword = () =>
    setShowPassword(show => !show);

  const phoneHandler = (lada, phone) => {
    props.mortuarySetPhone(`${lada}${phone}`);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <div
      className={
        props.edit
          ? classes.form_EditGroup
          : props.second || props.secondDeath
          ? classes.form_row
          : classes.form_group
      }
    >
      {props.inputText && (
        <p className={classes.control_label}>{props.inputText}</p>
      )}
      {props.subtext && (
        <p className={classes.control_sublabel}>{props.subtext}</p>
      )}
      {props.type === 'date' ? (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            inputFormat="dd/MM/yyyy"
            value={props.value}
            onChange={newValue => {
              props.dateHandler(newValue);
            }}
            className={classes.dateContainer}
            renderInput={params => (
              <TextField
                className={classes.date}
                size="small"
                {...params}
                name={props.name}
              />
            )}
          />
        </LocalizationProvider>
      ) : props.type === 'hour' ? (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            value={props.value}
            onChange={newValue => {
              props.dateHandler(newValue);
            }}
            renderInput={params => (
              <TextField
                className={classes.form_control}
                size="small"
                {...params}
                name={props.name}
              />
            )}
          />
        </LocalizationProvider>
      ) : props.type === 'phone' ? (
        <PhoneInputForm
          phoneHandler={phoneHandler}
          prevalue={props.prevalue}
        />
      ) : (
        <TextField
          type={
            props.type === 'password'
              ? showPassword
                ? 'text'
                : 'password'
              : props.type
          }
          InputProps={
            props.type === 'password' && {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }
          }
          className={
            props.second && props.edit
              ? classes.inputModificatedEdit
              : props.second
              ? classes.inputModificated
              : classes.form_control
          }
          size={props.size !== 'big' ? props.size : ''}
          multiline={props.size === 'big'}
          rows={4}
          onChange={handleChange}
          {...props.status}
          name={props.name}
          placeholder={props.placeholder ? props.placeholder : null}
        />
      )}
    </div>
  );
};

UserInput.propTypes = {
  // eslint-disable-next-line react/require-default-props
  prevalue: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  type: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  status: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  dateHandler: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  size: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  value: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  inputText: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  mortuarySetPhone: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  name: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  edit: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  row: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  second: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  secondDeath: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  placeholder: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  subtext: PropTypes.string
};

export default UserInput;

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { changePlan, getPricePayment } from 'store/payment-actions';

import EditPaymentPlan from '../EditPaymentPlan/EditPaymentPlan';
import classes from './PaymentData.module.css';

const PaymentData = props => {
  const [planStatus, setPlanStatus] = useState(
    props.subscriptionData.renovationType
  );
  const [pricesData, setPricesData] = useState([]);
  const [editPlanModal, setEditPlanModal] = useState(false);
  const [amount, setAmount] = useState(
    props.subscriptionData.amount
  );

  useEffect(() => {
    (async () => {
      let prices = await getPricePayment();
      setPricesData(prices);
    })();
  }, []);

  const editPlanHandler = () => {
    setEditPlanModal(true);
  };

  const closeDialogHandler = () => {
    setEditPlanModal(false);
  };

  const changePlanHandler = () => {
    let newPlanId = 0;
    let newPlanState = 0;
    let newAmount = 0;
    if (planStatus === 'year') {
      newPlanId = pricesData[0].id_price;
      newPlanState = pricesData[0].lapse;
      newAmount = pricesData[0].unit_amount;
    } else {
      newPlanId = pricesData[1].id_price;
      newPlanState = pricesData[1].lapse;
      newAmount = pricesData[1].unit_amount;
    }
    (async () => {
      let changePlanStatus = await changePlan(newPlanId);
      if (changePlanStatus) {
        setPlanStatus(newPlanState);
        setEditPlanModal(false);
        setAmount(newAmount);
      }
    })();
  };

  return (
    <aside className={classes.dataContainer}>
      <Button
        onClick={editPlanHandler}
        className={classes.editPlanBtn}
      >
        <EditOutlinedIcon />
      </Button>
      <p className={classes.item}>
        <span className={classes.itemTitle}>Plan de pago:</span>
        <span className={classes.itemData}>
          {planStatus === 'year' ? 'Anual' : 'Mensual'}
        </span>
      </p>
      <p className={classes.item}>
        <span className={classes.itemTitle}>
          Estatus de suscripción:{' '}
        </span>
        <span className={classes.itemData}>
          {props.subscriptionData.tributStatus === 'incomplete'
            ? 'Incompleto'
            : props.subscriptionData.tributStatus ===
              'incomplete_expired'
            ? 'Incompleto Expirado'
            : props.subscriptionData.tributStatus === 'trialing'
            ? 'Periodo de prueba'
            : props.subscriptionData.tributStatus === 'active'
            ? 'Activo'
            : props.subscriptionData.tributStatus === 'past_due'
            ? 'Pago Vencido'
            : props.subscriptionData.tributStatus === 'canceled'
            ? 'Cancelado'
            : 'No Pagado'}
        </span>
      </p>
      <p className={classes.item}>
        <span className={classes.itemTitle}>Proximo cargo: </span>
        <span className={classes.itemData}>
          {new Date(
            props.subscriptionData.nextPayment * 1000
          ).getDate()}
          /
          {new Date(
            props.subscriptionData.nextPayment * 1000
          ).getMonth() + 1}
          /
          {new Date(
            props.subscriptionData.nextPayment * 1000
          ).getFullYear()}{' '}
        </span>
      </p>
      <p className={classes.item}>
        <span className={classes.itemTitle}>
          Monto próximo cargo:{' '}
        </span>
        <span className={classes.itemData}>
          $ {amount / 100} {props.subscriptionData.currency}
        </span>
      </p>
      <p className={classes.item}>
        <span className={classes.itemTitle}>Tributos activos: </span>
        <span className={classes.itemData}>
          {props.subscriptionData.tributsCount}
        </span>
      </p>
      <EditPaymentPlan
        closeModal={closeDialogHandler}
        open={editPlanModal}
        changePlan={changePlanHandler}
        planSelected={planStatus}
      />
    </aside>
  );
};

PaymentData.propTypes = {
  // eslint-disable-next-line react/require-default-props
  subscriptionData: PropTypes.object
};

export default PaymentData;

import { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';

import DeleteTributModal from '../DeleteTributModal/DeleteTributModal';
import RecoverTributModal from '../RecoverTributModal/RecoverTributModal';
import Loading from 'components/UI/Loading/Loading';

import { recoverTribut } from 'store/tribut-actions';

import classes from './MyTribute.module.css';

const MyTribute = props => {
  const [deleteTributModal, setDeleteTributModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recoverTributModal, setRecoverTributModal] =
    useState(false);

  const deleteTributModalHandler = () => {
    setDeleteTributModal(!deleteTributModal);
  };

  const recoverTributHandler = () => {
    setLoading(true);
    (async () => {
      let recoverTributStatus = await recoverTribut(
        props.tributData.tributId
      );
      if (recoverTributStatus) {
        props.recallListTribut();
        recoverTributModalHandler();
        setLoading(false);
      }
    })();
  };

  const recoverTributModalHandler = () => {
    setRecoverTributModal(!recoverTributModal);
  };

  return (
    <div className={classes.tributContainer}>
      {props.tributData.urlAvatar ? (
        <figure className={classes.tributAvatar}>
          <img
            src={props.tributData.urlAvatar}
            alt={props.tributData.tributName}
            className={classes.tributImg}
          />
        </figure>
      ) : (
        <div className={classes.noUserContainer}>
          <PersonOutlinedIcon className={classes.noUserIcon} />
        </div>
      )}
      <p className={classes.tributName}>
        {props.tributData.firstName} {props.tributData.lastName}
      </p>
      <div className={classes.complementaryInfo}>
        <p className={classes.item}>
          <span className={classes.itemTitle}>
            Fecha de creación:
          </span>{' '}
          {props.tributData.dateEffective}
        </p>
        <p className={classes.item}>
          <span className={classes.itemTitle}>Condolencias:</span>{' '}
          {props.tributData.condolences}
        </p>
        <p className={classes.item}>
          <span className={classes.itemTitle}>Memorias:</span>{' '}
          {props.tributData.memories}
        </p>
        <p className={classes.item}>
          <span className={classes.itemTitle}>Visitas:</span>{' '}
          {props.tributData.views}
        </p>
      </div>
      <aside className={classes.moreOptionsContainer}>
        {!props.archived ? (
          <>
            <Button
              component={Link}
              to={`/tribut/${props.tributData.tributId}/memorias`}
              className={classes.tributOptionBtn}
            >
              <VisibilityOutlinedIcon className={classes.iconBtn} />
              <span className={classes.descriptionBtn}>Ver</span>
            </Button>
            <Button
              onClick={props.handleChange(props.tributData)}
              className={classes.tributOptionBtn}
            >
              <ModeOutlinedIcon className={classes.iconBtn} />
              <span className={classes.descriptionBtn}>Editar</span>
            </Button>
            <Button
              className={classes.tributOptionBtn}
              onClick={deleteTributModalHandler}
            >
              <DeleteOutlinedIcon className={classes.iconBtn} />
              <span className={classes.descriptionBtn}>Borrar</span>
            </Button>
          </>
        ) : (
          <>
            {!loading ? (
              <Button
                className={classes.tributrecoverBtn}
                onClick={recoverTributHandler}
              >
                Recuperar Tributo
              </Button>
            ) : (
              <Loading />
            )}
          </>
        )}
      </aside>
      <DeleteTributModal
        closeModal={deleteTributModalHandler}
        open={deleteTributModal}
        tributData={props.tributData}
        recallListTribut={props.recallListTribut}
      />
      <RecoverTributModal
        closeModal={recoverTributModalHandler}
        open={recoverTributModal}
        recoverTributModal={recoverTributModalHandler}
      />
    </div>
  );
};

MyTribute.propTypes = {
  // eslint-disable-next-line react/require-default-props
  tributData: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  handleChange: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  archived: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  recallListTribut: PropTypes.func
};

export default MyTribute;

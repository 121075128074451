import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import classes from './TabsSection.module.css';

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     'aria-controls': `simple-tabpanel-${index}`
//   };
// }

const TabsSection = props => {
  return (
    <Tabs
      value={props.valueTab}
      onChange={props.handleChange}
      TabIndicatorProps={{
        style: { background: 'transparent' }
      }}
    >
      <Tab
        className={
          props.valueTab === 0
            ? classes.tabBtnSelected
            : classes.tabBtn
        }
        label="Vida"
        component={Link}
        to={
          props.tributId !== 73
            ? `/tribut/${props.tributId}/vida`
            : '/alvaro/vida'
        }
      />
      <Tab
        className={
          props.valueTab === 1
            ? classes.tabBtnSelected
            : classes.tabBtn
        }
        label="Memorias"
        component={Link}
        to={
          props.tributId !== 73
            ? `/tribut/${props.tributId}/memorias`
            : '/alvaro/memorias'
        }
      />
      <Tab
        className={
          props.valueTab === 2
            ? classes.tabBtnSelected
            : classes.tabBtn
        }
        label="Muro"
        component={Link}
        to={
          props.tributId !== 73
            ? `/tribut/${props.tributId}/condolencias`
            : '/alvaro/condolencias'
        }
      />
    </Tabs>
  );
};

TabsSection.propTypes = {
  // eslint-disable-next-line react/require-default-props
  valueTab: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  handleChange: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  tributId: PropTypes.number
};

export default TabsSection;

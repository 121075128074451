import { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Login from 'components/login/Login';
import CreateUser from 'components/createUser/CreateUser';

import classes from './DialogNoUser.module.css';

const DialogNoUser = props => {
  const [pageView, setPageView] = useState(false);
  const [view, setView] = useState('SIGNUP');

  const loginHandler = async () => {
    setView('LOGIN');
  };

  const signupHandler = async () => {
    setView('SIGNUP');
  };

  const createAccountAction = async () => {
    props.action();
  };

  const loginAction = async () => {
    props.action();
  };

  const closeModalHandler = () => {
    props.closeDialog();
  };

  const changeViewHandler = () => {
    setPageView(true);
  };

  return (
    <>
      {!pageView ? (
        <Dialog
          open={props.open}
          onClose={closeModalHandler}
          PaperProps={{
            sx: {
              width: '100%',
              maxWidth: 355
            }
          }}
        >
          <div>
            <DialogTitle className={classes.title}>
              Tribut
            </DialogTitle>
            <DialogContent>
              <DialogContentText className={classes.subTitle}>
                Para continuar con esta acción es necesario iniciar
                sesión o crear una cuenta.
              </DialogContentText>
              <div className={classes.btnsActionContainer}>
                <Button
                  onClick={closeModalHandler}
                  className={classes.cancelBtn}
                >
                  Cancelar
                </Button>
                <Button
                  onClick={changeViewHandler}
                  className={classes.acceptBtn}
                >
                  Continuar
                </Button>
              </div>
            </DialogContent>
          </div>
        </Dialog>
      ) : (
        <div className={classes.userFormContainer}>
          <DialogContent>
            {view === 'LOGIN' ? (
              <>
                <p className={classes.subtitle}>Ingresar a Tribut</p>
                <Login noReload action={loginAction} />
                <aside className={classes.registerContainer}>
                  <Button
                    className={classes.createAccountBtn}
                    onClick={signupHandler}
                  >
                    Crear nueva cuenta
                  </Button>
                </aside>
              </>
            ) : view === 'SIGNUP' ? (
              <>
                <p className={classes.subtitle}>
                  Crear nueva Cuenta
                </p>
                <aside className={classes.changeViewBtnContainer}>
                  <p className={classes.registerText}>
                    Regístrate o Inicia Sesión
                  </p>
                  <Button
                    className={classes.changeViewBtn}
                    onClick={loginHandler}
                  >
                    Iniciar sesión
                  </Button>
                </aside>
                <CreateUser noReload action={createAccountAction} />
              </>
            ) : (
              ''
            )}
          </DialogContent>
        </div>
      )}
    </>
  );
};

DialogNoUser.propTypes = {
  // eslint-disable-next-line react/require-default-props
  open: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  closeDialog: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  action: PropTypes.func
};

export default DialogNoUser;

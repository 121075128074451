import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';

import UserInput from 'components/UI/UserInput/UserInput';
import Loading from 'components/UI/Loading/Loading.js';

import {
  recoverAccountEmail,
  recoverAccountPhone,
  recoverAccountToken
} from '../../../store/recoverAccount-actions.js';

import classes from './SecondStep.module.css';

const SecondStep = props => {
  const { register, watch } = useForm();
  const [load] = useState(false);
  const userDataNew = watch();

  useEffect(() => {
    props.sendPassword(false);
  }, [userDataNew.code]);

  const sendCodeHandler = () => {
    props.codeReset(userDataNew.code);
    (async () => {
      let phone = props.value.split(' ');
      props.sendPassword(
        await recoverAccountToken(
          props.typeValue === 'email' ? props.value : null,
          props.typeValue !== 'email' ? `${phone[1]}` : null,
          userDataNew.code
        )
      );
    })();
  };

  const resendCodeHandler = () => {
    if (props.typeValue === 'email') {
      (async () => {
        await recoverAccountEmail(props.value);
      })();
    } else {
      let phone = props.value.split(' ');
      (async () => {
        await recoverAccountPhone(phone[0], phone[1]);
      })();
    }
  };

  return (
    <>
      <p className={classes.recoverAccountFirstText}>
        Hemos enviado un código al{' '}
        {props.typeValue === 'email' ? 'correo' : 'teléfono'}{' '}
        {props.value} registrado en su cuenta Tribut. Revisa tu{' '}
        {props.typeValue === 'email' ? 'correo' : 'teléfono'} e
        introduce el código:
      </p>
      <p className={classes.recoverAccountCodeText}>
        Introducir código
      </p>
      <FormControl className={classes.selectOptionContainer}>
        <UserInput
          type="text"
          size="small"
          name="code"
          status={{ ...register('code', { required: false }) }}
        />
        <Button
          className={classes.resendCode}
          onClick={resendCodeHandler}
        >
          Reenviar código
        </Button>
      </FormControl>
      {!load ? (
        <aside className={classes.containerBtns}>
          <Button
            className={classes.sendBtn}
            onClick={sendCodeHandler}
          >
            Continuar
          </Button>
        </aside>
      ) : (
        <Loading />
      )}
      <p className={classes.recoverAccountText}>
        ¿Necesitas ayuda extra? <br /> Puedes ir al
        <Button
          className={classes.helpBtn}
          component="a"
          href="http://ayuda.mayahii.com/"
          target="_blank"
        >
          centro de ayuda
        </Button>{' '}
      </p>
    </>
  );
};

SecondStep.propTypes = {
  // eslint-disable-next-line react/require-default-props
  sendPassword: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  typeValue: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  value: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  codeReset: PropTypes.func
};

export default SecondStep;

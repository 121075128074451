import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';

import { loginUser } from '../../store/login-actions';

import Loading from 'components/UI/Loading/Loading';
import DialogMessage from '../UI/DialogMessage/DialogMessage';
import UserInput from '../UI/UserInput/UserInput';
import UserButton from '../UI/UserButton/UserButton';

import classes from './Login.module.css';

const Login = props => {
  const [load, setLoad] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const { register, handleSubmit, watch } = useForm();

  const onSubmit = async data => {
    setLoad(true);
    const login = await loginUser(data.email, data.password);
    if (login && !props.noReload) {
      setLoad(false);
      window.location.href = '/admin';
    } else if (login && props.noReload) {
      setLoad(false);
      props.action();
    } else {
      setShowDialog(true);
      setLoad(false);
    }
  };

  const closeDialogHandler = () => {
    setShowDialog(false);
  };

  const userDataNew = watch();

  return (
    <div className={classes.login}>
      <Fragment>
        <form onSubmit={handleSubmit(onSubmit)}>
          <UserInput
            inputText="Correo Electrónico"
            type="email"
            size="small"
            name="email"
            status={
              props.createInTribut
                ? props.registerStatus('email', { required: true })
                : { ...register('email', { required: true }) }
            }
          />
          <UserInput
            inputText="Contraseña"
            type="password"
            size="small"
            name="password"
            status={
              props.createInTribut
                ? props.registerStatus('password', {
                    required: true
                  })
                : { ...register('password', { required: true }) }
            }
          />
          {!load ? (
            !props.createInTribut && (
              <UserButton
                disabled={
                  !userDataNew.email || !userDataNew.password
                }
                type="submit"
                text="Iniciar Sesión"
              />
            )
          ) : (
            <aside className={classes.loadContainer}>
              <Loading />
            </aside>
          )}
        </form>
        <aside className={classes.btnContainer}>
          <Button
            className={classes.recoverAccountBtn}
            component={Link}
            to="/recoverAccount"
          >
            RECUPERAR CONTRASEÑA
          </Button>
        </aside>
        <DialogMessage
          closeDialog={closeDialogHandler}
          open={showDialog}
          message="Tu usuario y contraseña no coinciden"
        />
      </Fragment>
    </div>
  );
};

Login.propTypes = {
  // eslint-disable-next-line react/require-default-props
  noReload: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  createInTribut: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  registerStatus: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  action: PropTypes.func
};

export default Login;

import classes from './Loading.module.css';

const Loading = () => {
  return (
    <figure className={classes.loadingImage}>
      <img
        className={classes.img}
        src="https://d1g6c01s9ssd7h.cloudfront.net/img/preloader.png"
        alt="loading"
      />
    </figure>
  );
};

export default Loading;

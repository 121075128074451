import { Fragment } from 'react';
import PropTypes from 'prop-types';

import MainHeader from './MainHeader/MainHeader';

const Layout = props => {
  return (
    <Fragment>
      <MainHeader />
      <main>{props.children}</main>
    </Fragment>
  );
};

Layout.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.string
};

export default Layout;

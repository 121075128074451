import PropTypes from 'prop-types';

import Bio from '../../../components/tribut/Bio/Bio';
import Condolence from '../../../components/tribut/Condolence/Condolence';
import MemoriesCollageContainer from 'components/tribut/MemorySection/MemoriesCollageContainer/MemoriesCollageContainer';
// import Memory from '../../../components/tribut/Memory/Memory';

const ShowSecction = props => {
  return (
    <>
      {props.valueTab === 0 ? (
        <Bio
          tributDetails={props.tributDetails}
          tributId={props.tributId}
        />
      ) : props.valueTab === 2 ? (
        <Condolence
          mortuaryData={props.tributDetails.note}
          tributId={props.tributId}
          tributDetails={props.tributDetails}
        />
      ) : (
        <MemoriesCollageContainer
          tributId={props.tributId}
          tributDetails={props.tributDetails}
          changeView={props.changeView}
          memoryIdHandler={props.memoryIdHandler}
          memoriesDataHandler={props.memoriesDataHandler}
        />
      )}
    </>
  );
};

ShowSecction.propTypes = {
  // eslint-disable-next-line react/require-default-props
  tributId: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  tributDetails: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  valueTab: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  changeView: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  memoriesDataHandler: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  memoryIdHandler: PropTypes.func
};

export default ShowSecction;

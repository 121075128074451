import { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import classes from './EditPaymentPlan.module.css';

const EditPaymentPlan = props => {
  const [fullWidth] = useState(true);
  const [maxWidth] = useState('sm');

  return (
    <Dialog
      open={props.open}
      onClose={props.closeModal}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      <div>
        <DialogTitle className={classes.title}>
          ¿Deseas cambiar tu plan?
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.textInfo}>
            Plan Actual:{' '}
            <strong>
              {props.planSelected === 'year' ? 'Anual' : 'Mensual'}
            </strong>
          </DialogContentText>
          <DialogContentText className={classes.subtextInfo}>
            {props.planSelected === 'year'
              ? '$399 MXN al año por Tributo al año'
              : '$45 MXN al mes por Tributo al mes'}
          </DialogContentText>
          <DialogContentText className={classes.textInfo}>
            Cambiar a:{' '}
            <strong>
              Plan{' '}
              {props.planSelected === 'year' ? 'Mensual' : 'Anual'}
            </strong>
          </DialogContentText>
          <DialogContentText className={classes.subtextInfo}>
            {props.planSelected === 'year'
              ? '$45 MXN al mes por Tributo al mes'
              : '$399 MXN al año por Tributo al año'}
          </DialogContentText>
          <DialogContentText className={classes.textInfo}>
            En caso de confirmar, el cambio se realizará
            automáticamente al momento de la próxima renovación.
          </DialogContentText>
        </DialogContent>
        <div className={classes.btnsContainer}>
          <Button
            className={classes.cancelBtn}
            onClick={props.closeModal}
          >
            Cancelar
          </Button>

          <Button
            className={classes.changePlanBtn}
            onClick={props.changePlan}
            autoFocus
          >
            Cambiar plan
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

EditPaymentPlan.propTypes = {
  // eslint-disable-next-line react/require-default-props
  open: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  closeModal: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  changePlan: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  planSelected: PropTypes.number
};

export default EditPaymentPlan;

import axios from 'axios';
import config from './config';

import { getAuthHeader } from './user-actions';

export const deleteOcurrence = async ocurrenceId => {
  let deleteOcurrenceData = await axios.put(
    `${config.API_OCURRENCE}/delete`,
    {
      ocurrenceId
    },
    {
      headers: getAuthHeader()
    }
  );
  if (deleteOcurrenceData.data.deleted) {
    return true;
  }
  return null;
};

export const addOcurrence = async (
  tributId,
  type,
  name,
  date,
  place,
  description
) => {
  let addOcurrenceData = await axios.put(
    `${config.API_OCURRENCE}`,
    {
      tributId,
      type,
      name,
      date,
      place,
      description
    },
    {
      headers: getAuthHeader()
    }
  );

  if (addOcurrenceData.data.ocurrenceId) {
    return addOcurrenceData.data.ocurrenceId;
  }
  return null;
};

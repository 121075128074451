import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';

import Login from '../../components/login/Login';

import classes from './Login.module.css';

const LoginPage = () => {
  useEffect(() => {
    ReactGA.initialize('G-N3MP6T91TP');
    ReactGA.pageview('/login');
  }, []);

  return (
    <>
      <section className={classes.loginContainer}>
        <p className={classes.loginTitle}>Ingresar a Tribut</p>
        <Login />
      </section>
      <aside className={classes.registerContainer}>
        <Button
          className={classes.createAccountBtn}
          component={Link}
          to="/register"
        >
          Crear nueva cuenta
        </Button>
        <p className={classes.infoText}>
          Crea páginas de Tributo en honor a tus seres queridos.
        </p>
      </aside>
    </>
  );
};

export default LoginPage;

import { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import CardData from 'components/UI/CardData/CardData';
import Loading from 'components/UI/Loading/Loading';

import classes from './DeleteCardModal.module.css';

const DeleteCardModal = props => {
  const [fullWidth] = useState(true);
  const [maxWidth] = useState('sm');
  const [loadingState, setLoadingState] = useState(false);

  const deleteCardHandler = () => {
    setLoadingState(true);
    props.deleteCard();
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.closeModal}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      <div>
        <DialogTitle className={classes.title}>
          Eliminar método de pago
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.textInfo}>
            Confirme eliminar la siguiente tarjeta
          </DialogContentText>
          <CardData cardInfo={props.cardInfo} delete />
        </DialogContent>
        <div className={classes.btnsContainer}>
          {!loadingState ? (
            <>
              <Button
                className={classes.cancelBtn}
                onClick={props.closeModal}
              >
                Cancelar
              </Button>

              <Button
                className={classes.deleteBtn}
                onClick={deleteCardHandler}
              >
                Eliminar
              </Button>
            </>
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </Dialog>
  );
};

DeleteCardModal.propTypes = {
  // eslint-disable-next-line react/require-default-props
  open: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  closeModal: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  cardInfo: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  deleteCard: PropTypes.func
};

export default DeleteCardModal;

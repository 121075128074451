import axios from 'axios';
import config from './config';

import { setCookie } from '../store/user-actions';

export const createUser = async (
  firstName,
  lastName,
  email,
  password,
  lada,
  cellphone,
  fileName,
  mediaType
) => {
  const data = {
    firstName,
    lastName,
    email,
    password,
    lada,
    cellphone,
    fileName,
    mediaType
  };

  let response = await axios.put(config.API_USER, data, {});
  if (response.data.user) {
    setCookie(response.data.response);
    return response.data.user;
  }
  return false;
};

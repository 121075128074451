import { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';

import DialogMessage from '../DialogMessage/DialogMessage';

import classes from './Comments.module.css';

const Comments = props => {
  const month = monthNumber => {
    const monthText = [
      'Ene',
      'Feb',
      'Mar',
      'Abr',
      'May',
      'Jun',
      'Jul',
      'Ago',
      'Sep',
      'Oct',
      'Nov',
      'Dic'
    ];
    return monthText[monthNumber];
  };

  const [openDialog, setOpenDialog] = useState(false);

  const closeDialogHandler = () => {
    setOpenDialog(false);
  };

  const deleteCommentModal = () => {
    setOpenDialog(true);
  };

  const [message] = useState('¿Eliminar comentario?');

  const deleteCommentHandler = () => {
    props.deleteComment(
      props.memory
        ? props.comment.memoryCommentId
        : props.comment.condolenceCommentId
    );
  };

  return (
    <div className={classes.responseContainer}>
      {props.comment.avatar ? (
        <figure className={classes.commentAvatar}>
          <img
            alt=""
            className={classes.commentAvatarImg}
            id={`myMemoryAvatar-${
              props.memory
                ? props.comment.memoryCommentId
                : props.comment.condolenceCommentId
            }`}
            src={props.comment.avatar}
          />
        </figure>
      ) : (
        <div className={classes.noUserContainer}>
          <PersonOutlinedIcon className={classes.noUserIcon} />
        </div>
      )}
      <p className={classes.commentName}>
        <strong>
          {props.comment.firstName} {props.comment.lastName}
        </strong>
      </p>
      <p className={classes.commentPublished}>
        {new Date(props.comment.timePublished * 1000).getDate()}
        {'/'}
        {month(
          new Date(props.comment.timePublished * 1000).getMonth()
        )}
        {'/'}
        {new Date(props.comment.timePublished * 1000).getFullYear()}
      </p>
      <p className={classes.commentText}>{props.comment.comment}</p>
      {(props.tributOwner || props.commentOwner) && (
        <Button
          className={classes.deleteCommentBtn}
          onClick={deleteCommentModal}
        >
          <DeleteIcon />
        </Button>
      )}
      <DialogMessage
        closeDialog={closeDialogHandler}
        action={deleteCommentHandler}
        open={openDialog}
        message={message}
        title=""
        delete
      />
    </div>
  );
};

Comments.propTypes = {
  // eslint-disable-next-line react/require-default-props
  comment: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  deleteComment: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  tributOwner: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  commentOwner: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  memory: PropTypes.bool
};

export default Comments;

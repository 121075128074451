import { Fragment } from 'react';
import PropTypes from 'prop-types';

import classes from './ComplementaryInfo.module.css';

const ComplementaryInfo = props => {
  return (
    <Fragment>
      <p className={classes.itemComplementaryInfo}>
        <strong className={classes.itemTitle}>Plan Actual: </strong>
        {props.data.status === 'ACTIVE' ? (
          <span className={classes.itemText}>Activo</span>
        ) : (
          <span className={classes.itemText}>Inactivo</span>
        )}
      </p>
      <p className={classes.itemComplementaryInfo}>
        <strong className={classes.itemTitle}>
          Número de Condolencias:
        </strong>{' '}
        <span className={classes.itemText}>
          {props.data.condolences}
        </span>
      </p>
      <p className={classes.itemComplementaryInfo}>
        <strong className={classes.itemTitle}>
          {props.data.daysRemaning > 0
            ? 'Fecha de Renovación:'
            : 'Tiempo Vencido Desde:'}
        </strong>{' '}
        <span className={classes.itemText}>
          {props.data.dateExpiration}
        </span>
      </p>
      <p className={classes.itemComplementaryInfo}>
        <strong className={classes.itemTitle}>
          Número de Memorias:
        </strong>{' '}
        <span className={classes.itemText}>
          {props.data.memories}
        </span>
      </p>
    </Fragment>
  );
};

ComplementaryInfo.propTypes = {
  // eslint-disable-next-line react/require-default-props
  data: PropTypes.object
};

export default ComplementaryInfo;

import { Fragment, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import ReactGA from 'react-ga4';

import CreateUserComponent from 'components/createUser/CreateUser';
import DialogMessage from 'components/UI/DialogMessage/DialogMessage';
import PaymentData from 'components/EditUser/PaymentData/PaymentData';
import PaymentMethod from 'components/EditUser/PaymentMethod/PaymentMethod';
import Loading from 'components/UI/Loading/Loading';

import {
  editUserData,
  getUserCredentialsUploadImageS3,
  getUserData,
  isLogged
} from '../../store/user-actions';
import { uploadMultimediaTribut } from '../../store/uploadFileS3-actions';

import classes from './EditUserData.module.css';

const EditUserData = () => {
  const [load, setLoad] = useState(false);
  const [reload, setReload] = useState(false);
  const [message, setMessage] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [lada, setLada] = useState('');
  const [phone, setPhone] = useState('');
  const [userData, setUserData] = useState(null);
  const [addCreditCard, setAddCreditCard] = useState(null);
  const [userFileExtension, setUserFileExtension] = useState(null);
  const [userContentType, setUserContentType] = useState(null);
  const [userFile, setUserFile] = useState(null);
  const [userFileName] = useState(Date.now());
  const [userImage, setUserImage] = useState(null);

  useEffect(() => {
    ReactGA.initialize('G-N3MP6T91TP');
    ReactGA.pageview('/editProfile');
    (async () => {
      let userInfo = await getUserData();
      setUserData(userInfo.user);
    })();
  }, []);

  useEffect(() => {
    if (userData !== null) {
      if (userData.avatar) {
        setUserImage(userData.avatar);
      }
    }
  }, [userData]);

  const phoneHandler = (lada, phone) => {
    setLada(`+${lada}`);
    setPhone(phone);
  };

  const onSubmit = async data => {
    setLoad(true);
    let changePassword = false;
    if (data.userNewPassword) {
      if (data.userNewPassword !== data.userConfirmNewPassword) {
        alert('las contraseñas deben de coincidir');
        return false;
      } else {
        changePassword = true;
      }
    }
    const editUser = await editUserData(
      data.userFirstName,
      data.userLastName,
      data.userEmail,
      changePassword ? data.userPassword : null,
      changePassword ? data.userNewPassword : null,
      lada ? lada : null,
      phone ? phone : null,
      userFileExtension ? userFileName.toString() : null,
      userFileExtension ? userFileExtension : null
    );
    if (userFileExtension) {
      let userPhotoCredentials =
        await getUserCredentialsUploadImageS3(
          userData.id,
          userContentType,
          userFileName.toString(),
          userFileExtension
        );
      if (userPhotoCredentials) {
        await uploadMultimediaTribut(userPhotoCredentials, userFile);
      }
    }

    if (editUser) {
      setShowDialog(true);
      setMessage('Tu Perfil fue actualizado con éxito');
      setLoad(false);
      setReload(true);
    } else {
      setShowDialog(true);
      setMessage('Hubo un error Te sugerimos volver a intentarlo');
      setLoad(false);
    }
  };

  const handleDropUserImage = async data => {
    let fileNameExt = data[0].type;
    if (fileNameExt) {
      let fileNameSplit = fileNameExt.split('/');
      setUserFileExtension(fileNameSplit[1]);
    }
    setUserContentType(data[0].type);
    // setFileSize(data[0].size);
    setUserFile(data[0]);

    const reader = new FileReader();
    reader.onload = function (e) {
      setUserImage(e.target.result);
    };
    reader.readAsDataURL(data[0]);
  };

  const closeDialogHandler = () => {
    setShowDialog(false);
    if (reload) {
      window.location.href = '/';
    }
  };

  const changeAddCreditCardViewHandler = () => {
    setAddCreditCard(!addCreditCard);
  };

  const reloadUserDataHandler = () => {
    (async () => {
      let userInfo = await getUserData();
      setUserData(userInfo.user);
    })();
  };

  return (
    <section>
      {isLogged() ? (
        userData ? (
          <Fragment>
            <h3 className={classes.title}>Mi cuenta</h3>
            {!addCreditCard && (
              <PaymentData
                subscriptionData={userData.subscription}
              />
            )}
            <PaymentMethod
              changeView={changeAddCreditCardViewHandler}
              addCreditCard={addCreditCard}
              subscriptionData={userData.subscription}
              reloadUserData={reloadUserDataHandler}
            />
            {!addCreditCard && (
              <Fragment>
                <h3 className={classes.title}>Editar perfil</h3>
                <CreateUserComponent
                  editData
                  predata={userData}
                  handleDropEditUserImage={handleDropUserImage}
                  editOnSubmit={onSubmit}
                  image={userImage}
                  phoneEditHandler={phoneHandler}
                  loadInfo={load}
                />
              </Fragment>
            )}
          </Fragment>
        ) : (
          <Loading />
        )
      ) : (
        <Navigate to="/" />
      )}
      <DialogMessage
        closeDialog={closeDialogHandler}
        open={showDialog}
        message={message}
      />
    </section>
  );
};

export default EditUserData;

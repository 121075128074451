import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { useRecordWebcam } from 'react-record-webcam';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import VideoRecord from 'components/UI/VideoRecord/VideoRecord';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';

import ShowVideo from 'components/UI/ShowVideo/ShowVideo';
import SelectTypeFile from '../SelectTypeFile/SelectTypeFile';
import DialogMessage from 'components/UI/DialogMessage/DialogMessage';

import { uploadMultimediaTribut } from '../../../store/uploadFileS3-actions';
import { getUserData, isLogged } from '../../../store/user-actions';
import {
  editCondolence,
  getCondolenceCredentialsUploadImageS3
} from '../../../store/condolence-actions';

import classes from './EditCondolence.module.css';

const EditCondolence = props => {
  const { handleSubmit } = useForm();

  const loged = isLogged();
  const [userData, setUserData] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [messageDialog, setMessageDialog] = useState('');
  const [message, setMessage] = useState(props.data.text);
  const [loadFile, setLoadfile] = useState(false);
  const [editFile, setEditFile] = useState(false);
  const [imgImageUpload, setImgImageUpload] = useState(
    props.data.mediaType === 'png' && props.data.multimedia
  );
  const [imgVideoUpload, setImgVideoUpload] = useState(
    (props.data.mediaType === 'HLS' ||
      props.data.mediaType === 'mp4') &&
      props.data.multimedia
  );
  const [file, setFile] = useState(props.data.multimedia);
  const [viewMedia, setViewMedia] = useState(
    props.data.mediaType === 'HLS' || props.data.mediaType === 'mp4'
      ? 'VIDEO'
      : props.data.mediaType === 'png'
      ? 'IMAGE'
      : null
  );
  const [contentType, setContentType] = useState(null);
  const [fileExtension, setFileExtension] = useState(
    props.data.mediaType
  );
  const [fileName] = useState(Date.now());
  const OPTIONS = {
    filename: fileName,
    fileType: 'mp4',
    width: 640,
    height: 480
  };
  const recordWebcam = useRecordWebcam(OPTIONS);
  const onSubmit = () => {};
  useEffect(() => {
    if (loged) {
      (async () => {
        let userInfo = await getUserData();
        setUserData(userInfo.user);
      })();
    }
  }, [loged]);

  const setDataFileHandler = (
    type,
    fileExtension,
    contentType,
    file,
    img
  ) => {
    recordWebcam.close();
    setViewMedia(type);
    setFileExtension(fileExtension);
    setContentType(contentType);
    setFile(file);
    if (type === 'IMAGE') {
      setImgImageUpload(img);
    } else {
      setImgVideoUpload(img);
    }
  };

  const selectTypeFileHandler = type => () => {
    setViewMedia(type);
    if (type === 'RECORD') {
      recordWebcam.open();
    } else {
      recordWebcam.close();
    }
  };

  const resetInfo = type => () => {
    setEditFile(true);
    if (type === 'all') {
      setMessage('');
      props.editCondolence();
    }
    setViewMedia(null);
    setFileExtension(null);
    setContentType(null);
    setFile(null);
    setImgImageUpload('');
    setImgVideoUpload('');
    recordWebcam.close();
  };

  const closeDialogHandler = () => {
    props.callCondolences();
    props.editCondolence();
    setLoadfile(false);
    // setView('UPLOAD');
    setShowDialog(false);
    setImgImageUpload(
      'https://d3cohyhv4rpctw.cloudfront.net/icon_imagen.png'
    );
    setImgVideoUpload(
      'https://d3cohyhv4rpctw.cloudfront.net/icon_video.png'
    );
  };

  const editCondolenceAction = async () => {
    setLoadfile(true);

    let condolenceId = await editCondolence(
      props.tributId,
      props.data.condolenceId,
      message,
      editFile ? fileName : null,
      editFile
        ? viewMedia === 'RECORD'
          ? 'mp4'
          : fileExtension
        : null
    );
    if (editFile) {
      if (fileExtension || viewMedia === 'RECORD') {
        let credentials = '';
        if (condolenceId) {
          credentials = await getCondolenceCredentialsUploadImageS3(
            props.tributId,
            props.data.condolenceId,
            viewMedia === 'RECORD' ? 'video/mp4' : contentType,
            fileName,
            viewMedia === 'RECORD' ? 'mp4' : fileExtension
          );
        }
        if (viewMedia === 'RECORD') {
          const blob = await recordWebcam.getRecording();
          if (blob) {
            if (credentials) {
              // Upload Image en S3
              let uploaded = await uploadMultimediaTribut(
                credentials,
                blob
              );

              if (uploaded) {
                setShowDialog(true);
                setMessageDialog(
                  'La Condolencia fue actualizada con éxito'
                );
                setMessage('');
                setViewMedia(null);
                setFileExtension(null);
                setContentType(null);
                setFile(null);
                setImgImageUpload('');
                setImgVideoUpload('');
                recordWebcam.close();
              } else {
                setShowDialog(true);
                setMessageDialog(
                  'Hubo un error, te sugerimos volver a intentarlo'
                );
              }
            }
          }
        } else {
          if (file) {
            if (credentials) {
              let uploaded = await uploadMultimediaTribut(
                credentials,
                file
              );

              if (uploaded) {
                setShowDialog(true);
                setMessageDialog(
                  'Tu condolencia fue actualizada con éxito'
                );
                setMessage('');
                setViewMedia(null);
                setFileExtension(null);
                setContentType(null);
                setFile(null);
                setImgImageUpload('');
                setImgVideoUpload('');
                recordWebcam.close();
              } else {
                setShowDialog(true);
                setMessageDialog(
                  'Hubo un error, te sugerimos volver a intentarlo'
                );
              }
            }
          }
        }
      }
    } else {
      if (condolenceId) {
        setShowDialog(true);
        setMessageDialog('Tu condolencia fue actualizada con éxito');
        setMessage('');
        setViewMedia(null);
      } else {
        setShowDialog(true);
        setMessageDialog(
          'Hubo un error, te sugerimos volver a intentarlo'
        );
      }
    }
  };

  return (
    <aside className={classes.addCondolenceContainer}>
      {userData ? (
        userData.avatar ? (
          <figure className={classes.userImage}>
            <img
              className={classes.img}
              alt={
                userData
                  ? userData.firstName
                    ? userData.firstName
                    : ''
                  : 'UserName'
              }
              src={userData.avatar}
            />
          </figure>
        ) : (
          <div className={classes.noUserContainer}>
            <PersonOutlinedIcon className={classes.noUserIcon} />
          </div>
        )
      ) : (
        <div className={classes.noUserContainer}>
          <PersonOutlinedIcon className={classes.noUserIcon} />
        </div>
      )}

      <div className={classes.rightSection}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={
            message.length > 20
              ? classes.formContainerBig
              : classes.formContainer
          }
        >
          <div className={classes.form_group}>
            <TextField
              placeholder="Publica un anécdota o mensaje"
              className={
                message.length > 20
                  ? classes.messageInputBig
                  : classes.messageInput
              }
              multiline
              value={message}
              onChange={e => setMessage(e.target.value)}
            />
          </div>
        </form>
        <SelectTypeFile
          selectTypeFileHandler={selectTypeFileHandler}
          changePosition={message.length > 24}
          setData={setDataFileHandler}
          edit
          viewMedia={viewMedia}
          mediaData={props.data}
        />
      </div>
      {viewMedia && (
        <>
          {viewMedia === 'IMAGE' ? (
            <>
              <div className={classes.deleteMediaContainer}>
                <Button
                  className={classes.deleteMedia}
                  onClick={resetInfo('')}
                >
                  <DeleteOutlinedIcon className={classes.icon} />{' '}
                  Borrar Imagen
                </Button>
              </div>
              <figure className={classes.commentImageContainer}>
                <img
                  className={classes.imgContainer}
                  src={imgImageUpload}
                  alt="image"
                />
              </figure>
            </>
          ) : viewMedia === 'VIDEO' ? (
            <>
              <div className={classes.deleteMediaContainer}>
                <Button
                  className={classes.deleteMedia}
                  onClick={resetInfo('')}
                >
                  <DeleteOutlinedIcon className={classes.icon} />{' '}
                  Borrar video
                </Button>
              </div>
              {fileExtension === 'mp4' ? (
                <aside className={classes.videoContainer}>
                  <video
                    controls
                    src={imgVideoUpload}
                    className={classes.video}
                  />
                </aside>
              ) : (
                <ShowVideo srcVideo={props.data.multimedia} />
              )}
            </>
          ) : (
            <>
              <div className={classes.deleteMediaContainer}>
                <Button
                  className={classes.deleteMedia}
                  onClick={resetInfo('')}
                >
                  <DeleteOutlinedIcon className={classes.icon} />{' '}
                  Borrar grabación
                </Button>
              </div>
              <VideoRecord recordWebcam={recordWebcam} />
            </>
          )}
        </>
      )}
      <div className={classes.btnsContainer}>
        {(message || viewMedia) &&
          (!loadFile ? (
            <>
              <Button
                onClick={resetInfo('all')}
                className={classes.cancelBtn}
              >
                Cancelar
              </Button>
              <Button
                onClick={editCondolenceAction}
                className={classes.acceptBtn}
              >
                Publicar
              </Button>
            </>
          ) : (
            <p className={classes.messageText}>
              No cambie de pantalla, esto puede afectar la carga del
              archivo
            </p>
          ))}
      </div>
      <DialogMessage
        closeDialog={closeDialogHandler}
        open={showDialog}
        message={messageDialog}
      />
    </aside>
  );
};

EditCondolence.propTypes = {
  // eslint-disable-next-line react/require-default-props
  tributId: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  callCondolences: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  data: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  editCondolence: PropTypes.func
};

export default EditCondolence;

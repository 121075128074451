import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';

import CreateUser from '../../components/createUser/CreateUser';

import classes from './Register.module.css';

const RegisterPage = () => {
  useEffect(() => {
    ReactGA.initialize('G-N3MP6T91TP');
    ReactGA.pageview('/register');
  }, []);

  return (
    <section className={classes.registerContainer}>
      <p className={classes.registerTitle}>Crear Cuenta</p>
      <p className={classes.registerText}>
        Regístrate o Inicia Sesión
      </p>
      <aside className={classes.btnContainer}>
        <Button
          className={classes.loginBtn}
          component={Link}
          to="/login"
        >
          Iniciar sesión
        </Button>
      </aside>
      <CreateUser />
      <div className={classes.recoverAccountContainer}>
        <Button
          className={classes.recoverAccountBtn}
          component={Link}
          to="/recoverAccount"
        >
          RECUPERAR CONTRASEÑA
        </Button>
      </div>
    </section>
  );
};

export default RegisterPage;

export default {
  API_AUTH:
    'https://mzg156366c.execute-api.us-east-1.amazonaws.com/prod/auth',
  API_TRIBUT:
    'https://1fyoyzhbm5.execute-api.us-east-1.amazonaws.com/prod/tribut',
  API_MEMORY:
    'https://1fyoyzhbm5.execute-api.us-east-1.amazonaws.com/prod/memory',
  API_FOLLOW:
    'https://1fyoyzhbm5.execute-api.us-east-1.amazonaws.com/prod/user',
  API_USER:
    'https://kc1icbsj73.execute-api.us-east-1.amazonaws.com/prod/user',
  API_CONDOLENCE:
    'https://1fyoyzhbm5.execute-api.us-east-1.amazonaws.com/prod/condolence',
  API_COMMENT:
    'https://1fyoyzhbm5.execute-api.us-east-1.amazonaws.com/prod/comment',
  API_PAYMENT:
    'https://1fyoyzhbm5.execute-api.us-east-1.amazonaws.com/prod/list_payment',
  API_OCURRENCE:
    'https://1fyoyzhbm5.execute-api.us-east-1.amazonaws.com/prod/ocurrence'
};

// export default {
//   API_AUTH:
//     'https://mzg156366c.execute-api.us-east-1.amazonaws.com/prod/auth',
//   API_TRIBUT:
//     'https://52yac76po2.execute-api.us-east-1.amazonaws.com/qa/tribut',
//   API_MEMORY:
//     'https://52yac76po2.execute-api.us-east-1.amazonaws.com/qa/memory',
//   API_USER:
//     'https://kc1icbsj73.execute-api.us-east-1.amazonaws.com/prod/user',
//   API_CONDOLENCE:
//     'https://52yac76po2.execute-api.us-east-1.amazonaws.com/qa/condolence',
//   API_COMMENT:
//     'https://52yac76po2.execute-api.us-east-1.amazonaws.com/qa/comment',
//   API_PAYMENT:
//     'https://52yac76po2.execute-api.us-east-1.amazonaws.com/qa/list_payment'
// };

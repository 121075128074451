import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useParams } from 'react-router-dom';

import TabsSection from './TabsSection/TabsSection';
import Loading from 'components/UI/Loading/Loading';
import TributHeader from './TributHeader/TributHeader';
import ShowSecction from './ShowSection/ShowSection';
import AllMemoriesContainer from 'components/tribut/MemorySection/AllMemoriesContainer/AllMemoriesContainer';
import RecordMemory from 'components/tribut/RecordMemory/RecordMemory';
import InactiveTribut from './InactiveTribut/InactiveTribut';

import { getBioDetails } from '../../store/tribut-actions';

import classes from './Tribut.module.css';

const Tribut = () => {
  const [valueTab, setValueTab] = useState(1);
  const [tributDetails, setTributDetails] = useState(null);
  const [memoriesView, setMemoriesView] = useState('all');
  const [memoryId, setMemoryId] = useState(false);
  const [memoriesData, setMemoriesData] = useState(false);

  const url = window.location.pathname.split('/');
  let params = useParams();
  let tributId = params ? params.tributId : 0;
  let tributSection = params ? params.section : '';

  if (url[1] === 'alvaro') {
    tributId = 73;
    tributSection = 'memorias';
  }

  useEffect(() => {
    if (tributSection === 'condolencias') {
      setValueTab(2);
    } else if (tributSection === 'vida') {
      setValueTab(0);
    } else {
      setValueTab(1);
    }
    ReactGA.initialize('G-N3MP6T91TP');
    ReactGA.pageview(`/tribut/${tributId}/${tributSection}`);
    (async () => {
      let tributDetails = await getBioDetails(tributId);
      setTributDetails(tributDetails);
    })();
  }, []);

  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };

  const changeViewHandler = view => {
    setMemoriesView(view);
  };

  const memoryIdHandler = id => {
    setMemoryId(id);
  };

  const memoriesDataHandler = memories => {
    setMemoriesData(memories);
  };

  return (
    <>
      {tributDetails ? (
        <>
          {tributDetails.tributId > 0 ? (
            <section className={classes.TributContainer}>
              {memoriesView === 'all' ? (
                <>
                  <TributHeader tributDetails={tributDetails} />
                  {tributDetails.daysRemaning > 0 && (
                    <>
                      <TabsSection
                        valueTab={valueTab}
                        handleChange={handleChange}
                        tributId={tributId}
                      />
                      <ShowSecction
                        tributDetails={tributDetails}
                        tributId={tributId}
                        valueTab={valueTab}
                        changeView={changeViewHandler}
                        memoryIdHandler={memoryIdHandler}
                        memoriesDataHandler={memoriesDataHandler}
                      />
                    </>
                  )}
                </>
              ) : memoriesView === 'specific' ? (
                <AllMemoriesContainer
                  memories={memoriesData}
                  tributId={tributId}
                  changeView={changeViewHandler}
                  memoryView={memoryId}
                />
              ) : (
                <RecordMemory
                  changeView={changeViewHandler}
                  tributDetails={tributDetails}
                  tributId={tributId}
                />
              )}
            </section>
          ) : (
            <InactiveTribut
              tributName={tributDetails.data.fullName}
              tributImage={tributDetails.data.avatarUrl}
              tributId={tributId}
            />
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Tribut;

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { getStripeData } from 'store/payment-actions';

import Loading from 'components/UI/Loading/Loading';
import CheckoutForm from '../CheckoutForm/CheckoutForm';

const StripeContainer = props => {
  const [clientSecret, setClientSecret] = useState('');
  const [stripePromise, setStripePromise] = useState('');

  useEffect(() => {
    (async () => {
      let stripeData = await getStripeData();
      setClientSecret(stripeData.client_secret);
      setStripePromise(
        loadStripe(
          stripeData.livemode
            ? 'pk_live_m96i3GLo8cogVNhwaJoTbu4R'
            : 'pk_test_NAkks2isB44C2yGjykFAbKH5'
        )
      );
    })();
  }, []);

  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#c94d32',
      colorBackground: '#ffffff',
      colorText: '#30313d',
      colorDanger: '#df1b41',
      fontFamily: 'Ideal Sans, system-ui, sans-serif',
      spacingUnit: '2px',
      borderRadius: '4px'
    }
  };
  const options = {
    clientSecret,
    appearance
  };

  return (
    <div className="App">
      {clientSecret && stripePromise ? (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm newCardSuccess={props.newCardSuccess} />
        </Elements>
      ) : (
        <Loading />
      )}
    </div>
  );
};

StripeContainer.propTypes = {
  // eslint-disable-next-line react/require-default-props
  newCardSuccess: PropTypes.func
};

export default StripeContainer;

import { Fragment, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import ReactGA from 'react-ga4';

import ChargesTribut from 'components/ChargesTribut/ChargesTribut';
import Loading from 'components/UI/Loading/Loading';

import { getTributsPayment } from '../../store/payment-actions';
import { isLogged } from '../../store/user-actions';
import classes from './Charges.module.css';

const Charges = () => {
  const [lstTribut, setLstTribut] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    ReactGA.initialize('G-N3MP6T91TP');
    ReactGA.pageview('/charges');
  }, []);

  useEffect(() => {
    (async () => {
      if (isLogged()) {
        if (lstTribut == null) {
          let tributs = await getTributsPayment();
          setLstTribut(tributs);
        }
      }
    })();
    if (lstTribut !== null) {
      setIsLoading(false);
    }
  }, [lstTribut]);

  return (
    <section className={classes.admin}>
      <h3 className={classes.title}>Cargos</h3>
      <Fragment>
        {isLogged() ? (
          <Fragment>
            {!isLoading ? (
              lstTribut != null && lstTribut.length > 0 ? (
                <ChargesTribut lstTribut={lstTribut} />
              ) : (
                <p className={classes.complementaryText}>
                  No has credo ningún Tributo
                </p>
              )
            ) : (
              <Loading />
            )}
          </Fragment>
        ) : (
          <Navigate to="/" />
        )}
      </Fragment>
    </section>
  );
};

export default Charges;

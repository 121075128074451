import { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';

import UnfollowTributeModal from '../UnfollowTributeModal/UnfollowTributeModal';

import { isLogged } from 'store/user-actions';
import { followTribut } from 'store/tribut-actions';
import DialogNoUser from 'components/UI/DialogNoUser/DialogNoUser';

import classes from './TributHeader.module.css';
const urlNew = window.location.host;

const TributHeader = props => {
  const loged = isLogged();
  const [state] = useState({
    vertical: 'bottom',
    horizontal: 'center'
  });
  const [modalNotUser, setModalNotUser] = useState(false);
  const { vertical, horizontal } = state;
  const [unfollowTributModal, setUnfollowTributModal] =
    useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [followTributStatus, setFollowTributStatus] = useState(
    props.tributDetails.follow
  );
  let params = useParams();
  let tributId = params.tributId;

  const copyLink = () => {
    const copyText = document.getElementById('urlCopy');
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand('copy');
    setShowSnackBar(true);
  };

  const closeSnackBarHandler = () => {
    setShowSnackBar(false);
  };

  const followTributeHandler = () => {
    setUnfollowTributModal(false);
    (async () => {
      let followTributDetails = await followTribut(tributId);
      if (followTributDetails) {
        setFollowTributStatus(!followTributStatus);
      }
      closeDialog();
    })();
  };

  const unfollowTributeModalHandler = () => {
    setUnfollowTributModal(true);
  };

  const closeModalHandler = () => {
    setUnfollowTributModal(false);
  };

  const modalNotUserHandler = () => {
    setModalNotUser(true);
  };

  const closeDialog = () => {
    setModalNotUser(false);
  };

  return (
    <>
      {props.tributDetails.tributId !== 1 && (
        <Button className={classes.shareBtn} onClick={copyLink}>
          <ShareOutlinedIcon />
        </Button>
      )}
      <input
        className={classes.shareInput}
        type="text"
        value={`https://${urlNew}/tribut/${tributId}/memorias`}
        id="urlCopy"
      />
      <div className={classes.myTributImageContainer}>
        <p className={classes.myTributDetails__text}>
          En la memoria de
        </p>
        {props.tributDetails.urlAvatar !== null ? (
          <figure className={classes.myTributAvatar}>
            <img
              alt={props.tributDetails.firstName}
              id="myTributAvatar"
              className={classes.img}
              src={props.tributDetails.urlAvatar}
            />
          </figure>
        ) : (
          <div className={classes.noUserContainer}>
            <PersonOutlinedIcon className={classes.noUserIcon} />
          </div>
        )}
        <div className={classes.myTributDetails}>
          <p className={classes.myTributDetails__name}>
            <strong>
              {props.tributDetails.firstName}{' '}
              {props.tributDetails.lastName}
            </strong>
          </p>
          <p className={classes.myTributDetails__name}>
            {props.tributDetails.age} años
          </p>
          <p className={classes.myTributDetails__name}>
            {new Date(props.tributDetails.birthday).getFullYear()} -{' '}
            {new Date(props.tributDetails.dateDeath).getFullYear()}
          </p>
        </div>
        {props.tributDetails.tributId !== 1 && (
          <Button
            onClick={
              !loged
                ? modalNotUserHandler
                : followTributStatus
                ? unfollowTributeModalHandler
                : followTributeHandler
            }
            className={
              followTributStatus
                ? classes.unfollowTributeBtn
                : classes.followTributeBtn
            }
          >
            {followTributStatus ? 'Siguiendo' : 'Seguir Tributo'}
          </Button>
        )}
      </div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={showSnackBar}
        onClose={closeSnackBarHandler}
        message="Enlace copiado"
        autoHideDuration={3000}
      />
      <UnfollowTributeModal
        open={unfollowTributModal}
        closeModal={closeModalHandler}
        unfollow={followTributeHandler}
      />
      {modalNotUser && (
        <DialogNoUser
          open={modalNotUser}
          closeDialog={closeDialog}
          action={followTributeHandler}
        />
      )}
    </>
  );
};

TributHeader.propTypes = {
  // eslint-disable-next-line react/require-default-props
  tributDetails: PropTypes.object
};

export default TributHeader;

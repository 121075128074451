import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MemoriesCarousel from 'components/UI/MemoriesCarousel/MemoriesCarousel';

import classes from './AllMemoriesContainer.module.css';

const AllMemoriesContainer = props => {
  return (
    <section className={classes.memoriesContainer} id="allMemories">
      <div className={classes.allMemoriesContainer}>
        <Button
          component={Link}
          to={
            props.tributId !== 73
              ? `/tribut/${props.tributId}/memorias`
              : '/alvaro'
          }
          className={classes.btnBack}
        >
          <ArrowBackIcon className={classes.btnBackIcon} />
        </Button>
        <p className={classes.title}>Memorias</p>
        <MemoriesCarousel
          memories={props.memories}
          tributId={props.tributId}
          memoryView={props.memoryView}
        />
      </div>
    </section>
  );
};

AllMemoriesContainer.propTypes = {
  // eslint-disable-next-line react/require-default-props
  tributId: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  memories: PropTypes.array,
  // eslint-disable-next-line react/require-default-props
  changeView: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  memoryView: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  className: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  style: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  onClick: PropTypes.func
};

export default AllMemoriesContainer;

import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';

import { isLogged } from '../../../../store/user-actions';

import classes from './UserOptions.module.css';

const UserOptions = props => {
  return (
    <Fragment>
      {!isLogged() ? (
        <div className={classes.loginMenuContainer}>
          <Link
            to="/login"
            className={classes.loginBtn}
            onClick={props.handleCloseMenu}
          >
            Ingresar
          </Link>
          <p className={classes.loginText}>
            Ingresa a tu cuenta para <br /> acceder a tus tributos
          </p>
        </div>
      ) : (
        <div className={classes.userOptions}>
          {props.userData ? (
            <figure className={classes.userAvatar}>
              <img
                className={classes.imgAvatar}
                src={props.userData.avatar}
                alt="user image"
              />
            </figure>
          ) : (
            <div className={classes.noUserContainer}>
              <PersonOutlinedIcon className={classes.noUserIcon} />
            </div>
          )}
          {props.userData && (
            <p className={classes.userName}>
              {props.userData.firstName} {props.userData.lastName}
            </p>
          )}
        </div>
      )}
    </Fragment>
  );
};

UserOptions.propTypes = {
  // eslint-disable-next-line react/require-default-props
  handleCloseMenu: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  userData: PropTypes.object
};

export default UserOptions;

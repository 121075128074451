import axios from 'axios';
import config from './config';

export const recoverAccountEmail = async email => {
  let recoverEmail = await axios.get(
    `${config.API_USER}/password/email`,
    {
      params: {
        email
      }
    }
  );
  if (recoverEmail.data.send) {
    return true;
  }
  return 'error';
};

export const recoverAccountPhone = async (lada, cellphone) => {
  let recoverPhone = await axios.get(
    `${config.API_USER}/password/sms`,
    {
      params: {
        lada,
        cellphone
      }
    },
    {}
  );
  if (recoverPhone.data.send) {
    return true;
  }
  return 'error';
};

export const recoverAccountToken = async (
  email,
  cellphone,
  token
) => {
  let tokenData = await axios.get(
    `${config.API_USER}/token`,
    {
      params: {
        email,
        cellphone,
        token
      }
    },
    {}
  );
  if (tokenData.data.isValid === true) {
    return true;
  }
  return 'notFoundCode';
};

export const resetPassword = async (
  email,
  cellphone,
  token,
  password
) => {
  let resetPasswordData = await axios.post(
    `${config.API_USER}/password/token`,
    {
      email,
      cellphone,
      token,
      password
    }
  );
  if (resetPasswordData.data.changed === true) {
    return true;
  }
  return false;
};

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { CAMERA_STATUS } from 'react-record-webcam';
import ReactStopwatch from 'react-stopwatch';

import StopCircleIcon from '@mui/icons-material/Stop';

import classes from './VideoRecors.module.css';

const VideoRecord = props => {
  const [timeStart, setTimeStart] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 900);
  }, []);

  const timeStartHandler = () => {
    props.recordWebcam.start();
    setTimeStart(true);
  };

  const stopVideoMemory = () => {
    props.recordWebcam.stop();
    props.saveFileHandler();
  };

  return (
    <aside className={classes.recordContainer}>
      <video
        className={classes.videoContainer}
        autoPlay
        muted
        ref={props.recordWebcam.webcamRef}
        style={{
          display: `${
            props.recordWebcam.status === CAMERA_STATUS.OPEN ||
            props.recordWebcam.status === CAMERA_STATUS.RECORDING
              ? 'block'
              : 'none'
          }`
        }}
      />
      <video
        className={classes.videoContainer}
        controls
        ref={props.recordWebcam.previewRef}
        style={{
          display: `${
            props.recordWebcam.status === CAMERA_STATUS.PREVIEW
              ? 'block'
              : 'none'
          }`
        }}
      />

      {props.recordWebcam.status !== CAMERA_STATUS.PREVIEW && (
        <>
          {timeStart ? (
            <ReactStopwatch
              seconds={0}
              minutes={0}
              hours={0}
              render={({ formatted }) => {
                return (
                  <div>
                    <p className={classes.timeTextRecording}>
                      {formatted}
                    </p>
                  </div>
                );
              }}
            />
          ) : (
            <p className={classes.timeText}>00:00:00</p>
          )}
          <div className={classes.btnsContainer}>
            {props.recordWebcam.status !==
              CAMERA_STATUS.RECORDING && (
              <button
                className={classes.btnRecordAction}
                disabled={
                  props.recordWebcam.status ===
                    CAMERA_STATUS.CLOSED ||
                  props.recordWebcam.status ===
                    CAMERA_STATUS.RECORDING ||
                  props.recordWebcam.status === CAMERA_STATUS.PREVIEW
                }
                onClick={timeStartHandler}
              />
            )}
            {props.recordWebcam.status ===
              CAMERA_STATUS.RECORDING && (
              <button
                className={classes.btnStopAction}
                disabled={
                  props.recordWebcam.status !==
                  CAMERA_STATUS.RECORDING
                }
                onClick={
                  props.memory
                    ? stopVideoMemory
                    : props.recordWebcam.stop
                }
              >
                <StopCircleIcon
                  className={classes.stopIcon}
                  color="blue"
                />
              </button>
            )}
          </div>
        </>
      )}
      <aside
        className={
          loading ? classes.loadingContainer : classes.noShow
        }
      >
        .
      </aside>
    </aside>
  );
};

VideoRecord.propTypes = {
  // eslint-disable-next-line react/require-default-props
  recordWebcam: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  saveFileHandler: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  memory: PropTypes.bool
};

export default VideoRecord;

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import {
  addOcurrence,
  deleteOcurrence
} from 'store/ocurrence-actions';

import TimeLine from '../TimeLine/TimeLine';
import classes from './Events.module.css';

const Events = props => {
  const [eventType, setEventType] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [eventDate, setEventDate] = useState(null);
  const [eventPlace, setEventPlace] = useState(null);
  const [eventsData, setEventsData] = useState(props.ocurrencesData);

  useEffect(() => {
    if (!props.edit) {
      let events = eventsData;
      events.map(event => {
        delete event.id;
      });
      props.ocurrencesHandler(events);
    }
  }, [eventsData]);

  const eventDateHandler = value => {
    setEventDate(value);
  };

  const handleChangeTitle = event => {
    setTitle(event.target.value);
  };

  const handleChangeEventPlace = event => {
    setEventPlace(event.target.value);
  };

  const handleChangeDescription = event => {
    setDescription(event.target.value);
  };

  const handleChangeEventType = event => {
    if (!props.edit) {
      props.ocurrenceStatusHandler();
    }
    setEventType(event.target.value);
  };

  const deleteEventHandler = id => {
    if (props.edit) {
      deleteOcurrence(id);
    }
    setEventsData(events => {
      return events.filter(event => event.ocurrenceId !== id);
    });
  };

  const resetEventHandler = () => {
    setEventType('');
    setTitle('');
    setDescription('');
    setEventDate(null);
    setEventPlace('');
    if (!props.edit) {
      props.ocurrenceStatusHandler();
    }
  };

  const saveEventHandler = () => {
    if (props.edit) {
      (async () => {
        let addOcurrenceData = await addOcurrence(
          props.tributId,
          eventType,
          title,
          eventDate,
          eventPlace,
          description
        );
        setEventsData(events => [
          ...events,
          {
            type: eventType,
            name: title,
            date: eventDate,
            place: eventPlace,
            description: description,
            ocurrenceId: addOcurrenceData
          }
        ]);
      })();
    } else {
      setEventsData(events => [
        ...events,
        {
          type: eventType,
          name: title,
          date: eventDate,
          place: eventPlace,
          description: description,
          ocurrenceId: Math.random().toString()
        }
      ]);
    }
    resetEventHandler();
  };

  return (
    <>
      <div
        className={
          props.edit
            ? classes.eventContainerEdit
            : classes.eventContainer
        }
      >
        <TimeLine
          edit={props.edit}
          eventsData={eventsData}
          deleteEvent={deleteEventHandler}
          birthday={props.birthday}
        />
        <h3 className={classes.tributStepTitle}>
          Agrega acontecimientos de vida (opcional)
        </h3>
        <FormControl fullWidth>
          <InputLabel id="event-type">
            Seleciona un tipo de acontecimiento
          </InputLabel>
          <Select
            labelId="event-type"
            id="event-type-select"
            value={eventType}
            label="Seleciona un tipo de acontecimiento"
            onChange={handleChangeEventType}
          >
            <MenuItem value={'MARRIAGE'}>Matrimonio</MenuItem>
            <MenuItem value={'BIRTH_SON'}>
              Nacimiento de hijos
            </MenuItem>
            <MenuItem value={'CUSTOM'}>Personalizado</MenuItem>
          </Select>
        </FormControl>
        {eventType && (
          <>
            <p>
              {eventType === 'MARRIAGE'
                ? 'Matrimonio'
                : eventType === 'BIRTH_SON'
                ? 'Nacimiento de hijos'
                : 'Acontecimiento Perzonalizado'}
            </p>
            <p>
              {eventType === 'MARRIAGE'
                ? 'Nombre del cónyuge'
                : eventType === 'BIRTH_SON'
                ? 'Nombre'
                : 'Acontecimiento'}
            </p>
            <TextField
              className={classes.inputField}
              onChange={handleChangeTitle}
              value={title}
              size="small"
            />
            <p>
              {eventType === 'MARRIAGE'
                ? 'Fecha y lugar de Matrimonio'
                : eventType === 'BIRTH_SON'
                ? 'Fecha y lugar de nacimiento (opcional)'
                : 'Fecha y lugar del acontecimiento'}
            </p>
            <div className={classes.specialContainer}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  inputFormat="dd/MM/yyyy"
                  value={eventDate}
                  onChange={newValue => {
                    eventDateHandler(newValue);
                  }}
                  className={classes.dateContainer}
                  renderInput={params => (
                    <TextField
                      className={classes.date}
                      size="small"
                      {...params}
                    />
                  )}
                />
              </LocalizationProvider>
              <TextField
                onChange={handleChangeEventPlace}
                value={eventPlace}
                className={classes.dateText}
                size="small"
                placeholder={
                  eventType === 'MARRIAGE'
                    ? 'Lugar de Matrimonio'
                    : eventType === 'BIRTH_SON'
                    ? 'Lugar de Nacimiento'
                    : 'Lugar del Acontecimiento'
                }
              />
            </div>
            <p>Descripción</p>
            <TextField
              onChange={handleChangeDescription}
              value={description}
              multiline
              rows={4}
              className={classes.inputField}
              size="small"
              inputProps={{ maxLength: 140 }}
            />
            <p className={classes.totalCharacters}>
              {140 - description.length} caracteres
            </p>
          </>
        )}
      </div>
      {eventType && (
        <div className={classes.btnsContainer}>
          <Button
            onClick={resetEventHandler}
            className={classes.cancelEventBtn}
          >
            Cancelar
          </Button>
          <Button
            onClick={saveEventHandler}
            className={classes.acceptEventBtn}
          >
            Guardar
          </Button>
        </div>
      )}
    </>
  );
};

Events.propTypes = {
  // eslint-disable-next-line react/require-default-props
  ocurrencesHandler: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  edit: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  ocurrencesData: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  tributId: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  ocurrenceStatusHandler: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  birthday: PropTypes.string
};

export default Events;

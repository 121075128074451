import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import Loading from 'components/UI/Loading/Loading';
import { deleteTribut } from 'store/tribut-actions';

import classes from './DeleteTributModal.module.css';

const DeleteTributModal = props => {
  const [fullWidth] = useState(true);
  const [maxWidth] = useState('sm');
  const [loadingState, setLoadingState] = useState(false);
  const [deleteTributStatus, setDeleteTributStatus] =
    useState(false);
  const [deleteTributMessage, setDeleteTributMessage] = useState(
    <Fragment>
      <DialogTitle style={{ textAlign: 'center' }}>
        Eliminar Tributo a {props.tributData.firstName}{' '}
        {props.tributData.lastName}
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.textInfo}>
          Al eliminar el Tributo, este permanecerá vigente hasta la
          siguiente fecha de vencimento:{' '}
          {props.tributData.dateExpiration}. Cuando llegue esa fecha,
          el Tributo no será renovado y comenzará su proceso de
          eliminación.
        </DialogContentText>
        <DialogContentText className={classes.textInfo}>
          Tienes hasta la fecha de vencimiento para dar reversa a tu
          decisión si así lo deseas. Una vez eliminado, el Tributo y
          sus contenidos, incluyendo Condolencias, Memorias,
          fotografías, videos y comentarios, no se podrán recuperar.
        </DialogContentText>
      </DialogContent>
    </Fragment>
  );

  const deleteTributHandler = () => {
    setLoadingState(true);
    (async () => {
      let deleteTributStatus = await deleteTribut(
        props.tributData.tributId
      );
      if (deleteTributStatus) {
        setLoadingState(false);
        setDeleteTributStatus(true);
        setDeleteTributMessage(
          <Fragment>
            <DialogContent>
              <DialogContentText className={classes.textInfo}>
                El Tributo a {props.tributData.firstName}{' '}
                {props.tributData.lastName} no será renovado
                automáticamente en su fecha de vencimiento (
                {props.tributData.dateExpiration}). Continuará
                vigente hasta ese día, y después será eliminado con
                todos sus contenidos, incluyendo condolencias,
                memorias, fotografías, videos y comentarios. Una vez
                eliminado, no se podrá recuperar.
              </DialogContentText>
            </DialogContent>
          </Fragment>
        );
      }
      props.recallListTribut();
    })();
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.closeModal}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      <div>
        {deleteTributMessage}
        <div className={classes.btnsContainer}>
          {!loadingState ? (
            <>
              {!deleteTributStatus && (
                <Button
                  className={classes.cancelBtn}
                  onClick={props.closeModal}
                >
                  Cancelar
                </Button>
              )}

              <Button
                className={classes.deleteBtn}
                onClick={
                  !deleteTributStatus
                    ? deleteTributHandler
                    : props.closeModal
                }
                autoFocus
              >
                {!deleteTributStatus
                  ? 'Eliminar Tributo'
                  : 'De acuerdo'}
              </Button>
            </>
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </Dialog>
  );
};

DeleteTributModal.propTypes = {
  // eslint-disable-next-line react/require-default-props
  open: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  closeModal: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  tributData: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  recallListTribut: PropTypes.func
};

export default DeleteTributModal;

import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    data: []
  },
  reducers: {
    userData(state, action) {
      state.data = action.payload.items;
    }
  }
});

export const userActions = userSlice.actions;

export default userSlice;

import axios from 'axios';
import config from './config';

import { getAuthHeader } from './user-actions';

export const getTributsPayment = async () => {
  let payment = await axios.get(config.API_PAYMENT, {
    headers: getAuthHeader()
  });
  if (payment && payment.data && payment.data.httpStatus === 200) {
    return payment.data.list;
  }
  return null;
};

export const getPricePayment = async () => {
  let pricePayment = await axios.get(`${config.API_USER}/price`, {
    headers: getAuthHeader()
  });
  if (pricePayment.data.httpStatus === 200) {
    return pricePayment.data.prices;
  }
  return null;
};

export const getStripeData = async () => {
  let stripeData = await axios.get(`${config.API_USER}/card`, {
    headers: getAuthHeader()
  });
  if (stripeData.data.httpStatus === 200) {
    return stripeData.data.card;
  }
  return null;
};

export const deleteCard = async paymentId => {
  let deleteData = await axios.post(
    `${config.API_USER}/card/delete`,
    { paymentId },
    {
      headers: getAuthHeader()
    }
  );
  if (deleteData.data.httpStatus === 200) {
    return true;
  }
  return false;
};

export const changePlan = async planId => {
  let changePlanData = await axios.post(
    `${config.API_USER}/plan`,
    { planId },
    {
      headers: getAuthHeader()
    }
  );
  if (changePlanData.data.plan.success === true) {
    return true;
  }
  return false;
};

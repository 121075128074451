import {
  Route,
  BrowserRouter as Router,
  Routes
} from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import Layout from './components/Layout/Layout';

import Home from './Pages/Home/Home';
import Altar from 'Pages/Altar/Altar';
import Login from './Pages/Login/Login';
import CreateTribut from './Pages/CreateTribut/CreateTribut';
import Tribut from './Pages/Tribut/Tribut';
import Register from './Pages/Register/Register';
import Admin from './Pages/Admin/Admin';
import AdminPlan from './Pages/AdminPlan/AdminPlan';
import Faqs from './Pages/Faqs/Faqs';
import EditUserData from 'Pages/EditUserData/EditUserData';
import RecoverAccountPage from 'Pages/RecoverAccountPage/RecoverAccountPage';
import Politics from 'Pages/Politics/Politics';
import Contact from 'Pages/Contact/Contact';
import Charges from 'Pages/Charges/Charges';
import NotFound from 'Pages/NotFound/NotFound';
import Memories from 'Pages/Memories/Memories';
import MemoryPage from 'Pages/MemoryPage/MemoryPage';

const helmetContext = {};

function App() {
  return (
    <Router>
      <HelmetProvider context={helmetContext}>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/admin" element={<Admin />} />
            <Route exact path="/altar" element={<Altar />} />
            <Route exact path="/faqs" element={<Faqs />} />
            <Route exact path="/politics" element={<Politics />} />
            <Route exact path="/contactUs" element={<Contact />} />
            <Route exact path="/charges" element={<Charges />} />
            <Route
              exact
              path="/recoverAccount"
              element={<RecoverAccountPage />}
            />
            <Route
              exact
              path="/editProfile"
              element={<EditUserData />}
            />
            <Route
              exact
              path="/admin/plans"
              element={<AdminPlan />}
            />
            <Route
              exact
              path="/tribut/create"
              element={<CreateTribut />}
            />
            <Route
              exact
              path="/tribut/:tributId/:section"
              element={<Tribut />}
            />
            <Route
              exact
              path="/tribut/:tributId/memories/:idMemory"
              element={<Memories />}
            />
            <Route
              exact
              path="/tribut/:tributId/memory/:idMemory"
              element={<MemoryPage />}
            />
            <Route exact path="/alvaro" element={<Tribut />} />
            <Route
              exact
              path="/alvaro/:section"
              element={<Tribut />}
            />
            <Route
              exact
              path="/alvaro/memories/:idMemory"
              element={<Memories />}
            />
            <Route
              exact
              path="/alvaro/memory/:idMemory"
              element={<MemoryPage />}
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Layout>
      </HelmetProvider>
    </Router>
  );
}

export default App;

import { createSlice } from '@reduxjs/toolkit';

const MemoriesDataSlice = createSlice({
  name: 'MemoriesData',
  initialState: {
    data: []
  },
  reducers: {
    info(state, action) {
      state.data = action.payload.items;
    }
  }
});

export const MemoriesDataActions = MemoriesDataSlice.actions;

export default MemoriesDataSlice;

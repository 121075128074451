import { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import classes from './UnfollowTributeModal.module.css';

const UnfollowTributeModal = props => {
  const [fullWidth] = useState(true);
  const [maxWidth] = useState('xs');

  return (
    <Dialog
      open={props.open}
      onClose={props.closeModal}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      <div>
        <DialogContent>
          <DialogContentText className={classes.textInfo}>
            ¿Quieres dejar de seguir este Tributo?
          </DialogContentText>
        </DialogContent>
        <div className={classes.btnsContainer}>
          <Button
            className={classes.cancelBtn}
            onClick={props.closeModal}
          >
            Cancelar
          </Button>
          <Button
            className={classes.deleteBtn}
            onClick={props.unfollow}
          >
            Dejar de seguir
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

UnfollowTributeModal.propTypes = {
  // eslint-disable-next-line react/require-default-props
  open: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  closeModal: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  unfollow: PropTypes.func
};

export default UnfollowTributeModal;

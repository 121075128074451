import { useEffect } from 'react';
import ReactGA from 'react-ga4';

import BannerSection from '../../components/UI/BannerSection/BannerSection';

import classes from './Faqs.module.css';

const Faqs = () => {
  useEffect(() => {
    ReactGA.initialize('G-N3MP6T91TP');
    ReactGA.pageview('/faqs');
  }, []);

  return (
    <section>
      <BannerSection />
      <aside className={classes.faqsContainer}>
        <h3 className={classes.faqsTitle}>Preguntas Frecuentes</h3>
        <p className={classes.faqsQuestion}>
          ¿Cómo funciona Tribut?
        </p>
        <p className={classes.faqsAnswer}>
          Tu cuenta de Tribut es gratis, siempre. Con tu cuenta
          gratuita puedes seguir el número de tributos que desees
          agregándolos a tu altar de tributos. También puedes
          escribir condolencias, subir fogotrafías, videos, mensajes
          y recuerdos a cualquier tributo al que desees aportar, todo
          esto sin costo alguno.
        </p>
        <p className={classes.faqsQuestion}>
          ¿Cuál es el precio de Tribut?
        </p>
        <p className={classes.faqsAnswer}>
          Tu cuenta Tribut te permite también crear nuevos tributos,
          y te otorga un período gratuito de 90 días en el que puedes
          crear y administrar todos los tributos que desees, para que
          tu y otras personas puedan rendir homenaje a tus seres
          queridos. Al final del período gratuito de 90 días tendrás
          la opción de mantener activos tus tributos, en cuyo caso
          Tribut te ofrece 2 alternativas de pago: un pago mensual de
          $45 pesos por tributo, o un pago anual de $399 pesos por
          tributo. Estos pagos son recurrentes y se cargan
          automáticamente a tu tarjeta de crédito. Podrás cancelar
          cualquiera de tus tributos en el momento que lo desees, sin
          ningún tipo de penalización.
        </p>
        {/* <p className={classes.faqsQuestion}>
            ¿Sólo aplica para México?
          </p>
          <p className={classes.faqsAnswer}>
            Por el momento nuestro servicio solo está disponible en
            México, pero estamos trabajando para llevarlo a más
            lugares del mundo.
          </p> */}
      </aside>
    </section>
  );
};

export default Faqs;

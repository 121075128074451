import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useRecordWebcam } from 'react-record-webcam';
import Button from '@mui/material/Button';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import VideoRecord from 'components/UI/VideoRecord/VideoRecord';

import { uploadMultimediaTribut } from '../../../store/uploadFileS3-actions';
import {
  createMemory,
  getMemoryCredentialsUploadImageS3
} from '../../../store/memory-actions';

import classes from './RecordMemory.module.css';

const RecordMemory = props => {
  const [publishRecord, setPublishRecord] = useState(false);
  const [loadFile, setLoadFile] = useState(false);
  const [fileName] = useState(Date.now());
  const OPTIONS = {
    filename: fileName,
    fileType: 'mp4',
    width: 640,
    height: 480
  };
  const recordWebcam = useRecordWebcam(OPTIONS);

  useEffect(() => {
    recordWebcam.open();
  }, []);

  const changeViewHandler = () => {
    props.changeView('all');
    recordWebcam.close();
  };

  const saveFileHandler = async () => {
    setPublishRecord(true);
  };

  const createMemoryAction = async () => {
    setLoadFile(true);
    const memoryRecord = await recordWebcam.getRecording();
    const file = {
      mediaType: 'video/mp4',
      extension: 'mp4',
      fileName: fileName
    };
    let memoryId = await createMemory(props.tributId, null, file);
    if (memoryId) {
      let credentials = await getMemoryCredentialsUploadImageS3(
        props.tributId,
        memoryId,
        file.mediaType,
        file.fileName,
        file.extension
      );
      let uploaded = await uploadMultimediaTribut(
        credentials,
        memoryRecord
      );

      if (uploaded) {
        props.changeView('all');
      } else {
        props.changeView('all');
      }
    }
  };

  return (
    <section className={classes.recordContainer}>
      <p className={classes.title}>Grabar Memoria</p>
      <Button
        onClick={changeViewHandler}
        className={classes.returnBtn}
      >
        <DeleteOutlinedIcon /> Borrar grabación
      </Button>
      <p className={classes.description}>
        Cuéntale a todos en tu video acerca de alguna anécdota que
        hayas vivido con {props.tributDetails.firstName}{' '}
        {props.tributDetails.lastName}, o alguna forma en la cual
        marcó tu vida positivamente.
      </p>
      <VideoRecord
        recordWebcam={recordWebcam}
        memory
        saveFileHandler={saveFileHandler}
      />
      {publishRecord &&
        (!loadFile ? (
          <div className={classes.btnsContainer}>
            <Button
              onClick={changeViewHandler}
              className={classes.cancelBtn}
            >
              Cancelar
            </Button>
            <Button
              onClick={createMemoryAction}
              className={classes.acceptBtn}
            >
              Publicar
            </Button>
          </div>
        ) : (
          <p className={classes.messageText}>
            No cambie de pantalla, esto puede afectar la carga del
            archivo
          </p>
        ))}
    </section>
  );
};

RecordMemory.propTypes = {
  // eslint-disable-next-line react/require-default-props
  changeView: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  tributId: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  tributDetails: PropTypes.object
};

export default RecordMemory;

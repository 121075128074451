import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import List from '@mui/material/List';
import Button from '@mui/material/Button';

import UserOptions from './UserOptions/UserOptions';

import { isLogged, removeCookie } from '../../../store/user-actions';
import classes from './Menu.module.css';

const Menu = props => {
  const originalURL = window.location.host;
  const logOut = () => {
    removeCookie();
    window.location.href = '/';
  };
  const handleCloseMenu = () => {
    props.closeMenu(true, false);
  };

  return (
    <>
      <aside className={classes.container}>
        <figure className={classes.tributLogo}>
          <img
            className={classes.img}
            src="https://d3cohyhv4rpctw.cloudfront.net/Logo_Tribut.png"
            alt="logo"
          />
        </figure>
      </aside>
      <List className={classes.menuContainer}>
        <UserOptions
          handleCloseMenu={handleCloseMenu}
          userData={props.userData}
        />
        {isLogged() && (
          <>
            <div className={classes.sectionContainer}>
              {originalURL === 'https://tribut.org' ||
              originalURL === 'localhost:3000' ? (
                <Link
                  to="/altar"
                  className={classes.moreInformation__link}
                  onClick={handleCloseMenu}
                >
                  <figure className={classes.altarIcon}>
                    <img
                      className={classes.img}
                      src="https://d3cohyhv4rpctw.cloudfront.net/icon_altar.png"
                      alt="altar_icon"
                    />
                  </figure>{' '}
                  <span className={classes.specialText}>
                    Mi altar de tributos
                  </span>
                </Link>
              ) : (
                <a
                  href="https://tribut.org/altar"
                  className={classes.moreInformation__link}
                  onClick={handleCloseMenu}
                >
                  <figure className={classes.altarIcon}>
                    <img
                      className={classes.img}
                      src="https://d3cohyhv4rpctw.cloudfront.net/icon_altar.png"
                      alt="altar_icon"
                    />
                  </figure>{' '}
                  <span className={classes.specialText}>
                    Mi altar de tributos
                  </span>
                </a>
              )}
            </div>
            <div className={classes.sectionContainer}>
              {originalURL === 'https://tribut.org' ||
              originalURL === 'localhost:3000' ? (
                <Button
                  component={Link}
                  to="/tribut/create"
                  className={classes.createTributBtn}
                  onClick={handleCloseMenu}
                >
                  Crear nuevo Tributo
                </Button>
              ) : (
                <Button
                  component="a"
                  href="https://tribut.org/tribut/create"
                  className={classes.createTributBtn}
                  onClick={handleCloseMenu}
                >
                  Crear nuevo Tributo
                </Button>
              )}
            </div>
          </>
        )}
        <div className={classes.sectionContainer}>
          <p className={classes.admintitle}>Administrar</p>
          {isLogged() && (
            <>
              {originalURL === 'https://tribut.org' ||
              originalURL === 'localhost:3000' ? (
                <>
                  <Link
                    to="/admin"
                    className={classes.moreInformation__link}
                    onClick={handleCloseMenu}
                  >
                    Mis Tributos
                  </Link>
                  <Link
                    to="/editProfile"
                    className={classes.moreInformation__link}
                    onClick={handleCloseMenu}
                  >
                    Mi cuenta
                  </Link>
                </>
              ) : (
                <>
                  <a
                    href="https://tribut.org/admin"
                    className={classes.moreInformation__link}
                    onClick={handleCloseMenu}
                  >
                    Mis Tributos
                  </a>
                  <a
                    href="https://tribut.org/editProfile"
                    className={classes.moreInformation__link}
                    onClick={handleCloseMenu}
                  >
                    Mi cuenta
                  </a>
                </>
              )}
            </>
          )}
          {originalURL === 'https://tribut.org' ||
          originalURL === 'localhost:3000' ? (
            <>
              <Link
                to="/faqs"
                className={classes.moreInformation__link}
                onClick={handleCloseMenu}
              >
                Preguntas frecuentes
              </Link>
              <Link
                to="/admin/plans"
                className={classes.moreInformation__link}
                onClick={handleCloseMenu}
              >
                Planes de pago
              </Link>
            </>
          ) : (
            <>
              <a
                href="https://tribut.org/faqs"
                className={classes.moreInformation__link}
                onClick={handleCloseMenu}
              >
                Preguntas frecuentes
              </a>
              <a
                href="https://tribut.org/admin/plans"
                className={classes.moreInformation__link}
                onClick={handleCloseMenu}
              >
                Planes de pago
              </a>
            </>
          )}
        </div>
        <div className={classes.sectionContainer}>
          {originalURL === 'https://tribut.org' ||
          originalURL === 'localhost:3000' ? (
            <>
              <Link
                to="/terms"
                className={classes.moreInformation__link}
                onClick={handleCloseMenu}
              >
                Terminos y Condiciones
              </Link>
              <Link
                to="/politics"
                className={classes.moreInformation__link}
                onClick={handleCloseMenu}
              >
                Política de Privacidad
              </Link>
            </>
          ) : (
            <>
              <a
                href="https://tribut.org/terms"
                className={classes.moreInformation__link}
                onClick={handleCloseMenu}
              >
                Terminos y Condiciones
              </a>
              <a
                href="https://tribut.org/politics"
                className={classes.moreInformation__link}
                onClick={handleCloseMenu}
              >
                Política de Privacidad
              </a>
            </>
          )}
        </div>
        {isLogged() && (
          <div className={classes.sectionContainer}>
            <Button
              component={Link}
              to="/"
              onClick={logOut}
              className={classes.btnOptions}
            >
              Cerrar sesión
            </Button>
          </div>
        )}
      </List>
      {/* <figure className={classes.tributFooterLogo}>
        <img
          className={classes.imgFooter}
          src="https://d3cohyhv4rpctw.cloudfront.net/InMemoriam.png"
          alt="logo"
        />
      </figure> */}
    </>
  );
};

Menu.propTypes = {
  // eslint-disable-next-line react/require-default-props
  closeMenu: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  userData: PropTypes.object
};

export default Menu;

import { createSlice } from '@reduxjs/toolkit';

const condolenceSlice = createSlice({
  name: 'condolence',
  initialState: {
    items: null
  },
  reducers: {
    showCondolences(state, action) {
      state.items = action.payload.items;
    }
  }
});

export const condolenceActions = condolenceSlice.actions;

export default condolenceSlice;

import { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import classes from './RecoverTributModal.module.css';

const RecoverTributModal = props => {
  const [fullWidth] = useState(true);
  const [maxWidth] = useState('sm');

  const closeModalHandler = () => {
    props.recoverTributModal();
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.closeModal}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      <div>
        <DialogContent>
          <DialogContentText className={classes.textInfo}>
            El Tributo se recuperó <br /> con éxito
          </DialogContentText>
        </DialogContent>
        <div className={classes.btnsContainer}>
          <Button
            className={classes.recoverBtn}
            onClick={closeModalHandler}
          >
            De acuerdo
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

RecoverTributModal.propTypes = {
  // eslint-disable-next-line react/require-default-props
  open: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  closeModal: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  recoverTributModal: PropTypes.func
};

export default RecoverTributModal;

import PropTypes from 'prop-types';

import classes from './CardData.module.css';

const CardData = props => {
  return (
    <>
      <div className={classes.section}>
        <figure className={classes.cardImage}>
          <img
            src={
              props.cardInfo.paymentBrand == 'mastercard'
                ? 'https://d3cohyhv4rpctw.cloudfront.net/masterdacradLogo.png'
                : props.cardInfo.paymentBrand == 'visa'
                ? 'https://d3cohyhv4rpctw.cloudfront.net/visaLogo.png'
                : props.cardInfo.paymentBrand == 'amex'
                ? 'https://d3cohyhv4rpctw.cloudfront.net/americanexpressLogo.png'
                : 'https://d3cohyhv4rpctw.cloudfront.net/americanexpressLogo.png'
            }
            alt="icon card"
            className={classes.img}
          />
        </figure>
        <aside className={classes.generalCardData}>
          <p className={classes.cardText}>
            Tarjeta de{' '}
            {props.cardInfo.fundingType === 'credit'
              ? 'credito'
              : 'debito'}{' '}
            {props.cardInfo.paymentBrand == 'mastercard'
              ? 'Mastercard'
              : props.cardInfo.paymentBrand == 'visa'
              ? 'Visa'
              : props.cardInfo.paymentBrand == 'JCB'
              ? 'JCB'
              : props.cardInfo.paymentBrand == 'UnionPay'
              ? 'UnionPay'
              : props.cardInfo.paymentBrand == 'Discover'
              ? 'Discover'
              : props.cardInfo.paymentBrand == 'amex'
              ? 'American Express'
              : props.cardInfo.paymentBrand == 'Diners Club'
              ? 'Diners Club'
              : 'Unkown'}
            {props.delete && (
              <span className={classes.cardNumber}>
                **** {props.cardInfo.last4digits}
              </span>
            )}
          </p>
        </aside>
      </div>
      {!props.delete && (
        <>
          <div className={classes.section}>
            <p className={classes.title}>Expira</p>
            <p className={classes.cardText}>
              {props.cardInfo.expMonth} / {props.cardInfo.expYear}
            </p>
          </div>
          <div className={classes.section}>
            <p className={classes.title}>Datos de la tarjeta</p>
            <p className={classes.cardSubtext}>
              No. de tarjeta:{' '}
              <span className={classes.cardText}>
                <span className={classes.hideNumbersCard}>
                  **** **** ****
                </span>{' '}
                {props.cardInfo.last4digits}
              </span>
            </p>
          </div>
        </>
      )}
    </>
  );
};

CardData.propTypes = {
  // eslint-disable-next-line react/require-default-props
  cardInfo: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  delete: PropTypes.bool
};

export default CardData;

import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { getTributsByWord } from '../../../store/tribut-actions';

import classes from './BannerSection.module.css';

const BannerSection = () => {
  const originalURL = window.location.host;
  const [openTributs, setOpenTributs] = useState(true);
  const [tributs, setTributs] = useState(null);
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [searchUser, setSearchUser] = useState('');
  const { register, handleSubmit } = useForm();

  const handleSearchUser = event => {
    setSearchUser(event.target.value);
  };

  const sendTributsHandler = tributId => () => {
    if (
      originalURL === 'https://tribut.org' ||
      originalURL === 'localhost:3000'
    ) {
      window.location.href = `/tribut/${tributId}/memorias`;
    } else {
      window.location.href = `https://tribut.org/tribut/${tributId}/memorias`;
    }
  };

  const handleClose = () => {
    setOpenTributs(false);
  };

  const handleOpen = () => {
    setOpenTributs(true);
  };

  const tributSearchHandler = async () => {
    if (searchUser.length > 0) {
      setOpenTributs(true);
      let _tributs = await getTributsByWord(searchUser);
      setTributs(_tributs);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      let _tributs = await getTributsByWord(searchUser);
      setTributs(_tributs);
    };
    if (searchUser.length > 0) {
      if (typingTimeout) clearTimeout(typingTimeout);
      setTypingTimeout(
        setTimeout(() => {
          setOpenTributs(true);
          fetchData();
        }, 800)
      );
    }
  }, [searchUser]);

  const onSubmit = async data => {
    if (searchUser.length > 0) {
      setOpenTributs(true);
      let _tributs = await getTributsByWord(data.searchUser);
      setTributs(_tributs);
    }
  };

  return (
    <Fragment>
      <div className={classes.inTributBanner}>
        <p className={classes.inTribut__title}>
          Nuestros recuerdos tienen que ser transmitidos por quienes
          nos conocieron en vida, en las historias que cuentan sobre
          nosotros...
        </p>
        {originalURL === 'https://tribut.org' ||
        originalURL === 'localhost:3000' ? (
          <Link
            className={classes.inTribut__btn}
            to="/tribut/create"
          >
            Crea un Tributo
          </Link>
        ) : (
          <a
            className={classes.inTribut__btn}
            href="https://tribut.org/tribut/create"
          >
            Crea un Tributo
          </a>
        )}
      </div>
      <div className={classes.searchUserContainer}>
        <p className={classes.searchText}>
          ¿Buscas un Tributo existente?
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl
            className={classes.searchUser__input}
            variant="outlined"
          >
            <InputLabel htmlFor="searchUser">
              Nombre de la persona
            </InputLabel>
            <OutlinedInput
              id="searchUser"
              {...register('searchUser', { required: true })}
              onChange={handleSearchUser}
              value={searchUser}
              label="Nombre de la persona"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    type="submit"
                    onClick={tributSearchHandler}
                    aria-label="toggle password visibility"
                    edge="end"
                  >
                    <SearchIcon className={classes.searchIcon} />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </form>
        {tributs !== null && openTributs && tributs.length > 0 && (
          <aside className={classes.optionContainer}>
            <Select
              onClose={handleClose}
              onOpen={handleOpen}
              open={openTributs}
              className={classes.tributsToSelectContainer}
              color="secondary"
            >
              {tributs === 'error' ? (
                <MenuItem
                  disabled
                  className={classes.tributsToSelect}
                >
                  <p className={classes.tributName}>
                    No se encontraron resultados
                  </p>
                </MenuItem>
              ) : (
                tributs.map(tribut => (
                  <MenuItem
                    onClick={sendTributsHandler(tribut.tributId)}
                    key={tribut.tributId}
                    value={tribut}
                    className={classes.tributsToSelect}
                  >
                    <figure className={classes.tributAvatar}>
                      <img
                        alt=""
                        className={classes.img}
                        src={tribut.urlAvatar}
                      />
                    </figure>
                    <p className={classes.tributName}>
                      {tribut.firstName} {tribut.lastName}
                    </p>
                  </MenuItem>
                ))
              )}
            </Select>
          </aside>
        )}
      </div>
    </Fragment>
  );
};

export default BannerSection;

import { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

import StripeContainer from '../StripeContainer/StripeContainer';
import DialogMessage from 'components/UI/DialogMessage/DialogMessage';
import CardData from 'components/UI/CardData/CardData';
import DeleteCardModal from '../DeleteCardModal/DeleteCardModal';

import { deleteCard } from 'store/payment-actions';

import classes from './PaymentMethod.module.css';

const AddNewPaymentMethod = props => {
  const [showDialog, setShowDialog] = useState(false);
  const [message, setMessage] = useState('');
  const [deleteCardModalStatus, setDeleteCardModalStatus] =
    useState(false);

  const deleteCardModalHandler = () => {
    setDeleteCardModalStatus(!deleteCardModalStatus);
  };

  const deleteCardHandler = () => {
    (async () => {
      let deleteCardData = await deleteCard(
        props.subscriptionData.paymentId
      );
      if (deleteCardData) {
        props.reloadUserData();
      }
    })();
  };

  const newCardSuccessHandler = () => {
    setShowDialog(true);
    setMessage('El método de pago fue agregado exitosamente');
  };

  const closeDialogHandler = () => {
    setShowDialog(false);
    setMessage('');
    props.reloadUserData();
    props.changeView();
  };

  return !props.addCreditCard ? (
    <>
      {props.subscriptionData.paymentId ? (
        <aside className={classes.dataContainer}>
          <p className={classes.title}>Mi tarjeta</p>
          <CardData cardInfo={props.subscriptionData} />
          <div className={classes.section}>
            <Button
              className={classes.deleteCard}
              onClick={deleteCardModalHandler}
            >
              Eliminar
            </Button>
            <Button
              className={classes.changeCard}
              onClick={props.changeView}
            >
              Cambiar
            </Button>
          </div>
          <DeleteCardModal
            closeModal={deleteCardModalHandler}
            open={deleteCardModalStatus}
            cardInfo={props.subscriptionData}
            deleteCard={deleteCardHandler}
          />
        </aside>
      ) : (
        <aside className={classes.dataContainer}>
          <Button
            className={classes.newMethodBtn}
            onClick={props.changeView}
          >
            Agregar un metodo de pago
          </Button>
          <p className={classes.paymentText}>
            Puedes agregar un método de pago en el momento que
            desees, y se seguirá respetando tu período de prueba.
            Cualquier cargo aplicable se realizaría al finalizar el
            período de prueba.
          </p>
        </aside>
      )}
    </>
  ) : (
    <aside className={classes.addCardContainer}>
      <p className={classes.title}>Agregar tarjeta</p>
      <StripeContainer newCardSuccess={newCardSuccessHandler} />
      <DialogMessage
        closeDialog={closeDialogHandler}
        open={showDialog}
        message={message}
      />
    </aside>
  );
};

AddNewPaymentMethod.propTypes = {
  // eslint-disable-next-line react/require-default-props
  changeView: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  reloadUserData: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  addCreditCard: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  subscriptionData: PropTypes.object
};

export default AddNewPaymentMethod;

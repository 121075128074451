import { useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { createComment } from 'store/comment-actions';
import { getUserInfo, setUserInfo } from 'store/generalInfo';
import { getUserData, isLogged } from 'store/user-actions';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';

import DialogNoUser from '../DialogNoUser/DialogNoUser';
import classes from './CommentInput.module.css';

const CommentComponent = ({ object, loadNewComment, tributId }) => {
  const loged = isLogged();
  const { register, handleSubmit, resetField, watch } = useForm();
  const [modalNotUser, setModalNotUser] = useState(false);
  const userDataNew = watch();

  const closeDialog = () => {
    setModalNotUser(false);
  };

  const modalNotUserHandler = () => {
    setModalNotUser(true);
  };

  const onSubmit = () => {};

  const setUserData = async () => {
    (async () => {
      let userInfo = await getUserData();
      setUserInfo(userInfo.user);
      commentMemory();
    })();
  };

  const commentMemory = async () => {
    if (userDataNew && object) {
      let type = null;
      if (object.memoryId) {
        type = 'MEMORY';
      } else if (object.condolenceId) {
        type = 'CONDOLENCE';
      }

      if (userDataNew.comment) {
        let comment = await createComment(
          object,
          userDataNew.comment,
          type
        );
        if (comment) {
          closeDialog();
          resetField('comment');
          loadNewComment(
            getUserInfo().avatar,
            getUserInfo().firstName,
            getUserInfo().lastName,
            userDataNew.comment,
            comment
          );
        }
      }
    }
  };

  return (
    <>
      {tributId !== '1' && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.formCommentCondolence}>
            {getUserInfo().avatar ? (
              <figure className={classes.commentAvatar}>
                <img
                  alt=""
                  className={classes.commentAvatarImg}
                  src={getUserInfo().avatar}
                />
              </figure>
            ) : (
              <div className={classes.noUserContainer}>
                <PersonOutlinedIcon className={classes.noUserIcon} />
              </div>
            )}
            <aside className={classes.inputContainer}>
              <TextField
                className={classes.commentCondolenceField}
                placeholder="Escribe aquí ..."
                {...register('comment', { required: true })}
                sx={{
                  '& fieldset': { border: 'none' }
                }}
              />
            </aside>
            <Button
              className={classes.btnSubmitComment}
              onClick={!loged ? modalNotUserHandler : commentMemory}
            >
              {' '}
              <SendOutlinedIcon />{' '}
            </Button>
          </div>
        </form>
      )}
      {!loged && modalNotUser && (
        <DialogNoUser
          open={modalNotUser}
          closeDialog={closeDialog}
          action={setUserData}
        />
      )}
    </>
  );
};

CommentComponent.propTypes = {
  // eslint-disable-next-line react/require-default-props
  object: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  loadNewComment: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  tributId: PropTypes.string
};

export default CommentComponent;

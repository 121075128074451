import axios from 'axios';
import config from './config';

import { getAuthHeader } from './user-actions';

export const createTribut = async (
  first_name,
  last_name,
  birthday,
  birthplace,
  date_death,
  place_death,
  dedication,
  phrase,
  note,
  fileName,
  mediaType,
  ocurrences
) => {
  const data = {
    first_name,
    last_name,
    birthday,
    birthplace,
    date_death,
    place_death,
    dedication,
    phrase,
    note,
    fileName,
    mediaType,
    ocurrences
  };

  let tribut = await axios.put(config.API_TRIBUT, data, {
    headers: getAuthHeader()
  });
  if (tribut && tribut.data && tribut.data.httpStatus === 200) {
    return tribut.data.tributId;
  }
  return null;
};

export const editTribut = async (
  tributId,
  first_name,
  last_name,
  birthday,
  birthplace,
  date_death,
  place_death,
  dedication,
  phrase,
  note,
  fileName,
  mediaType
) => {
  const data = {
    tributId,
    first_name,
    last_name,
    birthday,
    birthplace,
    date_death,
    place_death,
    dedication,
    phrase,
    note,
    fileName,
    mediaType
  };

  let editTribut = await axios.post(
    `${config.API_TRIBUT}/update`,
    data,
    {
      headers: getAuthHeader()
    }
  );
  if (
    editTribut &&
    editTribut.data &&
    editTribut.data.httpStatus === 200
  ) {
    return editTribut.data.updated;
  }
  return null;
};

export const getTributs = async () => {
  let tribut = await axios.get(config.API_TRIBUT, {
    headers: getAuthHeader()
  });
  if (tribut && tribut.data && tribut.data.httpStatus === 200) {
    return tribut.data.lstTribut;
  }
  return null;
};

export const getTributsByWord = async word => {
  let tributs = await axios.get(`${config.API_TRIBUT}/search`, {
    params: {
      word
    }
  });
  if (tributs && tributs.data && tributs.data.httpStatus === 200) {
    if (tributs.data.lstTributs.length !== 0) {
      return tributs.data.lstTributs;
    } else {
      return 'error';
    }
  }
  return null;
};

export const getTributDetails = async tributId => {
  let tribut = await axios.get(`${config.API_TRIBUT}/details`, {
    params: {
      tributId
    }
  });
  if (tribut && tribut.data && tribut.data.httpStatus === 200) {
    return tribut.data.tribut;
  }
  return null;
};

export const getTributsTop = async () => {
  let tributList = await axios.get(`${config.API_TRIBUT}/list`, {});
  if (
    tributList &&
    tributList.data &&
    tributList.data.httpStatus === 200
  ) {
    return tributList.data.lstTribut;
  }
  return null;
};

export const getBioDetails = async tributId => {
  let tributBio = await axios.get(
    `${config.API_TRIBUT}/bio/details`,
    {
      params: {
        tributId
      },
      headers: getAuthHeader()
    }
  );
  if (
    tributBio &&
    tributBio.data &&
    tributBio.data.httpStatus === 200
  ) {
    return tributBio.data.bio;
  }
  if (tributBio.data.httpStatus === 302) {
    return tributBio.data;
  }
  return null;
};

export const getCredentialsUploadImageS3 = async (
  tributId,
  contentType,
  fileName,
  mediaType
) => {
  let response = await axios.get(
    config.API_TRIBUT +
      '/key?tributId=' +
      tributId +
      '&contentType=' +
      contentType +
      '&fileName=' +
      fileName +
      '&mediaType=' +
      mediaType,
    { headers: getAuthHeader() }
  );
  if (
    response &&
    response.data &&
    response.data.httpStatus === 200
  ) {
    return response.data.credentials;
  }
  return null;
};

export const followTribut = async tributId => {
  const options = {
    tributId
  };
  let follow = await axios.post(
    `${config.API_FOLLOW}/tribut/follow`,
    options,
    {
      headers: getAuthHeader()
    }
  );
  if (follow.data.httpStatus === 200) {
    return true;
  }
  return false;
};

export const deleteTribut = async tributId => {
  const options = {
    tributId
  };
  let deleteTributData = await axios.post(
    `${config.API_TRIBUT}/delete`,
    options,
    {
      headers: getAuthHeader()
    }
  );
  if (deleteTributData.data.deleted.success === true) {
    return true;
  }
  return false;
};

export const recoverTribut = async tributId => {
  const options = {
    tributId
  };
  let recoverTributData = await axios.post(
    `${config.API_TRIBUT}/recover`,
    options,
    {
      headers: getAuthHeader()
    }
  );
  if (recoverTributData.data.recovered.success === true) {
    return true;
  }
  return false;
};

export const getAltar = async () => {
  let altarData = await axios.get(`${config.API_TRIBUT}/follow`, {
    headers: getAuthHeader()
  });
  if (altarData.data.lstFolow) {
    return altarData.data.lstFolow;
  }
  return null;
};

export const sendMessage = async tributId => {
  const options = {
    tributId
  };
  let sendMessageData = await axios.post(
    `${config.API_FOLLOW}/notify`,
    options,
    {
      headers: getAuthHeader()
    }
  );
  if (sendMessageData.data.httpStatus === 200) {
    return true;
  }
  return false;
};

import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { getMemoryList } from 'store/memory-actions';

import Loading from 'components/UI/Loading/Loading';
import Memory from 'components/tribut/MemorySection/Memory/Memory';

import classes from './MemoryPage.module.css';

const MemoryPage = () => {
  const [memoryData, setMemoryData] = useState(null);
  const [loading, setLoading] = useState(true);
  let params = useParams();
  let tributId = params.tributId ? params.tributId : 73;
  let memoryId = params.idMemory ? params.idMemory : '';
  const dispatch = useDispatch();
  const dataMemories = useSelector(state => state.memoriesData);

  useEffect(() => {
    ReactGA.initialize('G-N3MP6T91TP');
    ReactGA.pageview(`/tribut/${tributId}/memory/${memoryId}`);
  }, []);

  useEffect(() => {
    if (dataMemories.data.length === 0) {
      dispatch(getMemoryList(tributId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (dataMemories.data) {
      if (dataMemories.data === 'error') {
        setLoading(false);
        setMemoryData('error');
      }
      if (
        dataMemories.data.length > 0 &&
        dataMemories.data !== 'error'
      ) {
        setLoading(false);
        dataMemories.data.map(memory => {
          if (parseInt(memoryId) === memory.memoryId) {
            setMemoryData(memory);
          }
        });
      }
    }
  }, [dataMemories]);

  return (
    <>
      {!loading ? (
        <>
          {memoryData === 'error' ? (
            <p>No hay memoria</p>
          ) : (
            <section
              className={classes.TributContainer}
              id="allMemories"
            >
              <div className={classes.allMemoriesContainer}>
                {memoryData && (
                  <>
                    <Button
                      component={Link}
                      to={
                        tributId !== 73
                          ? `/tribut/${tributId}/memorias`
                          : '/alvaro'
                      }
                      className={classes.btnBack}
                    >
                      <ArrowBackIcon
                        className={classes.btnBackIcon}
                      />
                    </Button>
                    <p className={classes.title}>Memorias</p>
                    <Memory
                      dataMemory={memoryData}
                      tributId={tributId}
                      memoryView={parseInt(memoryId)}
                    />
                  </>
                )}
              </div>
            </section>
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default MemoryPage;

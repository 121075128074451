import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Drawer from '@mui/material/Drawer';

import Menu from '../../UI/Menu/Menu';

import {
  getLoggerData,
  getUserData,
  isLogged
} from '../../../store/user-actions';
import { setUserInfo } from '../../../store/generalInfo';

import classes from './MainHeader.module.css';

const Header = () => {
  const originalURL = window.location.host;
  const [state, setState] = useState(false);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    (async () => {
      await getLoggerData();
    })();
    if (isLogged()) {
      if (!userData) {
        (async () => {
          let userInfo = await getUserData();
          setUserData(userInfo.user);
          setUserInfo(userInfo.user);
        })();
      }
    }
  }, [isLogged(), userData]);

  const toggleDrawer = () => () => {
    setState(!state);
  };

  return (
    <AppBar position="static">
      <Toolbar className={classes.header}>
        <IconButton
          onClick={toggleDrawer(state, true)}
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
        >
          <MenuIcon className={classes.menuIcon} />
        </IconButton>
        <Drawer
          anchor="left"
          open={state}
          onClose={toggleDrawer(state, false)}
        >
          <Menu
            userData={userData}
            closeMenu={toggleDrawer(state, false)}
          />
        </Drawer>
        {originalURL === 'https://tribut.org' ||
        originalURL === 'localhost:3000' ? (
          <Link to="/" className={classes.inTributIconContainer}>
            <figure className={classes.inTributICon}>
              <img
                className={classes.img}
                src="https://d3cohyhv4rpctw.cloudfront.net/Logo_Tribut.png"
                alt="Tribut"
              />
            </figure>
          </Link>
        ) : (
          <a
            className={classes.inTributIconContainer}
            href="https://tribut.org"
          >
            <figure className={classes.inTributICon}>
              <img
                className={classes.img}
                src="https://d3cohyhv4rpctw.cloudfront.net/Logo_Tribut.png"
                alt="Tribut"
              />
            </figure>
          </a>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;

import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';

import UserInput from 'components/UI/UserInput/UserInput';
import Loading from 'components/UI/Loading/Loading.js';

import { resetPassword } from '../../../store/recoverAccount-actions.js';
import { loginUser } from '../../../store/login-actions';
import classes from './ThreeStep.module.css';

const ThreeStep = props => {
  const { register, watch } = useForm();
  const [load, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(false);
  const userDataNew = watch();

  useEffect(() => {
    setError(false);
    setMessage('');
  }, [userDataNew.newPassword, userDataNew.confirmPassword]);

  const savePasswordHandler = () => {
    setLoading(true);
    if (userDataNew.newPassword.length < 8) {
      setLoading(false);
      setError(true);
      setMessage(
        'Tu contraseña deberá ser de 8 carácteres como mínimo'
      );
    }
    if (userDataNew.newPassword !== userDataNew.confirmPassword) {
      setLoading(false);
      setError(true);
      setMessage('Las contraseñas deben de coincidir');
    }
    if (
      userDataNew.newPassword === userDataNew.confirmPassword &&
      userDataNew.newPassword.length >= 8
    ) {
      (async () => {
        let phone = props.value.split(' ');
        const resetPasswordData = await resetPassword(
          props.typeValue === 'email' ? props.value : null,
          props.typeValue !== 'email' ? phone[1] : null,
          props.code,
          userDataNew.newPassword
        );
        if (resetPasswordData) {
          const loginUserData = await loginUser(
            props.typeValue === 'email' ? props.value : null,
            userDataNew.newPassword,
            props.typeValue !== 'email' ? phone[1] : null
          );
          if (loginUserData) {
            window.location.href = '/';
          }
        }
      })();
    }
  };

  return (
    <>
      {error && <Alert severity="warning">{message}</Alert>}
      <p className={classes.recoverAccountText}>
        Tu contraseña deberá ser de <strong>8 carácteres</strong>{' '}
        como <strong>mínimo</strong>
      </p>
      <FormControl className={classes.selectOptionContainer}>
        <UserInput
          inputText="Nueva contraseña"
          type="password"
          size="small"
          name="newPassword"
          status={{
            ...register('newPassword', { required: false })
          }}
        />
        <UserInput
          inputText="Confirmar contraseña"
          type="password"
          size="small"
          name="confirmPassword"
          status={{
            ...register('confirmPassword', { required: false })
          }}
        />
      </FormControl>
      {!load ? (
        <aside className={classes.containerBtns}>
          <Button
            className={classes.sendBtn}
            onClick={savePasswordHandler}
          >
            Guardar
          </Button>
        </aside>
      ) : (
        <Loading />
      )}
    </>
  );
};

ThreeStep.propTypes = {
  // eslint-disable-next-line react/require-default-props
  code: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  typeValue: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  value: PropTypes.string
};

export default ThreeStep;

import { Fragment, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import PhoneInputForm from 'components/UI/PhoneInputForm/PhoneInputForm';
import UserInput from 'components/UI/UserInput/UserInput';
import Loading from 'components/UI/Loading/Loading.js';

import {
  recoverAccountEmail,
  recoverAccountPhone
} from '../../../store/recoverAccount-actions.js';

import classes from './FirstStep.module.css';

const FirstStep = props => {
  const { register, watch } = useForm();
  const [value, setValue] = useState(null);
  const [load, setLoad] = useState(false);
  const [lada, setLada] = useState('');
  const [phone, setPhone] = useState('');
  const userDataNew = watch();

  useEffect(() => {
    props.sendPassword(false);
  }, [userDataNew.email]);

  const handleChange = event => {
    props.sendPassword(false);
    setValue(event.target.value);
  };

  const phoneHandler = (lada, phone) => {
    props.sendPassword(false);
    setLada(`+${lada}`);
    setPhone(phone);
  };

  const sendMessage = () => {
    setLoad(true);
    if (value === 'email') {
      (async () => {
        props.sendPassword(
          await recoverAccountEmail(userDataNew.email)
        );
        props.setUsersValue(userDataNew.email, 'email');
        setLoad(false);
      })();
    } else {
      (async () => {
        props.sendPassword(await recoverAccountPhone(lada, phone));
        props.setUsersValue(`${lada} ${phone}`, 'phone');
        setLoad(false);
      })();
    }
  };

  return (
    <Fragment>
      <p className={classes.recoverAccountText}>
        ¿Cómo quieres recuperar tu cuenta?
      </p>
      <FormControl className={classes.selectOptionContainer}>
        <RadioGroup value={value} onChange={handleChange}>
          <FormControlLabel
            value="email"
            control={<Radio color="default" />}
            label="Correo Electrónico"
          />
          {value === 'email' && (
            <UserInput
              type="email"
              size="small"
              name="email"
              status={{ ...register('email', { required: false }) }}
            />
          )}
          <FormControlLabel
            value="phone"
            control={<Radio color="default" />}
            label="Número de celular"
          />
          {value === 'phone' && (
            <PhoneInputForm phoneHandler={phoneHandler} />
          )}
        </RadioGroup>
      </FormControl>
      {!load ? (
        <aside className={classes.containerBtns}>
          <Button
            className={classes.cancelBtn}
            component={Link}
            to="/"
          >
            Cancelar
          </Button>
          <Button className={classes.sendBtn} onClick={sendMessage}>
            Enviar
          </Button>
        </aside>
      ) : (
        <Loading />
      )}
      <p className={classes.recoverAccountText}>
        ¿Ya no cuentas con el número de celular asociado a tu cuenta
        ni con acceso a tu correo?
      </p>
      <p className={classes.recoverAccountText}>
        Puedes comunicarte con nosotros al{' '}
        <Button
          className={classes.helpBtn}
          component="a"
          href="http://ayuda.mayahii.com/"
          target="_blank"
        >
          centro de ayuda
        </Button>{' '}
        para restaurar el acceso a tu cuenta
      </p>
    </Fragment>
  );
};

FirstStep.propTypes = {
  // eslint-disable-next-line react/require-default-props
  sendPassword: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  setUsersValue: PropTypes.func
};

export default FirstStep;

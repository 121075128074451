import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import Button from '@mui/material/Button';

import { getAltar } from '../../store/tribut-actions';

import classes from './Altar.module.css';

const Altar = () => {
  const [altar, setAltar] = useState([]);

  useEffect(() => {
    ReactGA.initialize('G-N3MP6T91TP');
    ReactGA.pageview('/altar');
    (async () => {
      let altarData = await getAltar();
      setAltar(altarData);
    })();
  }, []);

  return (
    <>
      <section className={classes.altarContainer}>
        <h3 className={classes.title}>Mi Altar de Tributos</h3>
        <Button
          component={Link}
          to="/tribut/create"
          className={classes.createTributBtn}
        >
          Crear nuevo Tributo
        </Button>
        {altar.length > 0 &&
          altar.map(tribut => (
            <Button
              key={tribut.tributId}
              component={Link}
              to={`/tribut/${tribut.tributId}/memorias`}
              className={classes.tributContainer}
            >
              <div>
                {tribut.urlAvatar ? (
                  <figure className={classes.tributAvatar}>
                    <img
                      src={tribut.urlAvatar}
                      alt={tribut.firstName}
                      className={classes.tributImg}
                    />
                  </figure>
                ) : (
                  <div className={classes.noUserContainer}>
                    <PersonOutlinedIcon
                      className={classes.noUserIcon}
                    />
                  </div>
                )}
                <p className={classes.tributName}>
                  {tribut.firstName} {tribut.lastName}
                </p>
                <div className={classes.complementaryInfo}>
                  <p className={classes.item}>
                    <span className={classes.itemTitle}>
                      Memorias:
                    </span>{' '}
                    {tribut.memories}
                  </p>
                  <p className={classes.item}>
                    <span className={classes.itemTitle}>
                      Condolencias:
                    </span>{' '}
                    {tribut.condolences}
                  </p>
                  <p className={classes.item}>
                    <span className={classes.itemTitle}>
                      Visitas:
                    </span>{' '}
                    {tribut.views}
                  </p>
                </div>
              </div>
            </Button>
          ))}
      </section>
    </>
  );
};

export default Altar;

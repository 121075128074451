import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

import { getCondolenceList } from '../../../store/condolence-actions';

import AddCondolence from '../AddCondolence/AddCondolence';
import CommentsCondolence from '../CommentsCondolence/CommentsCondolence';
import Loading from 'components/UI/Loading/Loading';
import DialogMessage from 'components/UI/DialogMessage/DialogMessage';

import classes from './Condolence.module.css';

const Condolence = ({ mortuaryData, tributId, tributDetails }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const dispatch = useDispatch();
  const condolenceItems = useSelector(
    state => state.condolences.items
  );

  useEffect(() => {
    dispatch(getCondolenceList(tributId));
  }, [dispatch, tributId]);

  const closeDialogHandler = () => {
    setOpenDialog(false);
  };

  const openDialogHandler = () => {
    setOpenDialog(true);
  };

  const callCondolencesHandlder = () => {
    dispatch(getCondolenceList(tributId));
  };

  return (
    <Fragment>
      <div className={classes.condolenceContainer}>
        {tributId != 1 && (
          <AddCondolence
            tributId={tributId}
            callCondolences={callCondolencesHandlder}
          />
        )}
        {/* <AddNewComment tributId={tributId} open={handleClickOpen} /> */}
        <p className={classes.dedicationText}>
          {tributDetails.dedication}
        </p>
        {mortuaryData && (
          <aside className={classes.mortuaryBtncontainer}>
            <Button
              className={classes.mortuaryDataBtn}
              onClick={openDialogHandler}
            >
              Información del servicio funerario
            </Button>
          </aside>
        )}
        {condolenceItems != null ? (
          condolenceItems.length > 0 ? (
            <>
              {/* <p className={classes.totalCondolences}>
                <span className={classes.number}>
                  {condolenceItems.length} Condolencias
                </span>
              </p> */}
              {condolenceItems.map(condolence => (
                <CommentsCondolence
                  key={condolence.condolenceId}
                  tributId={tributId}
                  data={condolence}
                  callCondolences={callCondolencesHandlder}
                />
              ))}
            </>
          ) : (
            <p className={classes.addCondolenceText}>
              Sé el primero en enviar tus Condolencias
            </p>
          )
        ) : (
          <Loading />
        )}
      </div>
      <DialogMessage
        closeDialog={closeDialogHandler}
        open={openDialog}
        message={mortuaryData}
        mortuaryMessage
        title="Información del servicio funerario"
      />
    </Fragment>
  );
};

Condolence.propTypes = {
  // eslint-disable-next-line react/require-default-props
  tributId: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  mortuaryData: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  tributDetails: PropTypes.object
};

export default Condolence;

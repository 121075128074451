import { useEffect } from 'react';
import ReactGA from 'react-ga4';

import classes from './NotFound.module.css';

const NotFound = () => {
  useEffect(() => {
    ReactGA.initialize('G-N3MP6T91TP');
    ReactGA.pageview('/politics');
  }, []);

  return (
    <>
      <section className={classes.container}>
        <p>Página no encontrada</p>
      </section>
    </>
  );
};

export default NotFound;

import axios from 'axios';
import config from './config';
import { getAuthHeader } from './user-actions';
import { condolenceActions } from './condolence-slice';

export const getCondolenceList = tributId => {
  return async dispatch => {
    let response = await axios.get(`${config.API_CONDOLENCE}`, {
      params: {
        tributId
      },
      headers: getAuthHeader()
    });
    // let response = await axios.get(
    //   config.API_CONDOLENCE + '?tributId=' + tributId,
    //   {}
    // );
    if (
      response &&
      response.data &&
      response.data.httpStatus === 200
    ) {
      dispatch(
        condolenceActions.showCondolences({
          items: response.data.condolences
        })
      );
      // return response.data.condolences;
    }
    return null;
  };
};

export const getCondolenceDetails = async condolenceId => {
  let response = await axios.get(
    `${config.API_CONDOLENCE}/details`,
    {
      params: {
        condolenceId
      }
    }
  );
  // let response = await axios.get(
  //   config.API_CONDOLENCE + '/details?condolenceId=' + condolenceId,
  //   {}
  // );
  if (
    response &&
    response.data &&
    response.data.httpStatus === 200
  ) {
    return response.data.condolence;
  }
  return null;
};

export const getCondolenceComments = async condolenceId => {
  let response = await axios.get(
    `${config.API_CONDOLENCE}/comments`,
    {
      params: {
        condolenceId
      }
    }
  );
  // let response = await axios.get(
  //   config.API_CONDOLENCE + '/comments?condolenceId=' + condolenceId,
  //   {}
  // );
  if (
    response &&
    response.data &&
    response.data.httpStatus === 200
  ) {
    return response.data.comments;
  }
  return null;
};

// TODO Implement video/mp4
export const createCondolence = async (
  tributId,
  message,
  fileName,
  mediaType
) => {
  const data = {
    tributId,
    message,
    mediaType,
    fileName
  };

  let condolenceData = await axios.put(config.API_CONDOLENCE, data, {
    headers: getAuthHeader()
  });
  if (
    condolenceData &&
    condolenceData.data &&
    condolenceData.data.httpStatus === 200
  ) {
    return condolenceData.data.condolenceId;
  }
  return null;
};

export const editCondolence = async (
  tributId,
  condolenceId,
  message,
  fileName,
  mediaType
) => {
  const data = {
    tributId,
    condolenceId,
    message,
    mediaType,
    fileName
  };

  let condolenceData = await axios.post(
    `${config.API_CONDOLENCE}/update`,
    data,
    {
      headers: getAuthHeader()
    }
  );
  if (condolenceData.data.updated) {
    return condolenceData.data.updated;
  }
  return null;
};

export const getCondolenceCredentialsUploadImageS3 = async (
  tributId,
  condolenceId,
  contentType,
  fileName,
  mediaType
) => {
  let response = await axios.get(
    config.API_CONDOLENCE +
      '/key?tributId=' +
      tributId +
      '&condolenceId=' +
      condolenceId +
      '&contentType=' +
      contentType +
      '&fileName=' +
      fileName +
      '&mediaType=' +
      mediaType,
    { headers: getAuthHeader() }
  );
  if (
    response &&
    response.data &&
    response.data.httpStatus === 200
  ) {
    return response.data.credentials;
  }
  return null;
};

export const deleteCondolence = async condolenceId => {
  let deleteCondolenceData = await axios.post(
    `${config.API_CONDOLENCE}/delete`,
    {
      condolenceId
    },
    {
      headers: getAuthHeader()
    }
  );
  if (deleteCondolenceData.data.condolence === true) {
    return true;
  }
  return false;
};

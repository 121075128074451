import { useEffect } from 'react';
import ReactGA from 'react-ga4';

import classes from './Politics.module.css';

const Politics = () => {
  useEffect(() => {
    ReactGA.initialize('G-N3MP6T91TP');
    ReactGA.pageview('/politics');
  }, []);

  return (
    <section className={classes.container}>
      <p className={classes.title}>Aviso de Privacidad</p>
      <p className={classes.text}>
        De conformidad con lo establecido en la Ley Federal de
        Protección de Datos Personales en Posesión de los
        Particulares,{' '}
        <i>
          <u>Tribut</u>
        </i>{' '}
        pone a su disposición el siguiente aviso de privacidad.
      </p>
      <p className={classes.text}>
        <i>
          <u>Tribut</u>
        </i>
        , es responsable del uso y protección de sus datos
        personales, en este sentido y atendiendo las obligaciones
        legales establecidas en la Ley Federal de Protección de Datos
        Personales en Posesión de los Particulares, a través de este
        instrumento se informa a los titulares de los datos, la
        información que de ellos se recaba y los fines que se le
        darán a dicha información.
      </p>
      <p className={classes.text}>
        Los datos personales recabados como nombres, apellidos,
        correo electrónico, edad, domicilio, entre otros. Serán
        utilizados únicamente con fines de brindar el servicio
        solicitado en nuestro sitio web. El tratamiento de datos
        personales será el que resulte necesario , adecuado y
        relevante en relación con las finalidades previstas en este
        Aviso de Privacidad.
      </p>
      <p className={classes.text}>
        Sus datos personales no serán transferidos, difundidos ni
        distribuidos, salvo lo señalado en el artículo 22 de la Ley
        General de Protección de Datos Personales en Posesión de
        Sujetos Obligados o en el caso que exista consentimiento
        expreso de su parte. Solo tendrán acceso a esta información
        el titular de los datos, sus representantes legales y los
        servidores públicos facultados para ellos.
      </p>
    </section>
  );
};

export default Politics;

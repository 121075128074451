import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { useRecordWebcam } from 'react-record-webcam';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';

import VideoRecord from 'components/UI/VideoRecord/VideoRecord';
import SelectTypeFile from '../SelectTypeFile/SelectTypeFile';
import DialogMessage from 'components/UI/DialogMessage/DialogMessage';

import { uploadMultimediaTribut } from 'store/uploadFileS3-actions';
import { getUserData, isLogged } from 'store/user-actions';
import {
  createCondolence,
  getCondolenceCredentialsUploadImageS3
} from 'store/condolence-actions';

import classes from './AddCondolence.module.css';

const AddCondolenceComponent = props => {
  const { handleSubmit } = useForm();

  const loged = isLogged();
  const [userData, setUserData] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [messageDialog, setMessageDialog] = useState('');
  const [message, setMessage] = useState('');
  const [loadFile, setLoadfile] = useState(false);
  const [imgImageUpload, setImgImageUpload] = useState('');
  const [imgVideoUpload, setImgVideoUpload] = useState('');
  const [file, setFile] = useState(null);
  const [viewMedia, setViewMedia] = useState(null);
  const [contentType, setContentType] = useState(null);
  const [fileExtension, setFileExtension] = useState(null);
  const [fileName] = useState(Date.now());
  const OPTIONS = {
    filename: fileName,
    fileType: 'mp4',
    width: 640,
    height: 480
  };
  const recordWebcam = useRecordWebcam(OPTIONS);
  const onSubmit = () => {};
  useEffect(() => {
    if (loged) {
      (async () => {
        let userInfo = await getUserData();
        setUserData(userInfo.user);
      })();
    }
  }, [loged]);

  const setDataFileHandler = (
    type,
    fileExtension,
    contentType,
    file,
    img
  ) => {
    recordWebcam.close();
    setViewMedia(type);
    setFileExtension(fileExtension);
    setContentType(contentType);
    setFile(file);
    if (type === 'IMAGE') {
      setImgImageUpload(img);
    } else {
      setImgVideoUpload(img);
    }
  };

  const selectTypeFileHandler = type => () => {
    setViewMedia(type);
    if (type === 'RECORD') {
      recordWebcam.open();
    } else {
      recordWebcam.close();
    }
  };

  const resetInfo = type => () => {
    if (type === 'all') {
      setMessage('');
    }
    setViewMedia(null);
    setFileExtension(null);
    setContentType(null);
    setFile(null);
    setImgImageUpload('');
    setImgVideoUpload('');
    recordWebcam.close();
  };

  const closeDialogHandler = () => {
    props.callCondolences();
    setLoadfile(false);
    // setView('UPLOAD');
    setShowDialog(false);
    setImgImageUpload(
      'https://d3cohyhv4rpctw.cloudfront.net/icon_imagen.png'
    );
    setImgVideoUpload(
      'https://d3cohyhv4rpctw.cloudfront.net/icon_video.png'
    );
  };

  const createCondolenceAction = async () => {
    setLoadfile(true);

    let condolenceId = await createCondolence(
      props.tributId,
      message,
      fileName,
      viewMedia === 'RECORD' ? 'mp4' : fileExtension
    );
    if (fileExtension || viewMedia === 'RECORD') {
      let credentials = '';
      if (condolenceId) {
        credentials = await getCondolenceCredentialsUploadImageS3(
          props.tributId,
          condolenceId,
          viewMedia === 'RECORD' ? 'video/mp4' : contentType,
          fileName,
          viewMedia === 'RECORD' ? 'mp4' : fileExtension
        );
      }
      if (viewMedia === 'RECORD') {
        const blob = await recordWebcam.getRecording();
        if (blob) {
          if (credentials) {
            // Upload Image en S3
            let uploaded = await uploadMultimediaTribut(
              credentials,
              blob
            );

            if (uploaded) {
              setShowDialog(true);
              setMessageDialog(
                'La Condolencia fue publicada con éxito'
              );
              setMessage('');
              setViewMedia(null);
              setFileExtension(null);
              setContentType(null);
              setFile(null);
              setImgImageUpload('');
              setImgVideoUpload('');
              recordWebcam.close();
            } else {
              setShowDialog(true);
              setMessageDialog(
                'Hubo un error, te sugerimos volver a intentarlo'
              );
            }
          }
        }
      } else {
        if (file) {
          if (credentials) {
            let uploaded = await uploadMultimediaTribut(
              credentials,
              file
            );

            if (uploaded) {
              setShowDialog(true);
              setMessageDialog(
                'Tu condolencia fue publicada con éxito'
              );
              setMessage('');
              setViewMedia(null);
              setFileExtension(null);
              setContentType(null);
              setFile(null);
              setImgImageUpload('');
              setImgVideoUpload('');
              recordWebcam.close();
            } else {
              setShowDialog(true);
              setMessageDialog(
                'Hubo un error, te sugerimos volver a intentarlo'
              );
            }
          }
        }
      }
    } else {
      if (condolenceId) {
        setShowDialog(true);
        setMessageDialog('Tu condolencia fue publicada con éxito');
        setMessage('');
        setViewMedia(null);
      } else {
        setShowDialog(true);
        setMessageDialog(
          'Hubo un error, te sugerimos volver a intentarlo'
        );
      }
    }
  };

  return (
    <aside className={classes.addCondolenceContainer}>
      {userData ? (
        userData.avatar ? (
          <figure className={classes.userImage}>
            <img
              className={classes.img}
              alt={
                userData
                  ? userData.firstName
                    ? userData.firstName
                    : ''
                  : 'UserName'
              }
              src={userData.avatar}
            />
          </figure>
        ) : (
          <div className={classes.noUserContainer}>
            <PersonOutlinedIcon className={classes.noUserIcon} />
          </div>
        )
      ) : (
        <div className={classes.noUserContainer}>
          <PersonOutlinedIcon className={classes.noUserIcon} />
        </div>
      )}

      <div className={classes.rightSection}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={
            message.length > 25
              ? classes.formContainerBig
              : classes.formContainer
          }
        >
          <div className={classes.form_group}>
            <TextField
              placeholder="Publica un anécdota o mensaje"
              className={
                message.length > 25
                  ? classes.messageInputBig
                  : classes.messageInput
              }
              multiline
              value={message}
              onChange={e => setMessage(e.target.value)}
            />
          </div>
        </form>
        <SelectTypeFile
          selectTypeFileHandler={selectTypeFileHandler}
          changePosition={message.length > 24}
          setData={setDataFileHandler}
        />
      </div>
      {viewMedia && (
        <>
          {viewMedia === 'IMAGE' ? (
            <>
              <div className={classes.deleteMediaContainer}>
                <Button
                  className={classes.deleteMedia}
                  onClick={resetInfo('')}
                >
                  <DeleteOutlinedIcon className={classes.icon} />{' '}
                  Borrar Imagen
                </Button>
              </div>
              <figure className={classes.commentImageContainer}>
                <img
                  className={classes.imgContainer}
                  src={imgImageUpload}
                  alt="image"
                />
              </figure>
            </>
          ) : viewMedia === 'VIDEO' ? (
            <>
              <div className={classes.deleteMediaContainer}>
                <Button
                  className={classes.deleteMedia}
                  onClick={resetInfo('')}
                >
                  <DeleteOutlinedIcon className={classes.icon} />{' '}
                  Borrar video
                </Button>
              </div>
              <aside className={classes.videoContainer}>
                <video
                  controls
                  src={imgVideoUpload}
                  className={classes.video}
                />
              </aside>
            </>
          ) : (
            <>
              <div className={classes.deleteMediaContainer}>
                <Button
                  className={classes.deleteMedia}
                  onClick={resetInfo('')}
                >
                  <DeleteOutlinedIcon className={classes.icon} />{' '}
                  Borrar grabación
                </Button>
              </div>
              <VideoRecord recordWebcam={recordWebcam} />
            </>
          )}
        </>
      )}
      {(message || viewMedia) &&
        (!loadFile ? (
          <div className={classes.btnsContainer}>
            <Button
              onClick={resetInfo('all')}
              className={classes.cancelBtn}
            >
              Cancelar
            </Button>
            <Button
              onClick={createCondolenceAction}
              className={classes.acceptBtn}
            >
              Publicar
            </Button>
          </div>
        ) : (
          <p className={classes.messageText}>
            No cambie de pantalla, esto puede afectar la carga del
            archivo
          </p>
        ))}
      <DialogMessage
        closeDialog={closeDialogHandler}
        open={showDialog}
        message={messageDialog}
      />
    </aside>
  );
};

AddCondolenceComponent.propTypes = {
  // eslint-disable-next-line react/require-default-props
  tributId: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  callCondolences: PropTypes.func
};

export default AddCondolenceComponent;

import { Fragment, useState } from 'react';

import Alert from '@mui/material/Alert';
// import Button from '@mui/material/Button';

// import SuccessMessage from './SuccessMessage/SuccessMessage';
import FirstStep from './FirstStep/FirstStep';
import SecondStep from './SecondStep/SecondStep';
import ThreeStep from './ThreeStep/ThreeStep';

// import classes from './RecoverAccount.module.css';

const RecoverAccount = () => {
  const [message, setMessage] = useState(null);
  const [step, setStep] = useState(1);
  const [value, setValue] = useState('');
  const [code, setCode] = useState('');
  const [typeValue, setTypeValue] = useState('');

  const sendPasswordHandler = result => {
    setMessage(result);
    if (result === true) {
      setStep(step + 1);
    }
  };

  const setUsersValueHandler = (value, type) => {
    setValue(value);
    setTypeValue(type);
  };

  const codeResetHandler = code => {
    setCode(code);
  };

  return (
    <Fragment>
      {message === 'error' ? (
        <Alert severity="warning">
          No se encontró una cuenta asociada
        </Alert>
      ) : message === 'notFoundCode' ? (
        <Alert severity="warning">Código invalido</Alert>
      ) : (
        ''
      )}
      {step === 1 ? (
        <FirstStep
          setUsersValue={setUsersValueHandler}
          sendPassword={sendPasswordHandler}
        />
      ) : step === 2 ? (
        <SecondStep
          value={value}
          typeValue={typeValue}
          sendPassword={sendPasswordHandler}
          codeReset={codeResetHandler}
        />
      ) : (
        <ThreeStep code={code} typeValue={typeValue} value={value} />
      )}
      {/* {message === true && (
        <SuccessMessage value={value} typeValue={typeValue} />
      )} */}
    </Fragment>
  );
};

export default RecoverAccount;

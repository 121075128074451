import { Fragment, useState } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';

import { createUser } from '../../store/createUser-actions';
import { loginUser } from '../../store/login-actions';
import {
  getUserCredentialsUploadImageS3,
  getUserData
} from '../../store/user-actions';
import { uploadMultimediaTribut } from '../../store/uploadFileS3-actions';

import PhoneInputForm from 'components/UI/PhoneInputForm/PhoneInputForm';
import DropZoneComponent from '../UI/DropZoneComponent/DropZoneComponent';
import DialogMessage from '../UI/DialogMessage/DialogMessage';
import UserInput from '../UI/UserInput/UserInput';
import UserButton from '../UI/UserButton/UserButton';
import Loading from 'components/UI/Loading/Loading';

import classes from './CreateUser.module.css';

const CreateUserComponent = props => {
  const [load, setLoad] = useState(
    props.editData ? props.loadInfo : false
  );
  const [lada, setLada] = useState('');
  const [phone, setPhone] = useState('');
  const prevalueData = {
    userFirstName: props.predata ? props.predata.firstName : '',
    userLastName: props.predata ? props.predata.lastName : '',
    userEmail: props.predata ? props.predata.email : ''
  };
  const [userImage, setUserImage] = useState(
    props.predata
      ? props.predata.avatar
        ? props.predata.avatar
        : null
      : null
  );
  const [userFileExtension, setUserFileExtension] = useState(null);
  const [changePasswordView, setChangePasswordView] =
    useState(false);
  const [userContentType, setUserContentType] = useState(null);
  const [userFileName] = useState(Date.now());
  const [userFile, setUserFile] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const { register, handleSubmit, watch } = useForm({
    defaultValues: prevalueData
  });

  const handleDropUserImage = async data => {
    let fileNameExt = data[0].type;
    if (fileNameExt) {
      let fileNameSplit = fileNameExt.split('/');
      setUserFileExtension(fileNameSplit[1]);
    }
    setUserContentType(data[0].type);
    // setFileSize(data[0].size);
    setUserFile(data[0]);

    const reader = new FileReader();
    reader.onload = function (e) {
      setUserImage(e.target.result);
    };
    reader.readAsDataURL(data[0]);
  };

  const phoneHandler = (lada, phone) => {
    setLada(`+${lada}`);
    setPhone(phone);
  };

  const onSubmit = async data => {
    setLoad(true);
    const createNewUser = await createUser(
      data.userFirstName,
      data.userLastName,
      data.userEmail,
      data.userPassword,
      lada ? lada : null,
      phone ? phone : null,
      userFileExtension ? userFileName.toString() : null,
      userFileExtension ? userFileExtension : null
    );
    if (createNewUser) {
      const login = await loginUser(
        data.userEmail,
        data.userPassword
      );
      if (login) {
        let userData = await getUserData();
        if (userData.user.id && userFile) {
          let userPhotoCredentials =
            await getUserCredentialsUploadImageS3(
              userData.user.id,
              userContentType,
              userFileName.toString(),
              userFileExtension
            );
          if (userPhotoCredentials) {
            await uploadMultimediaTribut(
              userPhotoCredentials,
              userFile
            );
          }
        }
      }
      if (login && !props.noReload) {
        window.location.href = '/';
      } else if (login && props.noReload) {
        props.action();
      }
    } else {
      setShowDialog(true);
      setLoad(false);
    }
  };

  const closeDialogHandler = () => {
    setShowDialog(false);
  };

  const changePasswordViewHandler = () => {
    setChangePasswordView(true);
  };

  const userDataNew = watch();

  return (
    <div className={classes.register}>
      <form
        onSubmit={handleSubmit(
          props.editData ? props.editOnSubmit : onSubmit
        )}
      >
        {props.editData && (
          <>
            <p className={classes.passwordTitle}>
              Foto del administrador
            </p>
            <aside className={classes.loadImage}>
              <DropZoneComponent
                onDrop={
                  props.createInTribut
                    ? props.dropUserImage
                    : props.editData
                    ? props.handleDropEditUserImage
                    : handleDropUserImage
                }
                image={
                  props.createInTribut || props.editData
                    ? props.image
                    : userImage
                }
                acceptFiles=".jpg, .png, .jpeg, .heic"
                multipleFiles="false"
                maxSizeFile={5000000000000000}
                type="image"
                createTribut
              />
              <p className={classes.uploadImageText}>
                {props.editData
                  ? 'Cambia tu imagen de perfil'
                  : 'Opcionalmente, sube una imagen para tu perfil'}
              </p>
              <p className={classes.uploadImageSubText}>
                Tamaño recomendado 200 x 200px
              </p>
            </aside>
          </>
        )}
        <UserInput
          inputText="Nombre (s) *"
          type="text"
          size="small"
          name="userFirstName"
          status={
            props.createInTribut
              ? props.registerStatus('userFirstName', {
                  required: true
                })
              : { ...register('userFirstName', { required: true }) }
          }
        />
        <UserInput
          inputText="Apellido (s) *"
          type="text"
          size="small"
          name="userLastName"
          status={
            props.createInTribut
              ? props.registerStatus('userLastName', {
                  required: true
                })
              : { ...register('userLastName', { required: true }) }
          }
        />
        <UserInput
          inputText="Email *"
          type="email"
          size="small"
          name="userEmail"
          status={
            props.createInTribut
              ? props.registerStatus('userEmail', { required: true })
              : { ...register('userEmail', { required: true }) }
          }
        />
        <aside className={classes.phoneContainer}>
          <p className={classes.phoneText}>Teléfono Celular *</p>
          <PhoneInputForm
            prevalue={
              props.predata
                ? props.predata.lada
                  ? `${props.predata.lada}${props.predata.cellphone}`
                  : ''
                : ''
            }
            phoneHandler={
              props.createInTribut
                ? props.phoneCreateTributHandler
                : props.editData
                ? props.phoneEditHandler
                : phoneHandler
            }
          />
        </aside>
        {props.editData && (
          <Button
            onClick={changePasswordViewHandler}
            className={classes.changePasswordVewBtn}
          >
            CAMBIAR CONTRASEÑA
          </Button>
        )}
        <p className={classes.passwordTitle}>Mi Contraseña</p>
        {/* {props.editData && (
          <Fragment>
            <p className={classes.changepasswordText}>
              Utiliza esta sección para actualizar tu contraseña
            </p>
          </Fragment>
        )} */}
        {!props.editData ? (
          <Fragment>
            <UserInput
              inputText="Contraseña *"
              type="password"
              size="small"
              name="userPassword"
              status={
                props.createInTribut
                  ? props.registerStatus('userPassword', {
                      required: true
                    })
                  : {
                      ...register('userPassword', {
                        required: props.editData ? false : true
                      })
                    }
              }
            />
            <UserInput
              inputText="Confirmar *"
              type="password"
              size="small"
              name="userConfirmPassword"
              status={
                props.createInTribut
                  ? props.registerStatus('userConfirmPassword', {
                      required: true
                    })
                  : {
                      ...register('userConfirmPassword', {
                        required: props.editData ? false : true
                      })
                    }
              }
            />
          </Fragment>
        ) : (
          <Fragment>
            {changePasswordView && (
              <Fragment>
                <UserInput
                  inputText="Nueva Contraseña"
                  type="password"
                  size="small"
                  name="userNewPassword"
                  status={{
                    ...register('userNewPassword', {
                      required: false
                    })
                  }}
                />
                <UserInput
                  inputText="Confirmar Contraseña"
                  type="password"
                  size="small"
                  name="userConfirmNewPassword"
                  status={{
                    ...register('userConfirmNewPassword', {
                      required: false
                    })
                  }}
                />
              </Fragment>
            )}
          </Fragment>
        )}
        {!props.editData && (
          <>
            <p className={classes.passwordTitle}>
              Foto del administrador
            </p>
            <aside className={classes.loadImage}>
              <DropZoneComponent
                onDrop={
                  props.createInTribut
                    ? props.dropUserImage
                    : props.editData
                    ? props.handleDropEditUserImage
                    : handleDropUserImage
                }
                image={
                  props.createInTribut || props.editData
                    ? props.image
                    : userImage
                }
                acceptFiles=".jpg, .png, .jpeg, .heic"
                multipleFiles="false"
                maxSizeFile={5000000000000000}
                type="image"
                createTribut
              />
              <p className={classes.uploadImageText}>
                {props.editData
                  ? 'Cambia tu imagen de perfil'
                  : 'Opcionalmente, sube una imagen para tu perfil'}
              </p>
              <p className={classes.uploadImageSubText}>
                Tamaño recomendado 200 x 200px
              </p>
            </aside>
          </>
        )}
        {!props.createInTribut &&
          (!load ? (
            <UserButton
              disabled={
                props.editData
                  ? false
                  : !userDataNew.userFirstName ||
                    !userDataNew.userLastName ||
                    !userDataNew.userEmail ||
                    !userDataNew.userPassword ||
                    !userDataNew.userConfirmPassword ||
                    userDataNew.userPassword !==
                      userDataNew.userConfirmPassword
              }
              type="submit"
              text={props.editData ? 'Guardar' : 'Crear cuenta'}
            />
          ) : (
            <aside className={classes.loadContainer}>
              <Loading />
            </aside>
          ))}
        <DialogMessage
          closeDialog={closeDialogHandler}
          open={showDialog}
          message="Ya existe un usuario con esa cuenta"
        />
      </form>
    </div>
  );
};

CreateUserComponent.propTypes = {
  // eslint-disable-next-line react/require-default-props
  noReload: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  createInTribut: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  registerStatus: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  action: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  dropUserImage: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  image: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  editData: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  handleDropUserImage: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  editOnSubmit: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  predata: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  phoneCreateTributHandler: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  phoneEditHandler: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  createMemoriam: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  handleDropEditUserImage: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  loadInfo: PropTypes.func
};

export default CreateUserComponent;

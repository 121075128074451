import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { findPhoneNumbersInText } from 'libphonenumber-js';
import {
  formatPhoneNumberIntl,
  isValidPhoneNumber
} from 'react-phone-number-input';

import classes from './PhoneInputForm.module.css';

const PhoneInputForm = props => {
  const [phone, setPhone] = useState(
    props.prevalue ? `${props.prevalue}` : ''
  );

  useEffect(() => {
    let validNumber = false;
    if (phone) {
      const phoneFormat = findPhoneNumbersInText(phone);
      if (phoneFormat.length > 0) {
        props.phoneHandler(
          phoneFormat[0].number.countryCallingCode,
          phoneFormat[0].number.nationalNumber
        );
      }
      validNumber = isValidPhoneNumber(phone);
    }
    // eslint-disable-next-line no-console
    console.log('validNumber', validNumber);
  }, [phone]);

  return (
    <div className={classes.textFieldPhone}>
      <PhoneInput
        className={classes.textField}
        international
        defaultCountry="MX"
        placeholder="Enter phone number"
        value={formatPhoneNumberIntl(phone)}
        onChange={setPhone}
      />
    </div>
  );
};

PhoneInputForm.propTypes = {
  // eslint-disable-next-line react/require-default-props
  phoneHandler: PropTypes.function,
  // eslint-disable-next-line react/require-default-props
  prevalue: PropTypes.string
};

export default PhoneInputForm;

import { configureStore } from '@reduxjs/toolkit';

import condolenceSlice from './condolence-slice';
import userSlice from './user-slice';
import MemoriesDataSlice from './memory-slice';

const store = configureStore({
  reducer: {
    condolences: condolenceSlice.reducer,
    userData: userSlice.reducer,
    memoriesData: MemoriesDataSlice.reducer
  }
});

export default store;

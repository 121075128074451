import axios from 'axios';

export const uploadMultimediaTribut = async (credentials, file) => {
  let formData = new FormData();
  formData.append('acl', credentials.acl);
  formData.append('Content-Type', credentials.contentType);
  formData.append('key', credentials.key);
  formData.append('policy', credentials.policy);
  formData.append('X-Amz-Algorithm', credentials.xAmzAlgorithm);
  formData.append('X-Amz-Credential', credentials.xAmzCredential);
  formData.append('X-Amz-Date', credentials.xAmzDate);
  formData.append('x-amz-signature', credentials.xAmzSignature);
  formData.append('file', file);

  let options = {
    headers: {
      'Content-Type': credentials.contentType,
      Accept: '*/*'
    }
  };
  const request = await axios.post(
    'https://memoriam-multimedia.s3.us-east-1.amazonaws.com/',
    formData,
    options
  );

  if (
    request &&
    (request.status === 200 || request.status === 204)
  ) {
    return true;
  }
  return false;
};

import PropTypes from 'prop-types';

import DropZoneComponent from '../UI/DropZoneComponent/DropZoneComponent';
import UserInput from '../UI/UserInput/UserInput';
import Events from '../UI/Events/Events';

import classes from './TributStep.module.css';

const TributStepOne = props => {
  return (
    <div className={classes.formContainer}>
      <h3 className={classes.tributStepTitle}>
        Datos sobre tu ser querido
      </h3>
      <aside className={classes.loadImage}>
        <p className={classes.uploadImageTitle}>
          Fotografía del Tributo
        </p>
        <DropZoneComponent
          onDrop={props.onDrop}
          image={props.image}
          acceptFiles=".jpg, .png, .jpeg, .heic"
          multipleFiles="false"
          maxSizeFile={5000000000000000}
          type="image"
          createTribut
        />
        <p className={classes.uploadImageText}>
          Sube una imagen de tu ser querido
        </p>
        <p className={classes.uploadImageSubText}>
          Tamaño recomendado 200 x 200px
        </p>
      </aside>
      <UserInput
        inputText="Nombre(s) *"
        type="text"
        size="small"
        status={props.registerStatus('firstName')}
        name="firstName"
      />
      <UserInput
        inputText="Apellido(s) *"
        type="text"
        size="small"
        status={props.registerStatus('lastName')}
        name="lastName"
      />
      <div className={classes.specialContainer}>
        <UserInput
          inputText="Fecha y lugar de nacimiento *"
          type="date"
          size="small"
          dateHandler={props.birthdayHandler}
          value={props.birthday}
          name="birthday"
          row
        />
        <UserInput
          type="text"
          size="small"
          status={props.registerStatus('birthplace', {
            required: false
          })}
          name="birthplace"
          row
          second
          placeholder="Lugar de nacimiento"
        />
      </div>
      <div className={classes.specialContainer}>
        <UserInput
          inputText="Fecha y lugar de defunción *"
          type="date"
          size="small"
          dateHandler={props.dayofDeathHandler}
          value={props.dayofdeath}
          name="dayofdeath"
          row
        />
        <UserInput
          type="text"
          size="small"
          status={props.registerStatus('placeofdeath', {
            required: false
          })}
          name="placeofdeath"
          row
          second
          placeholder="Lugar de defunción"
        />
      </div>
      <Events
        ocurrencesHandler={props.ocurrencesHandler}
        ocurrencesData={props.ocurrencesData}
        ocurrenceStatusHandler={props.ocurrenceStatusHandler}
        birthday={props.birthday}
      />
    </div>
  );
};

TributStepOne.propTypes = {
  // eslint-disable-next-line react/require-default-props
  onDrop: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  image: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  dayofdeath: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  registerStatus: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  dayofDeathHandler: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  birthday: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  birthdayHandler: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  ocurrencesHandler: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  ocurrencesData: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  ocurrenceStatusHandler: PropTypes.func
};

export default TributStepOne;

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';

import ShowVideo from 'components/UI/ShowVideo/ShowVideo';
import CommentInput from 'components/UI/CommentInput/CommentInput';
import Comments from 'components/UI/Comments/Comments';

import EditCondolence from '../EditCondolence/EditCondolence';
import DialogMessage from 'components/UI/DialogMessage/DialogMessage';
import DialogNoUser from 'components/UI/DialogNoUser/DialogNoUser';

import {
  deleteCondolence,
  getCondolenceList
} from 'store/condolence-actions';
import { deleteComment, likeAction } from 'store/comment-actions';
import { isLogged } from 'store/user-actions';

import classes from './CommentsCondolence.module.css';

const CommentCondolence = props => {
  const loged = isLogged();
  const month = monthNumber => {
    const monthText = [
      'Ene',
      'Feb',
      'Mar',
      'Abr',
      'May',
      'Jun',
      'Jul',
      'Ago',
      'Sep',
      'Oct',
      'Nov',
      'Dic'
    ];
    return monthText[monthNumber];
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const openMenu = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [edit, setEdit] = useState(false);
  const [condolenceLikes, setCondolenceLikes] = useState(
    props.data.countLikes
  );
  const [modalNotUser, setModalNotUser] = useState(false);
  const [condolenceLikeOwner, setCondolenceLikeOwner] = useState(
    props.data.isOwnerLike
  );
  const [showResponses, setShowResponses] = useState(false);
  const [comments, setComments] = useState(props.data.comments);
  const [openDialog, setOpenDialog] = useState(false);
  const [message] = useState(
    'La Condolencia se borrará de manera permanente junto con todos sus comentarios, imágenes y videos relacionados.  Esta acción no se puede deshacer.'
  );
  const dispatch = useDispatch();

  const showResponsesHandler = () => {
    setShowResponses(!showResponses);
  };

  // const loadNewCommentHandler = () => {
  //   dispatch(getCondolenceList(props.tributId));
  // };

  const loadNewCommentHandler = (
    avatar,
    userName,
    userLastName,
    comment,
    condolenceCommentId
  ) => {
    const start = Date.now();
    const newComment = {
      avatar: avatar ? avatar : null,
      comment: comment,
      firstName: userName,
      lastName: userLastName,
      timePublished: start / 1000,
      condolenceCommentId,
      isOwnerComment: true
    };
    setComments([...comments, newComment]);
  };

  const deleteCondolenceHandler = () => {
    (async () => {
      let deleteCondolenceData = await deleteCondolence(
        props.data.condolenceId
      );
      if (deleteCondolenceData) {
        setOpenDialog(false);
        dispatch(getCondolenceList(props.tributId));
        setOpenDialog(false);
      }
    })();
  };

  const deleteCommentHandler = id => {
    (async () => {
      let deleteCommentData = await deleteComment(id, 'CONDOLENCE');
      if (deleteCommentData) {
        setComments(comments => {
          return comments.filter(
            comment => comment.condolenceCommentId !== id
          );
        });
      }
    })();
  };

  const deleteCondolenceModal = () => {
    setOpenDialog(true);
    setAnchorEl(null);
  };

  const closeDialogHandler = () => {
    setOpenDialog(false);
  };

  const editCondolenceHandler = () => {
    setEdit(!edit);
    setAnchorEl(null);
  };

  const modalNotUserHandler = () => {
    setModalNotUser(true);
  };

  const closeDialogHander = () => {
    setModalNotUser(false);
  };

  const likeHandler = () => {
    if (condolenceLikeOwner) {
      setCondolenceLikes(condolenceLikes - 1);
      setCondolenceLikeOwner(false);
    } else {
      setCondolenceLikes(condolenceLikes + 1);
      setCondolenceLikeOwner(true);
    }
    setModalNotUser(false);
    (async () => {
      await likeAction(props.data.condolenceId, 'CONDOLENCE');
    })();
  };

  return (
    <>
      {!edit ? (
        <>
          <div
            key={props.data.condolenceId}
            className={classes.commentCondolenceContainer}
          >
            {props.data.avatar ? (
              <figure className={classes.commentAvatar}>
                <img
                  className={classes.commentAvatarImg}
                  alt={props.data.type}
                  id="myMemoryAvatar"
                  src={props.data.avatar}
                />
              </figure>
            ) : (
              <div className={classes.noUserContainer}>
                <PersonOutlinedIcon className={classes.noUserIcon} />
              </div>
            )}
            <p className={classes.commentName}>
              <strong>{props.data.firstName}</strong>
            </p>
            {(props.data.isOwnerCondolene ||
              props.data.isOwnerTribut) && (
              <>
                <Button
                  className={classes.deleteCondolenceBtn}
                  onClick={openMenu}
                >
                  <MoreHorizIcon className={classes.icon} />
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  {props.data.isOwnerCondolene && (
                    <MenuItem onClick={editCondolenceHandler}>
                      <EditIcon className={classes.icon} />
                      Editar
                    </MenuItem>
                  )}
                  <MenuItem onClick={deleteCondolenceModal}>
                    <DeleteIcon className={classes.icon} />
                    Eliminar
                  </MenuItem>
                </Menu>
              </>
            )}
            <p className={classes.commentPublished}>
              {new Date(props.data.timePublished * 1000).getDate()}
              {'/'}
              {month(
                new Date(props.data.timePublished * 1000).getMonth()
              )}
              {'/'}
              {new Date(
                props.data.timePublished * 1000
              ).getFullYear()}
            </p>
            <p className={classes.commentText}>
              {props.data != null ? props.data.text : null}
            </p>
            {props.data.mediaType === null ? (
              ''
            ) : props.data.mediaType === 'png' ||
              props.data.mediaType === 'jpg' ||
              props.data.mediaType === 'webp' ||
              props.data.mediaType === 'gif' ||
              props.data.mediaType === 'jpeg' ? (
              <figure className={classes.commentImage}>
                <img
                  className={classes.commentImg}
                  alt={props.data.text}
                  src={props.data.multimedia}
                />
              </figure>
            ) : (
              <ShowVideo srcVideo={props.data.multimedia} />
            )}
            <div className={classes.complementaryInfo}>
              <p className={classes.likesText}>
                <ThumbUpOutlinedIcon className={classes.likeIcon} />{' '}
                <span className={classes.likesNumber}>
                  {condolenceLikes}
                </span>
              </p>
              <Button
                className={classes.commentsText}
                onClick={showResponsesHandler}
              >
                {comments.length} Comentarios
              </Button>
            </div>
            <div className={classes.actionsContainer}>
              <Button
                className={classes.actionBtn}
                onClick={loged ? likeHandler : modalNotUserHandler}
              >
                <ThumbUpOutlinedIcon
                  className={classes.actionIcon}
                />{' '}
                <span className={classes.actionText}>Me Gusta</span>
              </Button>
              <Button
                className={classes.actionBtn}
                onClick={showResponsesHandler}
              >
                <ForumOutlinedIcon className={classes.actionIcon} />{' '}
                <span className={classes.actionText}>Comentar</span>
              </Button>
            </div>

            {showResponses && (
              <CommentInput
                tributId={props.tributId}
                object={props.data}
                loadNewComment={loadNewCommentHandler}
              />
            )}

            {showResponses &&
              comments &&
              comments.map(comment => (
                <Comments
                  comment={comment}
                  key={comment.condolenceCommentId}
                  deleteComment={deleteCommentHandler}
                  tributOwner={comment.isOwnerTribut}
                  commentOwner={comment.isOwnerComment}
                />
              ))}
          </div>
          <DialogMessage
            closeDialog={closeDialogHandler}
            action={deleteCondolenceHandler}
            open={openDialog}
            message={message}
            title="Eliminar Condolencia"
            delete
          />
        </>
      ) : (
        <EditCondolence
          data={props.data}
          editCondolence={editCondolenceHandler}
          tributId={props.tributId}
          callCondolences={props.callCondolences}
        />
      )}
      {!loged && modalNotUser && (
        <DialogNoUser
          open={modalNotUser}
          closeDialog={closeDialogHander}
          action={likeHandler}
        />
      )}
    </>
  );
};

CommentCondolence.propTypes = {
  // eslint-disable-next-line react/require-default-props
  data: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  tributId: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  callCondolences: PropTypes.number
};

export default CommentCondolence;

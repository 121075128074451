import { Fragment } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';

import Loading from 'components/UI/Loading/Loading';
import Login from '../login/Login';
import CreateUser from '../createUser/CreateUser';

import classes from './TributStep.module.css';

const TributStepThree = props => {
  const login = () => {
    props.loginHandler();
  };

  const createUser = () => {
    props.createUserHandler();
  };

  return (
    <div>
      {props.tributImage ? (
        <figure className={classes.tributAvatar}>
          <img
            className={classes.img}
            src={props.tributImage}
            alt={props.tributName}
          />
        </figure>
      ) : (
        <div className={classes.noUserContainer}>
          <PersonOutlinedIcon className={classes.noUserIcon} />
        </div>
      )}
      <p className={classes.tributStepSubitle}>
        El Tributo a <strong>{props.tributName}</strong> está listo
        para publicarse.
      </p>
      <aside className={classes.loginContainer}>
        {!props.loadCreateInTribut ? (
          <Button
            onClick={props.finishTribut}
            className={classes.loginBtn}
            disabled={!props.isLogin}
          >
            Publicar Tributo
          </Button>
        ) : (
          <Loading />
        )}
      </aside>
      {!props.isLogin && (
        <Fragment>
          <p className={classes.tributStepSubitle}>
            Para publicar tu tributo es necesario ingresar a tu
            cuenta Tribut o crear una cuenta nueva:
          </p>

          {props.registerFields ? (
            <Fragment>
              <h3 className={classes.title}>Crear nueva cuenta</h3>
              <CreateUser
                dropUserImage={props.dropUserImage}
                image={props.image}
                createInTribut
                registerStatus={props.registerStatus}
                phoneCreateTributHandler={
                  props.phoneCreateTributHandler
                }
              />
              <aside className={classes.loginContainer}>
                <Button
                  onClick={createUser}
                  className={classes.loginBtn}
                >
                  Crear cuenta
                </Button>
              </aside>
            </Fragment>
          ) : (
            <Fragment>
              <h3 className={classes.title}>Ingresar a Tribut</h3>
              <Login
                createInTribut
                userData={props.userData}
                registerStatus={props.registerStatus}
              />
              <aside className={classes.loginContainer}>
                <Button onClick={login} className={classes.loginBtn}>
                  Iniciar sesión
                </Button>
              </aside>
              <aside className={classes.createAccountContainerBtn}>
                <Button
                  className={classes.createAccountBtn}
                  onClick={props.showLoginFields}
                >
                  Crear nueva cuenta
                </Button>
                <p className={classes.infoText}>
                  Crea páginas de Tributo en honor a tus seres
                  queridos.
                </p>
              </aside>
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  );
};

TributStepThree.propTypes = {
  // eslint-disable-next-line react/require-default-props
  registerFields: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  showLoginFields: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  registerStatus: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  userData: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  dropUserImage: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  image: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  phoneCreateTributHandler: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  loginHandler: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  isLogin: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  createUserHandler: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  tributName: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  finishTribut: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  tributImage: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  loadCreateInTribut: PropTypes.bool
};

export default TributStepThree;

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined'; // ADD VIDEO condolence
import IconButton from '@mui/material/IconButton';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import MovieOutlinedIcon from '@mui/icons-material/MovieOutlined';

import DialogNoUser from 'components/UI/DialogNoUser/DialogNoUser';
import DropZoneComponent from 'components/UI/DropZoneComponent/DropZoneComponent';

import { getUserData, isLogged } from 'store/user-actions';

import classes from './SelectTypeFile.module.css';

const SelectTypeFile = props => {
  const loged = isLogged();
  const [modalNotUser, setModalNotUser] = useState(false);
  const [imgImageUpload, setImgImageUpload] = useState(
    props.edit
      ? props.mediaData.multimedia
      : 'https://d3cohyhv4rpctw.cloudfront.net/icon_imagen.png'
  );
  const [imgVideoUpload, setImgVideoUpload] = useState(
    props.edit
      ? props.mediaData.multimedia
      : 'https://d3cohyhv4rpctw.cloudfront.net/icon_video.png'
  );
  const [file, setFile] = useState(
    props.edit && props.mediaData.multimedia
  );
  const [contentType, setContentType] = useState(null);
  const [fileExtension, setFileExtension] = useState(
    props.edit && props.mediaData.mediaType
  );
  const [fileData, setFileData] = useState(
    props.edit ? props.viewMedia : null
  );

  useEffect(() => {
    setFileDataHandler(fileData);
  }, [imgImageUpload, imgVideoUpload]);

  const handleDropImage = async data => {
    let fileNameExt = data[0].type;
    if (fileNameExt) {
      let fileNameSplit = fileNameExt.split('/');
      setFileExtension(fileNameSplit[1]);
    }
    setContentType(data[0].type);
    setFile(data[0]);

    const reader = new FileReader();
    reader.onload = function (e) {
      setImgImageUpload(e.target.result);
    };
    reader.readAsDataURL(data[0]);
    props.selectTypeFileHandler('IMAGE');
    setFileData('IMAGE');
  };

  const dropVideoHandler = async data => {
    let fileNameExt = data[0].type;
    if (fileNameExt) {
      let fileNameSplit = fileNameExt.split('/');
      setFileExtension(fileNameSplit[1]);
    }
    setContentType(data[0].type);
    setFile(data[0]);

    const reader = new FileReader();
    reader.onload = function (e) {
      setImgVideoUpload(e.target.result);
    };
    reader.readAsDataURL(data[0]);
    props.selectTypeFileHandler('VIDEO');
    setFileData('VIDEO');
  };

  const setFileDataHandler = type => {
    if (type === 'IMAGE') {
      props.setData(
        type,
        fileExtension,
        contentType,
        file,
        imgImageUpload
      );
    } else {
      props.setData(
        type,
        fileExtension,
        contentType,
        file,
        imgVideoUpload
      );
    }
  };

  const closeDialog = () => {
    setModalNotUser(false);
  };

  const modalNotUserHandler = () => {
    setModalNotUser(true);
  };

  const loginUser = () => {
    (async () => {
      let userInfo = await getUserData();
      if (userInfo) {
        setModalNotUser(false);
      }
    })();
  };

  return (
    <div
      className={
        props.changePosition
          ? classes.iconsContainerBottom
          : classes.iconsContainer
      }
    >
      {loged ? (
        <>
          <div className={classes.iconContainer}>
            <DropZoneComponent
              onDrop={handleDropImage}
              image={imgImageUpload}
              acceptFiles="image/*, .heic"
              multipleFiles="false"
              maxSizeFile={10000000}
              type="image"
              condolenceImage
            />
          </div>
          <div className={classes.iconContainer}>
            <DropZoneComponent
              onDrop={dropVideoHandler}
              image={imgVideoUpload}
              acceptFiles="video/*"
              multipleFiles="false"
              maxSizeFile={5000000000000000}
              type="video"
              extension={fileExtension}
              condolenceVideo
            />
          </div>
        </>
      ) : (
        <>
          <div className={classes.iconContainer}>
            <IconButton
              onClick={modalNotUserHandler}
              className={classes.iconBtn}
            >
              <ImageOutlinedIcon className={classes.icon} />
            </IconButton>
          </div>
          <div className={classes.iconContainer}>
            <IconButton
              onClick={modalNotUserHandler}
              className={classes.iconBtn}
            >
              <MovieOutlinedIcon className={classes.icon} />
            </IconButton>
          </div>
        </>
      )}

      <div className={classes.iconContainer}>
        <IconButton
          onClick={
            loged
              ? props.selectTypeFileHandler('RECORD')
              : modalNotUserHandler
          }
          className={classes.iconBtn}
        >
          <VideocamOutlinedIcon className={classes.icon} />
        </IconButton>
      </div>
      {!loged && modalNotUser && (
        <DialogNoUser
          open={modalNotUser}
          closeDialog={closeDialog}
          action={loginUser}
        />
      )}
    </div>
  );
};

SelectTypeFile.propTypes = {
  // eslint-disable-next-line react/require-default-props
  selectTypeFileHandler: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  changePosition: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  setData: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  edit: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  viewMedia: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  mediaData: PropTypes.object
};

export default SelectTypeFile;

import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import classes from './TimeLine.module.css';

const TimeLine = props => {
  const month = monthNumber => {
    const monthText = [
      'Ene',
      'Feb',
      'Mar',
      'Abr',
      'May',
      'Jun',
      'Jul',
      'Ago',
      'Sep',
      'Oct',
      'Nov',
      'Dic'
    ];
    return monthText[monthNumber];
  };
  const deleteEventHandler = id => () => {
    props.deleteEvent(id);
  };

  return (
    <div className={classes.bioSpecificDataContainer}>
      {props.eventsData.length > 0 &&
        props.eventsData.map(ocurrence => (
          <div
            className={classes.ocurrenceContainer}
            key={ocurrence.ocurrenceId}
          >
            <div className={classes.ocurrenceYearsContainer}>
              <p className={classes.yearText}>
                {new Date(ocurrence.date).getFullYear()}
              </p>
              {props.birthday && (
                <p className={classes.ageText}>
                  {Math.floor(
                    (new Date(ocurrence.date).getTime() -
                      new Date(props.birthday).getTime()) /
                      (1000 * 60 * 60 * 24) /
                      365
                  )}
                </p>
              )}
            </div>
            <div className={classes.ocurrenceSpecificDataContainer}>
              <p className={classes.ocurrenceTitle}>
                <span className={classes.infoText}>
                  {ocurrence.type === 'MARRIAGE'
                    ? 'Matrimonio con '
                    : ocurrence.type === 'BIRTH_SON'
                    ? 'Nacimiento de '
                    : ''}
                  {ocurrence.name}
                </span>
              </p>
              <Button
                onClick={deleteEventHandler(ocurrence.ocurrenceId)}
                className={classes.deleteBtn}
              >
                <DeleteOutlinedIcon />
              </Button>
              <p className={classes.ocurrenceText}>
                {new Date(ocurrence.date).getDate()}/
                {month(new Date(ocurrence.date).getMonth())}/
                {new Date(ocurrence.date).getFullYear()}{' '}
                {ocurrence.place}
              </p>
              <p className={classes.ocurrenceText}>
                {ocurrence.description}
              </p>
            </div>
          </div>
        ))}
    </div>
  );
};

TimeLine.propTypes = {
  // eslint-disable-next-line react/require-default-props
  eventsData: PropTypes.array,
  // eslint-disable-next-line react/require-default-props
  deleteEvent: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  edit: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  birthday: PropTypes.string
};

export default TimeLine;

import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactGA from 'react-ga4';

import IconButton from '@mui/material/IconButton';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import {
  getUserCredentialsUploadImageS3,
  getUserData,
  isLogged
} from '../../store/user-actions';
import { loginUser } from '../../store/login-actions';
import { createUser } from '../../store/createUser-actions';
import {
  createTribut,
  getCredentialsUploadImageS3
} from '../../store/tribut-actions';

import { uploadMultimediaTribut } from '../../store/uploadFileS3-actions';

import DialogMessage from '../../components/UI/DialogMessage/DialogMessage';
import UserButton from '../../components/UI/UserButton/UserButton';
import TributStepOne from '../../components/tributCreation/TributStepOne';
import TributStepTwo from '../../components/tributCreation/TributStepTwo';
import TributStepTrhee from '../../components/tributCreation/TributStepTrhee';
import TributStepFour from '../../components/tributCreation/TributStepFour';

import classes from './CreateTribut.module.css';
let createTributeState = false;

const CreateTribut = () => {
  const [message, setMessage] = useState('');
  const [tributIdSuccess, setTributIdSuccess] = useState('');
  const [lada, setLada] = useState('');
  const [phone, setPhone] = useState('');
  const [contentType, setContentType] = useState(null);
  const [userContentType, setUserContentType] = useState(null);
  const [fileName] = useState(Date.now());
  const [userFileName] = useState(Date.now());
  const [fileExtension, setFileExtension] = useState(null);
  const [userFileExtension, setUserFileExtension] = useState(null);
  const [userLogin, setUserLogin] = useState(isLogged());
  const [ocurrencesData, setOcurrencesData] = useState([]);
  const [ocurrenceStatus, setOcurrenceStatus] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const [step, setStep] = useState(1);
  const [loadCreateInTribut, setLoadCreateInTribut] =
    useState(false);
  const { register, handleSubmit, watch } = useForm();
  const [showRegisterFields, setShowRegisterFields] =
    useState(false);
  const [saveTribut, setSaveTribut] = useState(false);
  const [dayofdeath, setDayofdeath] = useState(null);
  const [note, setNote] = useState(null);
  const [birthday, setBirthday] = useState(null);
  const [image, setImage] = useState(null);
  const [userImage, setUserImage] = useState(null);
  const [file, setFile] = useState(null);
  const [userFile, setUserFile] = useState(null);

  useEffect(() => {
    ReactGA.initialize('G-N3MP6T91TP');
    ReactGA.pageview('/tribut/create');
  }, []);

  const changeStepHandler = newStep => () => {
    window.scroll({
      top: 0
    });
    let userStep = 0;
    if (step === 2 && newStep === 1 && isLogged()) {
      userStep = step + 1;
    } else if (step === 4 && newStep === -1 && isLogged()) {
      userStep = step - 1;
    } else {
      userStep = step + newStep;
    }
    if (step === 3 && !showRegisterFields) {
      loginUser(userData.email, userData.password).then(response => {
        if (response) {
          setStep(userStep);
        } else {
          setShowDialog(true);
          setMessage('Tu usuario y contraseña no coinciden');
        }
      });
    } else {
      setStep(userStep);
    }
  };

  const ocurrenceStatusHandler = () => {
    setOcurrenceStatus(!ocurrenceStatus);
  };

  const loginHandler = () => {
    loginUser(userData.email, userData.password).then(response => {
      if (response) {
        setUserLogin(true);
      } else {
        setShowDialog(true);
        setMessage('Tu usuario y contraseña no coinciden');
      }
    });
  };

  const createUserHandler = () => {
    // (async () => {
    //   let prices = await getPricePayment();
    //   setPricesData(prices);
    // })();
    createUser(
      userData.userFirstName,
      userData.userLastName,
      userData.userEmail,
      userData.userPassword,
      lada,
      phone,
      userFileExtension ? userFileName.toString() : null,
      userFileExtension ? userFileExtension : null
    ).then(response => {
      if (response) {
        loginUser(userData.userEmail, userData.userPassword).then(
          responseLogin => {
            if (responseLogin) {
              setUserLogin(true);
              getUserData().then(responseData => {
                if (responseData.user.id && userFile) {
                  getUserCredentialsUploadImageS3(
                    responseData.user.id,
                    userContentType,
                    userFileName.toString(),
                    userFileExtension
                  ).then(responseCredentials => {
                    if (responseCredentials.key) {
                      uploadMultimediaTribut(
                        responseCredentials,
                        userFile
                      );
                    }
                  });
                }
              });
            }
          }
        );
      }
    });
  };

  const phoneHandler = (lada, phone) => {
    setLada(`+${lada}`);
    setPhone(phone);
  };

  const birthdayHandler = value => {
    setBirthday(value);
  };

  const noteHandler = value => {
    setNote(value);
  };

  const dayofDeathHandler = value => {
    setDayofdeath(value);
  };

  const showLoginFieldsHandler = () => {
    setShowRegisterFields(!showRegisterFields);
  };

  const closeDialogHandler = () => {
    setShowDialog(false);
  };

  const handleDrop = async data => {
    let fileNameExt = data[0].type;
    if (fileNameExt) {
      let fileNameSplit = fileNameExt.split('/');
      setFileExtension(fileNameSplit[1]);
    }
    setContentType(data[0].type);
    setFile(data[0]);

    const reader = new FileReader();
    reader.onload = function (e) {
      setImage(e.target.result);
    };
    reader.readAsDataURL(data[0]);
  };

  const handleDropUserImage = async data => {
    let fileNameExt = data[0].type;
    if (fileNameExt) {
      let fileNameSplit = fileNameExt.split('/');
      setUserFileExtension(fileNameSplit[1]);
    }
    setUserContentType(data[0].type);
    // setFileSize(data[0].size);
    setUserFile(data[0]);

    const reader = new FileReader();
    reader.onload = function (e) {
      setUserImage(e.target.result);
    };
    reader.readAsDataURL(data[0]);
  };

  const finishTribut = () => {
    setSaveTribut(true);
  };

  const ocurrencesHandler = ocurrences => {
    setOcurrencesData(ocurrences);
  };

  const onSubmit = async data => {
    if (saveTribut) {
      setLoadCreateInTribut(true);
      let tributId = await createTribut(
        data.firstName,
        data.lastName,
        birthday,
        data.birthplace,
        dayofdeath,
        data.placeofdeath,
        data.dedication,
        data.phrase,
        note,
        fileName.toString(),
        fileExtension,
        ocurrencesData
      );

      if (tributId) {
        if (file) {
          let credentials = await getCredentialsUploadImageS3(
            tributId,
            contentType,
            fileName.toString(),
            fileExtension
          );

          // Upload Image en S3
          let uploaded = await uploadMultimediaTribut(
            credentials,
            file
          );
          if (uploaded) {
            setShowDialog(true);
            setTributIdSuccess(tributId);
            setMessage('El Tributo fue publicado con éxito');
          }
        } else {
          setShowDialog(true);
          setTributIdSuccess(tributId);
          setMessage('El Tributo fue publicado con éxito');
        }
      } else {
        setShowDialog(true);
        setMessage(
          'Hubo un error, te sugerimos volver a intentarlo'
        );
      }
    }
  };

  const redirectTribut = () => {
    window.location.href = `/tribut/${tributIdSuccess}/memorias`;
  };

  const userData = watch();
  if (saveTribut) {
    if (!createTributeState) {
      onSubmit(userData);
    }
    createTributeState = true;
  }

  return (
    <>
      <div className={classes.tribut}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <h2 className={classes.tribut__title}>Crea un Tributo</h2>
          <aside className={classes.arrowStepContainer}>
            <IconButton
              className={classes.arrowStep}
              disabled={step === 1}
              onClick={changeStepHandler(-1)}
            >
              <ChevronLeftIcon className={classes.arrowStepIcon} />
            </IconButton>
            <h3 className={classes.tribut__step}>Paso {step} / 3</h3>
            <IconButton
              className={classes.arrowStep}
              disabled={
                step === 1
                  ? !userData.firstName || !userData.lastName
                  : step === 2
                  ? !userData.dedication
                  : step === 3
                  ? !userData.userFirstName ||
                    !userData.userLastName ||
                    !userData.userEmail ||
                    !userData.userPassword ||
                    !userData.userConfirmPassword
                  : step === 3 && !showRegisterFields
                  ? !userData.email || !userData.password
                  : step === 4
              }
              onClick={changeStepHandler(1)}
            >
              <ChevronRightIcon className={classes.arrowStepIcon} />
            </IconButton>
          </aside>
          <section>
            {step === 1 ? (
              <TributStepOne
                birthday={birthday}
                dayofdeath={dayofdeath}
                registerStatus={register}
                birthdayHandler={birthdayHandler}
                dayofDeathHandler={dayofDeathHandler}
                onDrop={handleDrop}
                image={image}
                ocurrencesHandler={ocurrencesHandler}
                ocurrencesData={ocurrencesData}
                ocurrenceStatusHandler={ocurrenceStatusHandler}
              />
            ) : step === 2 ? (
              <TributStepTwo
                noteHandler={noteHandler}
                registerStatus={register}
                dedicationText={userData.dedication}
                phraseText={userData.phrase}
              />
            ) : step === 3 ? (
              <TributStepTrhee
                registerStatus={register}
                isLogin={userLogin}
                registerFields={showRegisterFields}
                showLoginFields={showLoginFieldsHandler}
                userData={userData}
                dropUserImage={handleDropUserImage}
                image={userImage}
                phoneCreateTributHandler={phoneHandler}
                loginHandler={loginHandler}
                finishTribut={finishTribut}
                createUserHandler={createUserHandler}
                tributName={`${userData.firstName} ${userData.lastName}`}
                tributImage={image}
                loadCreateInTribut={loadCreateInTribut}
              />
            ) : (
              <TributStepFour />
            )}
            {step !== 3 ? (
              <UserButton
                disabled={
                  step === 1
                    ? !userData.firstName ||
                      !userData.lastName ||
                      !birthday ||
                      !userData.birthplace ||
                      !dayofdeath ||
                      !userData.placeofdeath ||
                      ocurrenceStatus
                    : step === 2
                    ? !userData.dedication
                    : true
                }
                text="Siguiente Paso"
                type="button"
                onClickHandler={changeStepHandler(1)}
              />
            ) : (
              ''
            )}
          </section>
        </form>
      </div>
      <DialogMessage
        closeDialog={
          tributIdSuccess ? redirectTribut : closeDialogHandler
        }
        open={showDialog}
        message={message}
      />
    </>
  );
};

export default CreateTribut;

import PropTypes from 'prop-types';

import classes from './UserButton.module.css';

const UserButton = props => {
  // const btnContainer = `{props.adminBtn ? {classes.userBtn} {classes.userBtnAdmin} : ''}`;
  return (
    <div
      className={
        props.double
          ? classes.userBtnContainerDouble
          : classes.userBtnContainer
      }
    >
      {props.type === 'submit' ? (
        <button
          disabled={props.disabled}
          onClick={!props.edit ? props.onClickHandler : ''}
          type={props.type}
          className={classes.userBtn}
        >
          {props.text}
        </button>
      ) : (
        <button
          disabled={props.disabled}
          onClick={props.onClickHandler}
          className={
            props.cancel ? classes.cancelBtn : classes.userBtn
          }
        >
          {props.text}
        </button>
      )}
    </div>
  );
};

UserButton.propTypes = {
  // eslint-disable-next-line react/require-default-props
  onClickHandler: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  text: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  type: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  double: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  edit: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  cancel: PropTypes.bool
};

export default UserButton;

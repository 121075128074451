import { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
// import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
// import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
// import MovieOutlinedIcon from '@mui/icons-material/MovieOutlined';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';

import DialogNoUser from 'components/UI/DialogNoUser/DialogNoUser';

import { isLogged } from 'store/user-actions';

import classes from './UploadFiles.module.css';

const UploadFiles = props => {
  const loged = isLogged();
  const [modalNotUser, setModalNotUser] = useState(false);

  const handleClickOpen = typeFile => () => {
    props.openUploadModal(typeFile);
  };

  const handleClickRecord = typeFile => () => {
    props.changeView(typeFile);
  };

  const modalNotUserHandler = () => {
    setModalNotUser(true);
  };

  const closeDialogHander = () => {
    setModalNotUser(false);
  };

  return (
    <aside className={classes.addMemoriesContainer}>
      {props.userData ? (
        props.userData.avatar ? (
          <figure className={classes.userImage}>
            <img
              className={classes.img}
              alt={
                props.userData
                  ? props.userData.firstName
                    ? props.userData.firstName
                    : ''
                  : 'UserName'
              }
              src={props.userData.avatar}
            />
          </figure>
        ) : (
          <div className={classes.noUserContainer}>
            <PersonOutlinedIcon className={classes.noUserIcon} />
          </div>
        )
      ) : (
        <div className={classes.noUserContainer}>
          <PersonOutlinedIcon className={classes.noUserIcon} />
        </div>
      )}
      <div className={classes.rightSection}>
        <div>
          <Button
            className={classes.openModalBtn}
            onClick={handleClickOpen('all')}
          >
            <figure className={classes.iconMedia}>
              <img
                className={classes.imgIcon}
                src="https://d1g6c01s9ssd7h.cloudfront.net/img/icon_Multimedia.png"
              />
            </figure>
            Multimedia
          </Button>
          {/* <Button
            className={classes.openModalBtn}
            onClick={handleClickOpen('video')}
          >
            <MovieOutlinedIcon className={classes.openModalIcon} />{' '}
            Video
          </Button> */}
          <Button
            className={classes.openModalBtn}
            onClick={
              loged
                ? handleClickRecord('record')
                : modalNotUserHandler
            }
          >
            <figure className={classes.iconMedia}>
              <img
                className={classes.imgIcon}
                src="https://d1g6c01s9ssd7h.cloudfront.net/img/icon_grabar.png"
              />
            </figure>
            Grabar
          </Button>
        </div>
      </div>
      {!loged && modalNotUser && (
        <DialogNoUser
          open={modalNotUser}
          closeDialog={closeDialogHander}
          action={handleClickRecord('record')}
        />
      )}
    </aside>
  );
};

UploadFiles.propTypes = {
  // eslint-disable-next-line react/require-default-props
  openUploadModal: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  userData: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  memoryIdHandler: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  changeView: PropTypes.func
};

export default UploadFiles;

import PropTypes from 'prop-types';
import classes from './ChargesTribut.module.css';

const ChargesTribut = props => {
  const month = monthNumber => {
    const monthText = [
      'Ene',
      'Feb',
      'Mar',
      'Abr',
      'May',
      'Jun',
      'Jul',
      'Ago',
      'Sep',
      'Oct',
      'Nov',
      'Dic'
    ];
    return monthText[monthNumber];
  };

  return (
    <aside className={classes.tributsContainer}>
      {props.lstTribut.map(tribut => (
        <div
          className={classes.tributContainer}
          key={tribut.idTribut}
        >
          <p className={classes.item}>
            <span className={classes.itemConcept}>Concepto:</span>{' '}
            <span className={classes.itemDescription}>
              {tribut.tributName}
            </span>
          </p>
          <p className={classes.item}>
            <span className={classes.itemConcept}>Periodo:</span>{' '}
            <span className={classes.itemDescription}>
              {tribut.tributPeriod}
            </span>
          </p>
          <p className={classes.item}>
            <span className={classes.itemConcept}>Precio:</span>{' '}
            <span className={classes.itemDescription}>
              {tribut.tributPrice} {tribut.tributCurrency}
            </span>
          </p>
          <p className={classes.item}>
            <span className={classes.itemConcept}>
              Proximo Cargo:
            </span>{' '}
            <span className={classes.itemDescription}>{`${new Date(
              tribut.nextPayment
            ).getDate()}/${month(
              new Date(tribut.nextPayment).getMonth()
            )}/${new Date(tribut.nextPayment).getFullYear()}`}</span>
          </p>
          <p className={classes.item}>
            <span className={classes.itemConcept}>Status:</span>{' '}
            <span className={classes.itemDescription}>
              {tribut.tributStatus}
            </span>
          </p>
        </div>
      ))}
    </aside>
  );
};

ChargesTribut.propTypes = {
  // eslint-disable-next-line react/require-default-props
  lstTribut: PropTypes.bool
};

export default ChargesTribut;

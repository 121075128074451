import { useEffect } from 'react';
import ReactGA from 'react-ga4';

import DoneIcon from '@mui/icons-material/Done';

import BannerSection from '../../components/UI/BannerSection/BannerSection';

import classes from './AdminPlan.module.css';

const AdminPlan = () => {
  useEffect(() => {
    ReactGA.initialize('G-N3MP6T91TP');
    ReactGA.pageview('/admin/plans');
  }, []);
  return (
    <>
      <BannerSection />
      <section className={classes.adminPlanContainer}>
        <h3 className={classes.title}>Planes de Pago</h3>
        <div className={classes.planContainer}>
          <aside className={classes.infoContainer}>
            <h4 className={classes.subtitle}>
              Plan Gratuito Permanente
            </h4>
            <div className={classes.subsection}>
              {/* <p className={classes.text}>
                Incluye 3 meses gratuitos de uso de la plataforma.
              </p> */}
              <p>
                <DoneIcon className={classes.icon} />
                <span className={classes.item}>
                  Envía condolencias y memorias a los tributos de tus
                  seres queridos.
                </span>
              </p>
              <p>
                <DoneIcon className={classes.icon} />
                <span className={classes.item}>
                  Agrega todos tus tributos de interés a Mi Altar de
                  Tributos.
                </span>
              </p>
              <p>
                <DoneIcon className={classes.icon} />
                <span className={classes.item}>
                  Gratuito para siempre.
                </span>
              </p>
              <p>
                <DoneIcon className={classes.icon} />
                <span className={classes.item}>
                  Este es el plan se te asigna automáticamente al
                  crear tu cuenta Tribut.
                </span>
              </p>
            </div>
          </aside>
          <figure className={classes.plan__image}>
            <img
              alt="basic plan"
              src="https://d3cohyhv4rpctw.cloudfront.net/imagen_PlanBasico.jpeg"
              className={classes.logo}
            />
          </figure>
        </div>
        <div className={classes.planContainer}>
          <aside className={classes.infoContainer}>
            <h4 className={classes.subtitle}>Plan Administrador</h4>
            <div className={classes.subsection}>
              <p>
                <DoneIcon className={classes.icon} />
                <span className={classes.item}>
                  Te permite crear y administrar tributos para todos
                  tus seres queridos.
                </span>
              </p>
              <p>
                <DoneIcon className={classes.icon} />
                <span className={classes.item}>
                  Período gratuito de 3 meses, en el que puedes crear
                  y administrar sin costo todos los tributos que
                  desees.
                </span>
              </p>
              <p>
                <DoneIcon className={classes.icon} />
                <span className={classes.item}>
                  Elige entre forma de pago mensual a un precio de
                  $45 pesos/mes por tributo, o anual a un precio de
                  $399 pesos/año por tributo.
                </span>
              </p>
              <p>
                <DoneIcon className={classes.icon} />
                <span className={classes.item}>
                  Este plan se te asigna automáticamente al crear tu
                  primer Tributo. Puedes regresar al Plan Gratuito
                  cuando lo desees, simplemente eliminando tus
                  Tributos existentes.
                </span>
              </p>
            </div>
          </aside>
          <figure className={classes.plan__image}>
            <img
              alt="basic plan"
              src="https://d3cohyhv4rpctw.cloudfront.net/imagenPlanAnual.png"
              className={classes.logo}
            />
          </figure>
        </div>
      </section>
    </>
  );
};

export default AdminPlan;

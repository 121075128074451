import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

import DialogMessage from 'components/UI/DialogMessage/DialogMessage';
import UserInput from 'components/UI/UserInput/UserInput';
import UserButton from 'components/UI/UserButton/UserButton';
import DropZoneComponent from 'components/UI/DropZoneComponent/DropZoneComponent';
import Loading from 'components/UI/Loading/Loading';
import Events from 'components/UI/Events/Events';

import { editTribut } from '../../../../store/tribut-actions';
import { getCredentialsUploadImageS3 } from '../../../../store/tribut-actions';
import { uploadMultimediaTribut } from '../../../../store/uploadFileS3-actions';

import classes from './EditInfo.module.css';

const EditInfo = props => {
  const [load, setLoad] = useState(false);
  const [editorNote, setEditorNote] = useState(null);
  const [message, setMessage] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const html = props.tributInfo.note ? props.tributInfo.note : null;
  if (html) {
    const contentBlock = htmlToDraft(html);
    if (contentBlock && editorNote === null) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState =
        EditorState.createWithContent(contentState);
      setEditorNote(editorState);
    }
  }

  const [fileName] = useState(Date.now());
  const [contentType, setContentType] = useState(null);
  const [fileExtension, setFileExtension] = useState(null);
  const [image, setImage] = useState(
    props.tributInfo.urlAvatar ? props.tributInfo.urlAvatar : null
  );
  const [file, setFile] = useState(null);
  const prevalueData = {
    firstName: props.tributInfo.firstName,
    lastName: props.tributInfo.lastName,
    birthplace: props.tributInfo.birthplace,
    birthday: props.tributInfo.birthday,
    placeofdeath: props.tributInfo.placeDeath,
    dayofdeath: props.tributInfo.dateDeath,
    dedication: props.tributInfo.dedication,
    phrase: props.tributInfo.phrase
  };

  let newHour = null;
  if (props.tributInfo.mortuaryDate) {
    newHour = new Date(props.tributInfo.mortuaryDate);
    let hour = newHour.getHours() + 5;
    newHour.setHours(hour);
  }

  const [birthday, setBirthday] = useState(
    props.tributInfo.birthday
  );
  const [dayofdeath, setDayofdeath] = useState(
    props.tributInfo.dateDeath
  );

  const { register, handleSubmit } = useForm({
    defaultValues: prevalueData
  });

  const handleDrop = async data => {
    let fileNameExt = data[0].type;
    if (fileNameExt) {
      let fileNameSplit = fileNameExt.split('/');
      setFileExtension(fileNameSplit[1]);
    }
    setContentType(data[0].type);
    setFile(data[0]);

    const reader = new FileReader();
    reader.onload = function (e) {
      setImage(e.target.result);
    };
    reader.readAsDataURL(data[0]);
  };

  const birthdayHandler = value => {
    setBirthday(value);
  };

  const dayofDeathHandler = value => {
    setDayofdeath(value);
  };

  const onEditorNoteChange = editorNote => {
    setEditorNote(editorNote);
  };

  const onSubmit = async data => {
    setLoad(true);
    const editTributInfo = await editTribut(
      props.tributInfo.tributId,
      data.firstName,
      data.lastName,
      birthday,
      data.birthplace,
      dayofdeath,
      data.placeofdeath,
      data.dedication,
      data.phrase,
      editorNote
        ? draftToHtml(convertToRaw(editorNote.getCurrentContent()))
        : null,
      fileExtension ? fileName.toString() : null,
      fileExtension ? fileExtension : null
    );

    if (fileExtension) {
      let credentials = await getCredentialsUploadImageS3(
        props.tributInfo.tributId,
        contentType,
        fileName.toString(),
        fileExtension
      );

      // Upload Image en S3
      await uploadMultimediaTribut(credentials, file);
    }
    if (editTributInfo) {
      setShowDialog(true);
      setMessage('El Tributo fue actualizado con éxito');
      setLoad(false);
    } else {
      setShowDialog(true);
      setMessage('Hubo un error Te sugerimos volver a intentarlo');
      setLoad(false);
    }
  };

  const closeDialogHandler = () => {
    setShowDialog(false);
    // window.location.href = '/';
    window.location.href = `/tribut/${props.tributInfo.tributId}/memorias`;
  };

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <aside className={classes.loadImage}>
          <p className={classes.principalText}>
            Fotografía del Tributo
          </p>
          <DropZoneComponent
            onDrop={handleDrop}
            image={image}
            acceptFiles=".jpg, .png, .jpeg, .heic"
            multipleFiles="false"
            maxSizeFile={5000000000000000}
            type="image"
            createTribut
          />
          <p className={classes.uploadImageText}>
            Sube una imagen de tu ser querido
          </p>
          <p className={classes.uploadImageSubText}>
            Tamaño recomendado 200 x 200px
          </p>
        </aside>
        <UserInput
          inputText="Nombre (s)"
          type="text"
          size="small"
          name="firstName"
          edit
          status={register('firstName', { required: true })}
        />
        <UserInput
          inputText="Apellido(s)"
          type="text"
          size="small"
          name="lastName"
          edit
          status={register('lastName', { required: true })}
        />
        <div className={classes.specialContainer}>
          <UserInput
            inputText="Fecha y lugar de nacimiento (opconal)"
            type="date"
            size="small"
            dateHandler={birthdayHandler}
            value={birthday}
            name="birthday"
            edit
            row
          />
          <UserInput
            type="text"
            size="small"
            name="birthplace"
            edit
            status={register('birthplace', { required: false })}
            row
            second
          />
        </div>
        <div className={classes.specialContainer}>
          <UserInput
            inputText="Fecha y lugar de defunción *"
            type="date"
            size="small"
            dateHandler={dayofDeathHandler}
            value={dayofdeath}
            name="dayofdeath"
            edit
            row
          />
          <UserInput
            type="text"
            size="small"
            name="placeofdeath"
            edit
            status={register('placeofdeath', { required: false })}
            row
            second
          />
        </div>
        <Events
          edit
          ocurrencesData={props.tributInfo.lstOcurrence}
          tributId={props.tributInfo.tributId}
          birthday={birthday}
        />

        <UserInput
          inputText="Mensaje público de para tu ser querido *"
          subtext="Este mensaje aparecerá en la parte superior de la sección de Condolencias"
          type="text"
          size="big"
          status={register('dedication')}
          name="dedication"
          edit
        />
        <UserInput
          inputText="Frase pública de para tu ser querido"
          subtext="Esta frase aparecerá resaltada en la sección de Vida"
          type="text"
          size="big"
          status={register('phrase')}
          name="phrase"
          edit
        />
        <p className={classes.principalText}>
          Datos generales del servicio funerario
        </p>
        <Editor
          toolbar={{
            options: [
              'inline',
              'blockType',
              'fontSize',
              'textAlign'
            ],
            inline: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true }
          }}
          editorState={editorNote}
          editorClassName="demo-editor"
          wrapperClassName="wrapperClassName"
          onEditorStateChange={onEditorNoteChange}
        />
        {!load ? (
          <Fragment>
            <UserButton
              double
              cancel
              onClickHandler={props.editCancel}
              text="Cancelar"
            />
            <UserButton
              double
              edit
              text="Guardar"
              type="submit"
              onClickHandler={onSubmit}
            />
          </Fragment>
        ) : (
          <Loading />
        )}
      </form>
      <DialogMessage
        closeDialog={closeDialogHandler}
        open={showDialog}
        message={message}
      />
    </Fragment>
  );
};

EditInfo.propTypes = {
  // eslint-disable-next-line react/require-default-props
  tributInfo: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  changeSection: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  editCancel: PropTypes.func
};

export default EditInfo;

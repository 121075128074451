import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  PaymentElement,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import classes from './CheckoutForm.module.css';

const CheckoutForm = props => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(
      window.location.search
    ).get('payment_intent_client_secret');

    if (!clientSecret) {
      return;
    }

    stripe
      .retrievePaymentIntent(clientSecret)
      .then(({ paymentIntent }) => {
        switch (paymentIntent.status) {
          case 'succeeded':
            setMessage('Payment succeeded!');
            break;
          case 'processing':
            setMessage('Your payment is processing.');
            break;
          case 'requires_payment_method':
            setMessage(
              'Your payment was not successful, please try again.'
            );
            break;
          default:
            setMessage('Something went wrong.');
            break;
        }
      });
  }, [stripe]);

  const handleSubmit = async e => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    let urlRedirect = '';
    urlRedirect = 'https://google.com';

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: urlRedirect
      },
      redirect: 'if_required'
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error) {
      if (
        error.type === 'card_error' ||
        error.type === 'validation_error'
      ) {
        setMessage(error.message);
      } else {
        setMessage('An unexpected error occured.');
      }
    } else {
      props.newCardSuccess();
    }

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <button
        className={classes.btnStripe}
        disabled={isLoading || !stripe || !elements}
        id="submit"
      >
        <span id="button-text">
          {isLoading ? (
            <div className={classes.spinner} id="spinner"></div>
          ) : (
            'Guardar'
          )}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
};

CheckoutForm.propTypes = {
  // eslint-disable-next-line react/require-default-props
  newCardSuccess: PropTypes.func
};

export default CheckoutForm;

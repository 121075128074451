import { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';

import { sendMessage } from 'store/tribut-actions';
import classes from './InactiveTribut.module.css';

const InactiveTribut = props => {
  const [showMessage, setShowMessage] = useState(false);
  const [open, setOpen] = useState(true);

  const sendMessageHandler = () => {
    (async () => {
      let sendMessageDetails = await sendMessage(props.tributId);
      if (sendMessageDetails) {
        setShowMessage(true);
      }
    })();
  };

  const closeModalHandler = () => {
    setOpen(false);
    window.location.href = '/';
  };

  const seeChargesHandler = () => {
    setOpen(false);
    window.location.href = '/charges';
  };

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        maxWidth="xs"
        onClose={props.admin ? seeChargesHandler : closeModalHandler}
      >
        <div>
          <DialogContent className={classes.infoContainer}>
            <figure className={classes.inTributICon}>
              <img
                className={classes.img}
                src="https://d3cohyhv4rpctw.cloudfront.net/Logo_Tribut.png"
                alt="Tribut"
              />
            </figure>
            {props.admin && (
              <p className={classes.warningMessage}>
                Tribut suspendido
              </p>
            )}
            {!showMessage ? (
              <>
                {props.admin ? (
                  <p className={classes.textInfo}>
                    Este Tribut para:
                  </p>
                ) : (
                  <>
                    <p className={classes.sorryText}>Lo sentimos</p>
                    <p className={classes.textInfo}>
                      Esta página de homenaje para:
                    </p>
                  </>
                )}
                {props.tributImage ? (
                  <figure className={classes.tributAvatar}>
                    <img
                      src={props.tributImage}
                      alt={props.tributName}
                      className={classes.tributImg}
                    />
                  </figure>
                ) : (
                  <div className={classes.noUserContainer}>
                    <PersonOutlinedIcon
                      className={classes.noUserIcon}
                    />
                  </div>
                )}
                <p className={classes.userName}>
                  {props.tributName}
                </p>
                {props.admin ? (
                  <p className={classes.textInfo}>
                    Ha sido suspendido por adeudos pendientes. Accede
                    a tu estado de cuenta para ver el detalle y
                    realizar el pago correspondiente. Si consideras
                    que se trata de un error, contacta a{' '}
                    <Button
                      className={classes.helpBtn}
                      component="a"
                      href="http://ayuda.mayahii.com/"
                      target="_blank"
                    >
                      Centro de ayuda
                    </Button>
                  </p>
                ) : (
                  <>
                    <p className={classes.complementaryInfo}>
                      Se encuentra suspendida.
                    </p>
                    <p className={classes.complementaryInfo}>
                      Por favor contacta al administrador dueño del
                      Tributo.
                    </p>
                  </>
                )}
              </>
            ) : (
              <p>
                Se ha enviado un mail al Administrador para
                notificarle la suspención de homenaje.
              </p>
            )}
          </DialogContent>
          <div className={classes.btnsContainer}>
            {props.admin ? (
              <Button
                className={classes.chargesBtn}
                onClick={seeChargesHandler}
              >
                Ver mi estado de cuenta
              </Button>
            ) : (
              <>
                {!showMessage ? (
                  <Button
                    className={classes.sendMessgeBtn}
                    onClick={sendMessageHandler}
                  >
                    Contactar al administrador
                  </Button>
                ) : (
                  <Button
                    className={classes.returnBtn}
                    onClick={closeModalHandler}
                  >
                    De acuerdo
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      </Dialog>
    </>
  );
};

InactiveTribut.propTypes = {
  // eslint-disable-next-line react/require-default-props
  tributName: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  tributImage: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  admin: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  tributId: PropTypes.string
};

export default InactiveTribut;

import axios from 'axios';
import config from './config';

import { getAuthHeader } from './user-actions';

export const createComment = async (object, message, type) => {
  let data = null;

  if (type === 'MEMORY') {
    data = {
      memoryId: object.memoryId,
      comment: message,
      type: type
    };
  } else if (type === 'CONDOLENCE') {
    data = {
      condolenceId: object.condolenceId,
      comment: message,
      type: type
    };
  }

  let comment = await axios.put(config.API_COMMENT, data, {
    headers: getAuthHeader()
  });
  if (comment && comment.data && comment.data.httpStatus === 200) {
    return comment.data.commentId;
  }
  return null;
};

export const deleteComment = async (commentId, type) => {
  let deleteCommentData = await axios.post(
    `${config.API_COMMENT}/delete`,
    {
      commentId,
      type
    },
    {
      headers: getAuthHeader()
    }
  );
  if (deleteCommentData.data.httpStatus === 200) {
    return true;
  }
  return false;
};

export const likeAction = async (id, type) => {
  let likeData = await axios.post(
    `${config.API_FOLLOW}/like`,
    {
      id,
      type
    },
    {
      headers: getAuthHeader()
    }
  );
  if (likeData.data.httpStatus === 200) {
    return true;
  }
  return false;
};

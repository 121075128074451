import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';

import UserInput from '../UI/UserInput/UserInput';

import classes from './TributStep.module.css';

const TributStepTwo = props => {
  const [editorState, setEditorState] = useState('');

  const onEditorStateChange = editorState => {
    props.noteHandler(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
    setEditorState(editorState);
  };

  return (
    <Fragment>
      <h3 className={classes.tributStepTitle}>
        Para tu ser querido
      </h3>
      <UserInput
        inputText="Mensaje público de para tu ser querido *"
        subtext="Este mensaje aparecerá en la parte superior de la sección de Condolencias"
        type="text"
        size="big"
        status={props.registerStatus('dedication')}
        name="dedication"
        placeholder="p. ej. Te recordaremos por tus buenos actos, ya que sería imposible recordar alguno de tus fallos, demasiado perfecto para este mundo."
      />
      <p className={classes.totalCharacters}>
        {140 -
          (props.dedicationText
            ? props.dedicationText.length
            : 0)}{' '}
        caracteres
      </p>
      <UserInput
        inputText="Frase pública de para tu ser querido"
        subtext="Esta frase aparecerá resaltada en la sección de Vida"
        type="text"
        size="big"
        status={props.registerStatus('phrase')}
        name="phrase"
        placeholder="p. ej. “Me sonries desde el cielo, realmente lo sé”"
      />
      <p className={classes.totalCharacters}>
        {140 - (props.phraseText ? props.phraseText.length : 0)}{' '}
        caracteres
      </p>
      <h3 className={classes.generalData}>
        Datos generales del servicio funerario (opcional)
      </h3>
      <aside className={classes.editorContainer}>
        <Editor
          toolbar={{
            options: [
              'inline',
              'blockType',
              'fontSize',
              'textAlign'
            ],
            inline: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true }
          }}
          editorState={editorState}
          toolbarHidden
          editorClassName="demo-editor"
          wrapperClassName="wrapperClassName"
          onEditorStateChange={onEditorStateChange}
          placeholder="p. ej. Funeral 24 de octubre - 13:00hrs FUNERALES Y CAPILLAS SAN ANDRÉS, Capilla No. 3 Av. de los Maestros # 43, Col. San Andrés Atenco Tlanepantla de Baz, Estado de México. Más informes al teléfono: 23455679"
        />
      </aside>
    </Fragment>
  );
};

TributStepTwo.propTypes = {
  // eslint-disable-next-line react/require-default-props
  registerStatus: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  mortuaryDateHandler: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  mortuaryHourHandler: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  mortuaryDate: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  mortuaryHour: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  mortuarySetPhone: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  noteHandler: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  dedicationText: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  phraseText: PropTypes.func
};

export default TributStepTwo;

import { useEffect } from 'react';
import ReactGA from 'react-ga4';

import BannerSection from '../../components/UI/BannerSection/BannerSection';
import classes from './Contact.module.css';

const Contact = () => {
  useEffect(() => {
    ReactGA.initialize('G-N3MP6T91TP');
    ReactGA.pageview('/contactUs');
  }, []);

  return (
    <section>
      <BannerSection />
      <aside className={classes.contactContainer}>
        <h3 className={classes.contactTitle}>Contacto</h3>
        <p className={classes.contactText}>
          ¡Ponte en contacto con nosotros, resolveremos tus dudas y
          comentarios con gusto!
        </p>
        <p className={classes.contactOption}>
          Mail de atención al cliente:
        </p>
        <p className={classes.contactOption}>Número telefónico:</p>
      </aside>
    </section>
  );
};

export default Contact;

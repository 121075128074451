import classes from './TributStep.module.css';

const TributStepFour = () => {
  return (
    <div>
      <h3 className={classes.tributStepTitle}>Opciones de pago</h3>
      <p className={classes.message}>
        No es necesario ningún tipo de pago
      </p>
      <p className={classes.message}>
        La Arquidiocesis de Monterrey está patrocinando{' '}
        <strong>3 meses gratuitos</strong> de Tribut, para que
        pruedas conmemorar a tu ser querido sin ningún tipo de
        compromiso.
      </p>
    </div>
  );
};

export default TributStepFour;

import { useEffect } from 'react';
import ReactGA from 'react-ga4';

import RecoverAccount from 'components/RecoverAccount/RecoverAccount';
import classes from './RecoverAccountPage.module.css';

const RecoverAccountPage = () => {
  useEffect(() => {
    ReactGA.initialize('G-N3MP6T91TP');
    ReactGA.pageview('/recoverAccount');
  }, []);

  return (
    <section className={classes.recoverAccountContainer}>
      <p className={classes.recoverAccountTitle}>
        Recuperar tu cuenta
      </p>
      <RecoverAccount />
    </section>
  );
};

export default RecoverAccountPage;

import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import AllMemoriesContainer from 'components/tribut/MemorySection/AllMemoriesContainer/AllMemoriesContainer';
import { getMemoryList } from 'store/memory-actions';
import Loading from 'components/UI/Loading/Loading';
import classes from './Memories.module.css';

const Memories = () => {
  const [lstMemory, setLstMemory] = useState(null);
  const [loading, setLoading] = useState(true);
  let params = useParams();
  let tributId = params.tributId ? params.tributId : 73;
  let memoryId = params.idMemory ? params.idMemory : '';
  const dispatch = useDispatch();
  const dataMemories = useSelector(state => state.memoriesData);

  useEffect(() => {
    ReactGA.initialize('G-N3MP6T91TP');
    ReactGA.pageview(`/tribut/${tributId}/memories/${memoryId}`);
  }, []);

  useEffect(() => {
    if (dataMemories.data.length === 0) {
      dispatch(getMemoryList(tributId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (dataMemories.data) {
      if (dataMemories.data === 'error') {
        setLoading(false);
        setLstMemory('error');
      }
      if (
        dataMemories.data.length > 0 &&
        dataMemories.data !== 'error'
      ) {
        setLoading(false);
        setLstMemory(dataMemories.data);
      }
    }
  }, [dataMemories]);

  return (
    <>
      {!loading ? (
        <>
          {lstMemory === 'error' ? (
            <p>No hay memorias</p>
          ) : (
            <section className={classes.TributContainer}>
              <AllMemoriesContainer
                memories={lstMemory}
                tributId={tributId}
                memoryView={parseInt(memoryId)}
              />
            </section>
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Memories;

import axios from 'axios';
import { setCookie } from '../store/user-actions';

export const loginUser = async (email, password, cellphone) => {
  const options = {
    email,
    password,
    cellphone
  };
  let auth = await axios.post(
    'https://mzg156366c.execute-api.us-east-1.amazonaws.com/prod/auth',
    options,
    {}
  );
  if (auth && auth.data && auth.data.httpStatus === 200) {
    setCookie(auth.data.response.token);
    return true;
  }
  return false;
};

import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-hook-inview';

import VideoInfo from '../VideoInfo/VideoInfo';

const ShowVideo = props => {
  const [playVideo, setPlayVideo] = useState(false);

  const playerRef = useRef(null);

  const [ref] = useInView({
    threshold: 1,
    onEnter,
    onLeave
  });

  function onLeave() {
    setPlayVideo(false);
  }

  function onEnter() {
    setPlayVideo(true);
  }

  const videoJsOptions = {
    autoplay: true,
    currentTime: 20,
    fullscreen: { options: { navigationUI: 'hide' } },
    controls: true,
    responsive: false,
    fluid: false,
    fill: false,
    poster: props.thumbnail,
    loop: true,
    sources: [
      {
        src: props.srcVideo
      }
    ],
    touchControls: {
      seekSeconds: 10,
      tapTimeout: 300,
      disableOnEnd: false,
      disabled: false
    }
  };

  const handlePlayerReady = player => {
    playerRef.current = player;

    if (!playVideo) {
      player.pause();
      player.currentTime(0.1);
    }

    if (playVideo) {
      player.play();
    }

    player.on('ready', () => {
      player.currentTime(0.1);
    });

    player.on('dispose', () => {});
  };

  return (
    <div ref={ref}>
      <VideoInfo
        options={videoJsOptions}
        onReady={handlePlayerReady}
        memoryId={props.memoryId}
        memoryPlaying={props.memoryPlaying}
        pauseVideo={props.pauseVideo}
        dataMemory={props.dataMemory}
        full={props.full}
      />
    </div>
  );
};

ShowVideo.propTypes = {
  // eslint-disable-next-line react/require-default-props
  srcVideo: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  dataMemory: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  memoryView: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  memoryId: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  memory: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  memoryPlayingHandler: PropTypes.func,
  // eslint-disable-next-line react/require-default-props
  memoryPlaying: PropTypes.number,
  // eslint-disable-next-line react/require-default-props
  pauseVideo: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  thumbnail: PropTypes.string,
  // eslint-disable-next-line react/require-default-props
  full: PropTypes.bool
};

export default ShowVideo;
